import React, { useContext, useEffect } from "react"

import { CarProvider } from "../../../context/CarContext"
import { GlobalContext } from "../../../context/GlobalContext"
import { SET_CARS, SET_LOADING } from "../../../context/reducer/GlobalReducer"

import { getCars } from "../../../lib/services"
import { handleError } from "../../../lib/serverAxios"

import CarsPage from "./CarsPage"

export default function Cars() {
   const { dispatch } = useContext(GlobalContext)
   useEffect(() => {
      dispatch({
         type: SET_LOADING,
         isLoading: true,
      })
      const controller = new AbortController()
      const signal = controller.signal

      ;(async () => {
         try {
            const data = await getCars(signal)

            dispatch({
               type: SET_CARS,
               cars: data.cars,
            })
            dispatch({
               type: SET_LOADING,
               isLoading: false,
            })
         } catch (error) {
            handleError(error)
            dispatch({
               type: SET_LOADING,
               isLoading: false,
            })
         }
      })()
      return () => {
         controller.abort()
      }
      //eslint-disable-next-line
   }, [])

   return (
      <CarProvider>
         <CarsPage />
      </CarProvider>
   )
}
