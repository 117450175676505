import { PlusCircleOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Form, Input, InputNumber, Row } from "antd"
import React, { useContext } from "react"
import { CarContext } from "../../../context/CarContext"
import UnitExpensesController from "../controller/UnitExpensesController"

const { TextArea } = Input

export default function UnitExpensesForm() {
   const { selectedDate, car, dispatch } = useContext(CarContext)
   const [form] = Form.useForm()

   const { disabledTo, isthreeMonthsAgo, onFinish, startDate, endDate } = UnitExpensesController({
      car,
      selectedDate,
			dispatch
   })

   return (
      <div>
         {isthreeMonthsAgo() && (
            <Form
               id='form'
               name='basic'
               layout='horizontal'
               form={form}
               onFinish={onFinish}
               onFinishFailed={null}
               autoComplete='off'
               labelCol={{
                  span: 8,
               }}
               wrapperCol={{
                  span: 16,
               }}
              //  initialValues={{
              //     date: dayjs(endDate),
              //  }}
            >
               <Row>
                  <Col span={12}>
                     <Form.Item
                        label='Date'
                        name='date'
                        style={{
                           width: "100%",
                           marginBottom: "0px",
                        }}
												rules={[
													{
														 required: true,
														 message: "Date is required!",
													},
											 ]}
                     >
                        <DatePicker
                           format='MM-DD-YYYY HH:mm'
                           showTime={true}
                           disabledDate={disabledTo}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label='Maintenance'
                        name='maintenance'
                        style={{
                           width: "100%",
                        }}
                     >
                        <b>
                           <InputNumber style={{ width: "50%" }} />
                        </b>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label='Car Wash'
                        name='carWash'
                        style={{
                           width: "100%",
                        }}
                     >
                        <b>
                           <InputNumber style={{ width: "50%" }} />
                        </b>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label='Pickup/Delivery'
                        name='pickupOrDelivery'
                        style={{
                           width: "100%",
                        }}
                     >
                        <b>
                           <InputNumber style={{ width: "50%" }} />
                        </b>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        name='description'
                        label='Description'
                        style={{
                           width: "100%",
                        }}
                     >
                        <TextArea
                           rows={8}
                           autoSize={{
                              minRows: 2,
                              maxRows: 8,
                           }}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        label='Referral'
                        name='referral'
                        style={{
                           width: "100%",
                        }}
                     >
                        <b>
                           <InputNumber style={{ width: "50%" }} />
                        </b>
                     </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                     <Form.Item
                        style={{
                           width: "100%",
                        }}
                     >
                        <Button
                           icon={<PlusCircleOutlined />}
                           style={{
                              width: "50%",
                           }}
                           type='primary'
                           htmlType='submit'
                        >
                           Add expense
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>
            </Form>
         )}
      </div>
   )
}
