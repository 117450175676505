import React, { useContext, useRef, useState } from 'react'
import SelectCarForCalendarModal from './SelectCarForCalendarModal'
import { Card, InputNumber, message } from 'antd'

import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { CalendarContext } from '../../../context/CalendarContext'
import { getBooksAndReservations } from '../api/calendarAPI'
import { SET_BOOK_RESERBVATIONS, SET_SELECTED_DATE } from '../../../context/reducer/CalendarReducer'

import isToday from 'dayjs/plugin/isToday'
import EventContent from './EventContent'
import CalendarPageController from '../controller/CalendarPageController'
import BookReservationDrawer from './BookReservationDrawer'

import dayjs from 'dayjs'
import SwitchCarDrawer from './SwitchCarDrawer'
dayjs.extend(isToday)

const CalendarPage = () => {
   const calendarRef = useRef()
   const { bookReservations, dispatch, isBookReservationDrawerOpen, isSwitchCarDrawerOpen } =
      useContext(CalendarContext)

   const _draggableEl = useRef(document.getElementById('external-events'))

   const [state, setState] = useState({
      startStr: '',
      endStr: '',
      allDay: false,
      height: parseInt(localStorage.getItem("height") || 0) || 1000,
      isViewEventModalOpen: false,
      clickInfo: '',
      weekendsVisible: true,
      externalEvents: [
         { title: 'Almera', date: '2024-01-15', color: '#3324', id: 34432 },
         {
            title: 'Wigo 3470',
            start: 'Tue Jan 02 2024 00:00:00 GMT+0800',
            end: 'Tue Jan 03 2024 00:00:00 GMT+0800',
            color: '#0097a7',
            id: 323232,
         },
         {
            title: 'Strada 1281',
            date: '2024-01-16',
            color: '#f44336',
            id: 1111,
         },
      ],
   })
   const [isSelectCarModalOpen, setIsSelectCarModalOpen] = useState(false)

   const { handleEventClick } = CalendarPageController({ setState, dispatch })

   const handleDateSelect = (selectInfo) => {
      const diff = dayjs(selectInfo.start).diff(dayjs(), 'day')
      if (diff < -8) return message.error("Can't add book/reservation on past 8 days")
      // let title = prompt('Please enter a new title for your event')
			dispatch({type: SET_SELECTED_DATE,selectedDateStr: selectInfo.startStr})
      setState((prevState) => ({
         ...prevState,
         startStr: selectInfo.start,
         endStr: selectInfo.endStr,
         allDay: false,
      }))
      setIsSelectCarModalOpen(true)
   }

   const handleEvents = (events) => {
      setState((state) => ({
         ...state,
         currentEvents: events,
      }))
   }

   const handleChangeHeight = (value) => {
      setState((prevState) => ({
         ...prevState,
         height: value,
      }))
   }

   const handleChangeDate = async (dateInfo) => {
      const { start, end, view } = dateInfo
			localStorage.setItem("dateView", view?.type)
      const [bookReservations, errorMsg] = await getBooksAndReservations(
         start,
         end
      )
      if (errorMsg) return message.error(errorMsg)
      dispatch({ type: SET_BOOK_RESERBVATIONS, bookReservations })
   }

   // handle event receive FOR DRAG AND DROP
   const handleEventReceive = (eventInfo) => {
      const newEvent = {
         id: eventInfo.draggedEl.getAttribute('data-id'),
         title: eventInfo.draggedEl.getAttribute('title'),
         color: eventInfo.draggedEl.getAttribute('data-color'),
         start: eventInfo.date,
         end: eventInfo.date,
         custom: eventInfo.draggedEl.getAttribute('data-custom'),
      }

      setState((prevState) => ({
         ...prevState,
         calendarEvents: state.calendarEvents.concat(newEvent),
      }))
   }

   const eventDrop = (info) => {
      if ('kiko' === 'kiay') return info.revert()

      // let calendarApi = calendarRef.current.getApi()
      // calendarApi.addEvent({
      //   id: createEventId(),
      //   title: "car",
      //   start: info.dateStr,
      //  startStr:  dayjs(date.start),
      // end: info.dateStr, // fullCalendarState.endStr
      //   endStr: dayjs(date.end).format("YYYY-MM-DD"), // fullCalendarState.endStr
      //  allDay: info.allDay,
      //})
      // is the "remove after drop" checkbox checked?
      // if (checkbox.checked) {
      //   // if so, remove the element from the "Draggable Events" list
      //   info.draggedEl.parentNode.removeChild(info.draggedEl);
      // }
   }

   return (
      <>
         <Card className='sd-sm'>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
               <div>
                  Height:{' '}
                  <InputNumber
                     defaultValue={state.height}
                     value={state.height}
                     onChange={handleChangeHeight}
                     min={500}
                     placeholder='500'
                  />
               </div>
            </div>
            <div style={{ marginTop: '1vw' }}>
               <FullCalendar
                  displayEventEnd={true}
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                  headerToolbar={{
                     start: 'today prevYear prev next nextYear',
                     center: 'title',
                     end: 'dayGridMonth dayGridWeek',
                  }}
                  themeSystem='Simplex'
                  initialView={localStorage.getItem("dateView") ? localStorage.getItem("dateView") :'dayGridMonth'}
                  views={['dayGridMonth', 'dayGridWeek']}
                  weekends={true}
                  eventDisplay='block'
									eventOverlap={true}
                  eventContent={(eventInfo) => (
                     <EventContent
                        eventInfo={eventInfo}
                        bookReservations={bookReservations}
                     />
                  )}
                  editable={false}
                  droppable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  eventChange={() => message.info('Are you sure my frined?')}
                  //	initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
                  select={handleDateSelect}
                  eventClick={handleEventClick}
                  events={bookReservations}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                  height={state.height}
                  eventReceive={handleEventReceive}
                  eventDrop={eventDrop}
                  datesSet={handleChangeDate}
               />
            </div>

            {/* <CarsDraggable /> */}
            {isSelectCarModalOpen && (
               <SelectCarForCalendarModal
                  fullCalendarState={state}
                  calendarRef={calendarRef}
                  isSelectCarModalOpen={isSelectCarModalOpen}
                  setIsSelectCarModalOpen={setIsSelectCarModalOpen}
               />
            )}
            {!!isBookReservationDrawerOpen && <BookReservationDrawer />}
            {!!isSwitchCarDrawerOpen && <SwitchCarDrawer />}
         </Card>
      </>
   )
}

export default CalendarPage
