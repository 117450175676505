import { Tag } from 'antd'
import React from 'react'
import { CARSTATUS } from '../../data/enum'

export default function CarStatusTag({status = '', fontSize = "0.9em"}) {
	let color = 'cyan'
	if (status === CARSTATUS.available) {
		 color = 'green'
	}
	if (status === CARSTATUS.rented) {
		 color = 'gold'
	}
	if (status === CARSTATUS.inOwner) {
		 color = 'geekblue'
	}
	if (status === CARSTATUS.undermaintenance) {
		color = 'volcano'
 }
	return (
		 <div >
				<Tag style={{fontSize: fontSize}} color={color}>{status?.toUpperCase()} </Tag>
		 </div>
	)
}
