import React, { useContext } from 'react'
import { Checkbox, Col } from 'antd'
import { CalendarContext } from '../../../context/CalendarContext'
import { RELEASED } from '../../../data/enum'

export default function ReleasedChecklist() {

	const { bookReservation } =
	useContext(CalendarContext)

	const checkList = bookReservation?.checklists.find((checklist) => checklist.action === RELEASED) || {}

   return (
      <>
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
         >
            CHECKLIST
         </Col>

         <Col span={4}>
            <div className='nti'>Release </div>
               <Checkbox checked={checkList?.orcr} disabled>OR/CR</Checkbox> <br />
               <Checkbox checked={checkList?.tools} disabled>Tools</Checkbox> <br />
               <Checkbox checked={checkList?.stinkyOdor} disabled>Stinky Odor</Checkbox>
         </Col>
         <Col span={4}>
            <div> &nbsp;</div>
            <Checkbox checked={checkList?.spareTire} disabled>Spare-Tire</Checkbox> <br />
            <Checkbox checked={checkList?.jack} disabled>Jack</Checkbox> <br />
            <Checkbox checked={checkList?.dirtyStained} disabled>Dirty Stained</Checkbox>
         </Col>
      </>
   )
}
