import React from 'react'
import { Table as AntTable } from 'antd'
import { rowkey } from '../../lib/utils'

const Table = ({
	className,
	columns,
	dataSource,
	rowClassName = false,
	style,
	onClickRow = () => {},
	isLoading = false,
	expandable = false,
	bordered = false,
	size = "small"
}) => {
	const defaultRowClassName = (record, index) => {
		if (rowClassName !== false) {
			return rowClassName(record, index)
		} else {
			return index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
		}
	}

	return (
		<AntTable
		  rowKey={rowkey}
		  loading={isLoading}
		  size={size}
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => onClickRow(record, rowIndex, event), // click row
					onDoubleClick: (event) => {}, // double click row
					onContextMenu: (event) => {}, // right button click row
					onMouseEnter: (event) => {}, // mouse enter row
					onMouseLeave: (event) => {}, // mouse leave row
				}
			}}
			bordered={bordered}
			pagination={false}
			style={{...style}}
			columns={columns}
			dataSource={dataSource}
			rowClassName={defaultRowClassName}
			expandable={expandable}
		/>
	)
}

export default Table
