import { Col, Form, Input, Space, Checkbox, Select } from "antd"
import React, { Fragment, useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import { isEmpty } from "../../../lib/utils"
import CarInformationColumn from "./CarInformationColumn"
import { isRelease } from "../../commons/controllers/bookReservationsStatesController"
import { GlobalContext } from "../../../context/GlobalContext"
import {
   RESET_OOTD,
   SET_OOTD,
   TOGGLE_CD,
} from "../../../context/reducer/CalendarReducer"
import { BOOKSTATE } from "../../../data/enum"

export default function InformationSection({ car }) {
   const { bookReservation, isCDchecked, dispatch } =
      useContext(CalendarContext)
   const { outOfTownDestinations } = useContext(GlobalContext)

   const { customer, start, ootd, end, state, idLeft } = bookReservation

   const fullName = `${customer?.firstName} ${
      !isEmpty(customer?.middle) ? customer?.middle : ""
   } ${customer?.lastName}`

   const isForRelease = isRelease({ state, start, end })
   const isCustomer = !isEmpty(customer)

   const handleChangeDestinationChecked = (e) => {
      if (e.target.checked === false) {
         dispatch({ type: RESET_OOTD })
      }
      dispatch({ type: TOGGLE_CD, isCDchecked: e.target.checked })
   }
   const handleChangeOOTD = (_, data) => {
      const { minDays, fee, withDriverFee, id } = data
      dispatch({
         type: SET_OOTD,
         destinationFee: fee,
         minDays,
         withDriverFee,
         destinationID: id,
      })
   }

   const isFinished =
      state === BOOKSTATE.done ||
      state === BOOKSTATE.switched ||
      state === BOOKSTATE.voided ||
      state === BOOKSTATE.cancelled

   return (
      <Fragment>
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            INFORMATION
         </Col>
         <CarInformationColumn car={car} />
         {!!isCustomer && (
            <Fragment>
               <Col span={12}>
                  <Space direction='vertical'>
                     <div>
                        <span className='nti'> Customer: </span>
                        <b>
                           <i>{fullName} </i>
                        </b>
                     </div>
                     <div>
                        <span className='nti'> Contact No.: </span>
                        <i>
                           <b> 0{customer?.contactNo1} </b> /
                           <b> 0{customer?.contactNo2} </b>
                        </i>
                     </div>
                     <div>
                        <span className='nti'> Address: </span>
                        <b> {customer?.address} </b>
                     </div>
                     <div>
                        <span className='nti'> Facebook: </span>
                        <b>
                           <i>{customer?.facebook}</i>
                        </b>
                     </div>
                     <div>
                        <span className='nti'> Driver License No.: </span>
                        <b>
                           <i>{customer?.driverLicenseNo}</i>
                        </b>
                     </div>
                     {isForRelease ? (
                        <Form.Item
                           label='ID left'
                           name='idLeft'
                           style={{
                              width: "100%",
                              marginBottom: "0px",
                           }}
                           rules={[
                              {
                                 required: true,
                                 message: "Please input ID left",
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>
                     ) : (
                        <div>
                           <span className='nti'> ID left: </span>
                           <b>
                              <i>{idLeft}</i>
                           </b>
                        </div>
                     )}
                  </Space>
               </Col>

               <Col span={12}>
                  <div>
                     <span className='nti'> Destination: </span>
                     <b>
                        <i>
                           {isEmpty(ootd?.destination)
                              ? "City Proper"
                              : ootd?.destination}{" "}
                        </i>
                     </b>
                  </div>
               </Col>
               <Col span={12}> </Col>
               {!isFinished && (
                  <Col span={5}>
                     <Checkbox
                        value={isCDchecked}
                        onChange={handleChangeDestinationChecked}
                     >
                        Change destination :
                     </Checkbox>
                  </Col>
               )}

               {isCDchecked && !isFinished && (
                  <Col span={8}>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: "Please select destination",
                           },
                        ]}
                        name='ootdId'
                        valuePropName='checked'
                     >
                        <Select
                           style={{
                              width: "100%",
                           }}
                           options={outOfTownDestinations}
                           onChange={handleChangeOOTD}
                        />
                     </Form.Item>
                  </Col>
               )}
            </Fragment>
         )}
      </Fragment>
   )
}
