import React, { useContext, useEffect } from "react"
import { OOTDContext } from "../../../context/OOTDContext"
import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd"
import AddUpdateOOTDController from "../controller/AddUpdateOOTDController"
import { UPDATE } from "../../../data/magicString"
import { GlobalContext } from "../../../context/GlobalContext"

export default function AddUpdateOOTD() {
   const [form] = Form.useForm()

   const { dispatch: globalDispatch } = useContext(GlobalContext)

   const { ootd, addOrUpdate, dispatch, ootdIndex, isAddUpdateModalOpen } =
      useContext(OOTDContext)

   const { title, handleCancel, handleOk } = AddUpdateOOTDController({
      form,
      addOrUpdate,
      dispatch,
      ootdIndex,
      globalDispatch,
			ootd
   })


 useEffect(() => {
	if (addOrUpdate === UPDATE) {
		 form.setFieldsValue(ootd)
	}

	return () => {
		// form.resetFields()
	}
	//eslint-disable-next-line
}, [addOrUpdate, ootd])

   return (
      <Modal
         title={title()}
         open={isAddUpdateModalOpen}
         okText={addOrUpdate === UPDATE ? "Update" : "Add"}
         onCancel={handleCancel}
         width={"30vw"}
         footer={[
            <Button key='back' onClick={handleCancel}>
               Cancel
            </Button>,
            <Button form='form' htmlType='submit' key='submit' type='primary'>
               {addOrUpdate}
            </Button>,
         ]}
      >
         <Form
            id='form'
            name='basic'
            layout='vertical'
            form={form}
            style={{
               maxWidth: 800,
               marginTop: "1vw !important",
            }}
            onFinish={() => handleOk()}
            autoComplete='off'
         >
            <Row style={{ marginTop: "1vw" }}>
               <Col span={24} key='1'>
                  <Form.Item
                     label='Destination'
                     name='destination'
                     rules={[
                        {
                           required: true,
                           message: "Destination is required!",
                        },
                     ]}
                  >
                     <Input />
                  </Form.Item>
               </Col>
               <Col span={24} key='2'>
                  <Form.Item
                     label='Fee'
                     name='fee'
                     rules={[
                        {
                           required: true,
                           message: "Fee is required!",
                        },
                     ]}
                  >
                     <InputNumber
                        style={{
                           width: "100%",
                        }}
                        min={1}
                     />
                  </Form.Item>
               </Col>
               <Col span={24} key='3'>
                  <Form.Item
                     label='Min. Days (hrs)'
                     name='minDays'
                     rules={[
                        {
                           required: true,
                           message: "Minimum days is required",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <InputNumber
                        placeholder='hrs'
                        style={{
                           width: "100%",
                        }}
                        min={1}
                     />
                  </Form.Item>
               </Col>
               <Col span={24} key='4'>
                  <Form.Item
                     label='With Driver Fee'
                     name='withDriverFee'
                     rules={[
                        {
                           required: true,
                           message: "With driver fee is required",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <InputNumber
                        style={{
                           width: "100%",
                        }}
                        min={1}
                     />
                  </Form.Item>
               </Col>
            </Row>
         </Form>
      </Modal>
   )
}
