import React, { useContext, useEffect, useState } from "react"
import { Button, Modal } from "antd"
import UploadAndDisplayImage from "./UploadAndDisplayImage"
import { CustomerContext } from "../../../context/CustomerContext"
import { OPEN_UPLOAD_MODAL } from "../../../context/reducer/CustomerReducer"
import { initialImageUrl } from "../data/customerManagementData"
import { isEmpty } from "../../../lib/utils"

export default function UploadIDModal({ setstate }) {
   const { isUploadIDOpen, dispatch, customerID } = useContext(CustomerContext)

   const [imageUrl, setImageUrl] = useState(initialImageUrl)

   const handleOK = () => {
      setImageUrl(initialImageUrl)
      setstate((prevState) => ({
         ...prevState,
         updatedCounter: prevState.updatedCounter + 1,
      }))
      dispatch({
         type: OPEN_UPLOAD_MODAL,
         isUploadIDOpen: false,
         customerID: "",
      })
      window.location.reload()
      setImageUrl(initialImageUrl)
   }

	 const handleCancel = () => {
		dispatch({
			type: OPEN_UPLOAD_MODAL,
			isUploadIDOpen: false,
			customerID: -1,
	 })
	 }

   useEffect(() => {
      if (!isEmpty(customerID)) {
         setImageUrl({
            selfie: `/images/${customerID}-selfie.jpeg`,
            firstFront: `/images/${customerID}-firstFront.jpeg`,
            firstBack: `/images/${customerID}-firstBack.jpeg`,
            secondFront: `/images/${customerID}-secondFront.jpeg`,
            secondBack: `/images/${customerID}-secondBack.jpeg`,
         })
      }
      return () => {
         setImageUrl(initialImageUrl)
      }
   }, [customerID])

   return (
      <Modal
         width={`50%`}
         title={"ID"}
         open={isUploadIDOpen}
         onCancel={handleCancel}
         footer={[
            <Button
               key='cance,'
               type='default'
               onClick={handleCancel}
            >
               Cancel
            </Button>,
            <Button
               form='customer-form'
               htmlType='submit'
               key='submit'
               type='primary'
               onClick={handleOK}
            >
               OK
            </Button>,
         ]}
      >
         <UploadAndDisplayImage
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            customerID={customerID}
         />
      </Modal>
   )
}
