import React, { useState } from 'react'
import './styles.css'

import { Button, Col, Dropdown, Row, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import AdminMenuController from '../controllers/AdminMenuController'
import {
	IdcardFilled,
	CarFilled,
	DashboardFilled,
	EnvironmentFilled,
	CalendarFilled,
	DownOutlined,
	FundFilled,
} from '@ant-design/icons'
import ReportsDropdown from './ReportsDropdown'

const AdminMenu = () => {
	let navigate = useNavigate()

	const [activeMenu, setActiveMenu] = useState(
		localStorage.getItem('activeMenu') || 'dashboard'
	)

	const { handleMenu } = AdminMenuController({ navigate, setActiveMenu })
	const items = [
		{
			key: 'Cars',
			label: (
				<Button className='btn-link-black' onClick={() => handleMenu('cars')} style={{width: "100%", textAlign: "left"}}>
					<CarFilled /> Cars
				</Button>
			),
		},
		{
			key: 'Live',
			label: (
				<Button className='btn-link-black' onClick={() => handleMenu('live')}>
					<FundFilled /> Live Status
				</Button>
			),
		},

		// {
		// 	key: 'car-rates',
		// 	label: (
		// 		<Button className='btn-link-black' onClick={() => handleMenu('car-rates')}>
		// 			<FundFilled /> Car Rates
		// 		</Button>
		// 	),
		// },
		{
			key: 'Calendar',
			label: (
				<Button
					className='btn-link-black'
					onClick={() => handleMenu('calendar')}
				>
					<CalendarFilled /> Calendar
				</Button>
			),
		},
	]

	

	

	return (
		<Row
			style={{
				marginTop: '0.1vw',
				marginLeft: '5vw',
				float: 'left',
				height: '100%',
			}}
			gutter={[8, 8]}
		>
			<Col
				onClick={() => handleMenu('dashboard')}
				className={activeMenu === 'dashboard' ? 'active-item' : ''}
			>
				<Button classNames='active-item' type='link' className='btn-link-white'>
					<DashboardFilled /> Dashboard
				</Button>
			</Col>

			<Col
				className={activeMenu === 'customer' ? 'active-item' : ''}
				onClick={() => handleMenu('customer')}
			>
				<Button type='link' className='btn-link-white'>
					<IdcardFilled /> Customer
				</Button>
			</Col>
			<Col
				className={
					activeMenu === 'out-of-town-destination' ? 'active-item' : ''
				}
				onClick={() => handleMenu('out-of-town-destination')}
			>
				<Button type='link' className='btn-link-white'>
					<EnvironmentFilled /> OOTD
				</Button>
			</Col>
			<Col className={activeMenu === 'calendar' ? 'active-item' : ''}>
				<div>
					<Dropdown
						menu={{
							items,
						}}
					>
						<Space>
							<Button className='btn-link'>
								<CarFilled /> Cars &nbsp;
								<DownOutlined />
							</Button>
						</Space>
					</Dropdown>
				</div>
			</Col>
			<ReportsDropdown handleMenu={handleMenu} activeMenu={activeMenu}/>
			
		</Row>
	)
}

export default AdminMenu
