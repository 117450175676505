import React, { useContext, useState } from "react"
import { Col, Space, Form, InputNumber } from "antd"
import { CalendarContext } from "../../../context/CalendarContext"
import { isEmpty } from "../../../lib/utils"
import { isRelease } from "../../commons/controllers/bookReservationsStatesController"
import { BOOKSTATE } from "../../../data/enum"
import { branchObject } from "../../../data/data"

export default function CarInformationColumn({ car }) {
   const { bookReservation } = useContext(CalendarContext)

   const { customer, start, dateReleased, dateReturned, state, end, mileage } =
      bookReservation
   const [odometerEnd, setOdometerEnd] = useState(0)

   const isFinished =
      state === BOOKSTATE.done ||
      state === BOOKSTATE.switched ||
      state === BOOKSTATE.voided ||
      state === BOOKSTATE.cancelled

   const fullName = `${customer?.firstName} ${
      !isEmpty(customer?.middle) ? customer?.middle : ""
   } ${customer?.lastName}`

   const isForRelease = isRelease({ state, start, end })
   const isForReturn = state === BOOKSTATE.released

   return (
      <Col span={12}>
         <Space direction='vertical'>
            <div>
               <span className='nti'> Car: </span>
               <b>
                  <i>{car?.name} </i>
               </b>
            </div>
            <div>
               <span className='nti'> Plate: </span>
               <b>
                  <i>{car?.plate} </i>
               </b>
            </div>
            <div>
               <span className='nti'> Type: </span>
               <b>
                  <i>{car?.type} </i>
               </b>
            </div>
            <div>
               <span className='nti'> Branch: </span>
               <b>
                  <i>{branchObject[car?.branchId]} </i>
               </b>
            </div>
            <br />
            {!isFinished && (
               <div>
                  <span className='nti'> Last Odometer: </span>
                  <b>
                     <i>{car?.odometer} </i>
                  </b>
               </div>
            )}

            {isFinished && (
               <div>
                  <span className='nti'> Odo Start: </span>
                  <b>
                     <i>{mileage?.start} </i>
                  </b>
               </div>
            )}
            {isFinished && (
               <div>
                  <span className='nti'> Odo End: </span>
                  <b>
                     <i>{mileage?.end} </i>
                  </b>
               </div>
            )}

            {isForRelease ? (
               <Form.Item
                  label='Odometer'
                  name='odometerStart'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Please input the odometer",
                     },
                     () => ({
                        validator(_, value) {
                           if (value < car.odometer && !isEmpty(value)) {
                              return Promise.reject(
                                 new Error(
                                    "Odometer is less than the last recorded!"
                                 )
                              )
                           }
                           return Promise.resolve(
                              new Error(
                                 "Odometer is less than the last recorded!"
                              )
                           )
                        },
                     }),
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            ) : null}

            {isForReturn && (
               <>
                  <Form.Item
                     label='Odometer'
                     name='odometerEnd'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "Please input the odometer",
                        },
                        () => ({
                           validator(_, value) {
                              setOdometerEnd(isEmpty(value) ? 0 : value)
                              if (value < car.odometer && !isEmpty(value)) {
                                 return Promise.reject(
                                    new Error(
                                       "Odometer is less than the last recorded!"
                                    )
                                 )
                              }
                              return Promise.resolve(
                                 new Error(
                                    "Odometer is less than the last recorded!"
                                 )
                              )
                           },
                        }),
                     ]}
                  >
                     <InputNumber />
                  </Form.Item>
                  {odometerEnd > 0 ? (
                     <div>
                        {" "}
                        <span className='nti'>Consumption: </span>{" "}
                        <strong
                           style={
														car?.odometer - odometerEnd > 99 ? styles.overConsumption : null
                           }
                        >
                           <span style={{ fontSize: "16px" }}>
                              {odometerEnd - car?.odometer}
                           </span>
                        </strong>
                     </div>
                  ) : (
                     ""
                  )}
               </>
            )}
            {!isFinished && (
               <div>
                  <span className='nti'> Last Gas: </span>
                  <b>
                     <i>{car?.gas} bar </i>
                  </b>
               </div>
            )}
            {!isFinished && (
               <Form.Item
                  label='Gas'
                  name='gas'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Please input the gas",
                     },
                     () => ({
                        validator(_, value) {
                           if (value < car.gas && !isEmpty(value)) {
                              return Promise.reject(
                                 new Error(
                                    "Gas is less than the last recorded!"
                                 )
                              )
                           }
                           return Promise.resolve(
                              new Error(
                                 "Gas is less than the last recorded!"
                              )
                           )
                        },
                     }),
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            )}
         </Space>
      </Col>
   )
}

const styles = {
   overConsumption: {
      color: "red",
   },
}
