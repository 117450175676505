import { Col, Divider } from 'antd'
import React, { Fragment, useContext } from 'react'
import { CalendarContext } from '../../../context/CalendarContext'
import ReleaseChecklist from './ReleaseChecklist'
import ReturnedChecklist from './ReturnedChecklist'
import ReturnChecklist from './ReturnChecklist'
import { isEmpty } from '../../../lib/utils'
import ReleasedChecklist from './ReleasedChecklist'

export default function CheckListRow() {
   const { bookReservation } = useContext(CalendarContext)

   const { car, dateReleased, dateReturned } = bookReservation

   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />

         {isEmpty(dateReleased) ? <ReleaseChecklist /> : <ReleasedChecklist />}
         <Col span={2}>
            <Divider type='vertical' style={{ height: '100%' }} />
         </Col>
         {isEmpty(dateReturned) && !isEmpty(dateReleased) ? (
            <ReturnChecklist />
         ) : (
            <ReturnedChecklist />
         )}
      </Fragment>
   )
}
