import { message } from "antd"

export const SET_LOADING = "SET_LOADING"
export const SET_DATE = "SET_DATE"
export const SET_DAY = "SET_DAY"
export const CHANGE_SELECTED_CAR = "CHANGE_SELECTED_CAR"
export const CHANGE_CAR = "CHANGE_CAR"
export const SET_BOOK_RESERVATIONS = "SET_BOOK_RESERVATIONS"
export const SET_MONTHLY_UNI_INVENTORY_REPORTS =
   "SET_MONTHLY_UNI_INVENTORY_REPORTS"
export const SET_UNIT_TOTAL_GROSS = "SET_UNIT_TOTAL_GROSS"
export const INITIALIAZE_COMPONENENT = "INITIALIAZE_COMPONENENT"
export const SET_PREVIOUS_BOOK = "SET_PREVIOUS_BOOK"

export const MonthlySalesReportReducer = (state, action) => {
   switch (action.type) {
      case SET_PREVIOUS_BOOK:
         return {
            ...state,
            previousBook: action?.previousBook,
         }
      case INITIALIAZE_COMPONENENT:
         return {
            ...state,
            startDateCutoff: action?.startDate || 0,
            endDateCutoff: action?.endDate || 0,
         }
      case SET_UNIT_TOTAL_GROSS:
         return {
            ...state,
            unitTotalGross: action?.unitTotalGross || 0,
         }
      case SET_MONTHLY_UNI_INVENTORY_REPORTS:
         return {
            ...state,
            bookReservations: action?.bookReservations || [],
            unitExpense: action?.unitExpense || [],
						isLoading: false,
         }
      case SET_BOOK_RESERVATIONS:
         return {
            ...state,
            bookReservations: action?.bookReservations || [],
         }
      case CHANGE_SELECTED_CAR:
         return {
            ...state,
            selectedCar: action.car,
         }
      case CHANGE_CAR:
         return {
            ...state,
            selectedCar: action.car,
            cutoff: action.cutoff,
         }
      case SET_LOADING:
         return {
            ...state,
            isLoading: action.isLoading,
         }
      case SET_DATE:
         return {
            ...state,
            selectedDate: action.selectedDate,
         }
      case SET_DAY:
         return {
            ...state,
            selectedDay: action.selectedDay,
         }

      default:
         message.info("No CASE scenario")
         return state
   }
}
