import { Col, Tag } from "antd"
import React, { Fragment, useContext } from "react"
import { BOOKSTATE, FLAG_BOOK } from "../../../data/enum"
import {
   CheckCircleFilled,
   CloseCircleFilled,
   ControlFilled,
   SwapOutlined,
} from "@ant-design/icons"
import { isEmpty } from "../../../lib/utils"
import EventController from "../controller/EventController"
import { CalendarContext } from "../../../context/CalendarContext"

export default function CarHeader({ car }) {

	const { bookReservation } = useContext(CalendarContext)
	const {state, flag} = bookReservation

   const { timePassed, isMinutePast, isBookDone, isReservationCancelled } =
      EventController({ state, bookReservation })



   const isOverDue =
      !isEmpty(bookReservation?.dateReleased) &&
      isMinutePast &&
      !isBookDone &&
      !isReservationCancelled

   return (
      <Fragment>
         <Col span={24}>
            <div
               className='sd-sm'
               style={{
                  color: "white",
                  backgroundColor: `${car?.color}`,
                  padding: "8px 2px",
                  width: "auto",
                  textAlign: "center",
                  letterSpacing: "3px",
               }}
            >
               <i style={styles.carName}>
                  {car?.name?.toUpperCase()}{" "}
                  <span className='nti'>({car?.type.toUpperCase()}) </span>{" "}
               </i>

               {isReservationCancelled ? (
                  <CloseCircleFilled style={{ color: "#DDA15E" }} />
               ) : (
                  ""
               )}
               {isBookDone ? (
                  <CheckCircleFilled style={{ marginLeft: "5px" }} />
               ) : null}
               {/* {bookReservation?.dateReturned} */}
               {isOverDue ? (
                  <Tag color='red'>OVER DUE ({timePassed()})</Tag>
               ) : null}

               {state === BOOKSTATE.switched && (
                  <SwapOutlined style={{ marginLeft: "5px" }} />
               )}
							 {flag === FLAG_BOOK.switchedBooked && (
								  <ControlFilled style={{ marginLeft: "5px" }} />
							 )
							 }
            </div>
         </Col>
      </Fragment>
   )
}



const styles = {
	carName: {
		 letterSpacing: "4px",
	},
}