import React, { useContext, useEffect, useState } from "react"
import "../view/styles.css"

import { Card, Col, Row } from "antd"
import OverdueBooked from "./OverdueBooked"
import DashboardFloatBtn from "./DashboardFloatBtn"
import ReleaseForToday from "./ReleaseForToday"
import FourCards from "./FourCards"
import RentedStatus from "./RentedStatus"
import BookReservationDrawer from "../../calendar/components/BookReservationDrawer"
import SwitchCarDrawer from "../../calendar/components/SwitchCarDrawer"
import { CalendarContext } from "../../../context/CalendarContext"
import BookOrReserveModal from "./BookOrReserveModal"
import { handleError } from "../../../lib/serverAxios"
import { getCars } from "../../../lib/services"
import { GlobalContext } from "../../../context/GlobalContext"
import { SET_CARS } from "../../../context/reducer/GlobalReducer"

// Under maintenance
// ROI

const StaffDashboard = () => {
   const { isBookReservationDrawerOpen, isSwitchCarDrawerOpen } =
      useContext(CalendarContext)

   const { dispatch } = useContext(GlobalContext)

   const [state, setState] = useState({
      isAddUpdateModalOpen: false,
      addOrUpdate: "Add",
      updateCount: 1,
   })
   const [isSelectCarModalOpen, setIsSelectCarModalOpen] = useState(false)

   const handleRefreshData = () => {
      setState((prevState) => ({
         ...prevState,
         updateCount: prevState?.updateCount + 1,
				 isLoading: true
      }))
   }

   const initialize = async () => {
      try {
         const { cars } = await getCars()
         dispatch({
            type: SET_CARS,
            cars,
         })
      } catch (error) {
         handleError(error)
      }
   }

   useEffect(() => {
      initialize()
			setState((prevState) => ({
				...prevState,
				isLoading: false
		 }))
			//eslint-disable-next-line
   }, [state?.updateCount])

   return (
      <div style={{ marginBottom: "1vw" }}>
         <FourCards  updateCount={state?.updateCount}/>

         <Row gutter={[8, 8]} style={{ marginTop: "1vw" }}>
            <Col span={12}>
               <ReleaseForToday updateCount={state?.updateCount} isSelectCarModalOpen={isSelectCarModalOpen} />
               <RentedStatus updateCount={state?.updateCount}  />
            </Col>
            <Col span={12}>
               <Card className='sd-sm' style={{ width: "100%" }}>
                  <OverdueBooked updateCount={state?.updateCount} isLoading={state.isLoading} />
               </Card>
            </Col>
         </Row>
         {!!isBookReservationDrawerOpen && <BookReservationDrawer />}
         {!!isSwitchCarDrawerOpen && <SwitchCarDrawer />}
         {!!isSelectCarModalOpen && (
            <BookOrReserveModal
               isSelectCarModalOpen={isSelectCarModalOpen}
               setIsSelectCarModalOpen={setIsSelectCarModalOpen}
            />
         )}
         <DashboardFloatBtn
				    isLoading={state?.isLoading}
            setIsSelectCarModalOpen={setIsSelectCarModalOpen}
            handleRefreshData={handleRefreshData}
         />
      </div>
   )
}

export default StaffDashboard
