import { Card, Col, Divider, Row } from "antd"
import SERVER from "../../../lib/serverAxios"
import { convertConvertToPHCurrency } from "../../../lib/utils"
import { computeTotalPayment } from "../../../lib/calculation"

const CustomerBookedController = ({
   customerID,
   setData,
   setCustomerManagementState,
}) => {
   const initialize = async () => {
      // const { data } = await SERVER.get(`/api/customer/book?id=${customerID}`)
      // const { datas } = await SERVER.get(`/api/customer/book?id=18`)
      // setData(data.bookReservations)
   }
   const handleOk = () => {}

   const computeTotalAmountPaid = (invoices) => {
      const initialValue = 0
      const sumWithInitial = invoices.reduce(
         (accumulator, currentValue) =>
            accumulator + parseInt(currentValue?.paidAmount ?? 0),
         initialValue
      )
      return sumWithInitial
   }

   const handleCancel = () => {
      setCustomerManagementState((prevState) => ({
         ...prevState,
         isViewCustomerBookedOpen: false,
      }))
   }

   const footerExpandedRow = (record) => {
      const totalAmountPaid = computeTotalAmountPaid(record?.invoices)
      const TotalPayment = computeTotalPayment(record?.payment)
      const balance = TotalPayment - totalAmountPaid
      return (
         <Row>
            <Col span={24} align='right'>
               <Card style={{ width: "30%" }}>
                  <div>
                     <span className='nti'> Total Cost: </span>{" "}
                     <b> {convertConvertToPHCurrency(TotalPayment)} </b>
                  </div>
                  <div>
                     <span className='nti'>
                        {balance < 1
                           ? "Fully Paid"
                           : "Partial/Reservation Paid"}{" "}
                     </span>{" "}
                     <b> {convertConvertToPHCurrency(totalAmountPaid)} </b>
                  </div>

                  {balance > 0 ? (
                     <div>
                        <Divider /> <span className='nti'> Balance: </span>{" "}
                        <b> {convertConvertToPHCurrency(balance)} </b>
                     </div>
                  ) : (
                     ""
                  )}
               </Card>
            </Col>
         </Row>
      )
   }

   return {
      footerExpandedRow,
      initialize,
      handleOk,
      handleCancel,
   }
}

export default CustomerBookedController
