import {
   CarFilled,
   DollarOutlined,
   EnvironmentFilled,
} from "@ant-design/icons"
import { Card, Col, Row, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { GlobalContext } from "../../../context/GlobalContext"
import { CARSTATUS } from "../../../data/enum"
import { convertConvertToPHCurrency } from "../../../lib/utils"

export default function FourCardsAdmin({ updateCount }) {
   const [state, setState] = useState({
      isAddUpdateModalOpen: false,
      addOrUpdate: "Add",
      total: 0,
   })

   const { cars } = useContext(GlobalContext)
   const availableCars = cars.filter(
      (car) => car.status === CARSTATUS.available
   )?.length
   const rentedCars = cars.filter(
      (car) => car.status === CARSTATUS.rented
   )?.length
   const underMaintenance = cars.filter(
      (car) => car.status === CARSTATUS.undermaintenance
   )?.length

   useEffect(() => {
      ;(async () => {
         try {
            const {
               data: { total },
            } = await SERVER.get(`/api/invoice/this-month`)
            setState((prevState) => ({
               ...prevState,
               total,
            }))
         } catch (error) {
            handleError(error)
         }
      })()
   }, [updateCount])

   return (
      <Row gutter={[8, 8]}>
         <Col span={6}>
            <Card
               className='sd-sm  dashboar-card'
               style={{ borderLeftColor: "#F6C439" }}
            >
               <div>
                  <div style={{ float: "left" }}>
                     <CarFilled
                        style={{
                           fontSize: "5em",
                           color: "#F6C439",
                        }}
                     />
                  </div>
                  <div style={{ paddingLeft: "1vw", float: "left" }}>
                     <span style={{ fontSize: "1.8em" }}>{availableCars}</span>
                     <Typography>Available </Typography>
                  </div>
               </div>
            </Card>
         </Col>
         <Col span={6}>
            <Card
               className='sd-sm dashboar-card'
               style={{ borderLeftColor: "#606C38" }}
            >
               <div>
                  <div style={{ float: "left" }}>
                     <DollarOutlined
                        style={{
                           fontSize: "5em",
                           color: "#606C38",
                        }}
                     />
                  </div>
                  <div style={{ paddingLeft: "1vw", float: "left" }}>
                     <span style={{ fontSize: "1.8em" }}>
                        {convertConvertToPHCurrency(parseFloat(state?.total))}
                     </span>
                     <Typography> Amount </Typography>
                  </div>
               </div>
            </Card>
         </Col>

         <Col span={6}>
            <Card
               className='sd-sm  dashboar-card'
               style={{ borderLeftColor: "#BC6C25" }}
            >
               <div>
                  <div style={{ float: "left" }}>
                     <EnvironmentFilled
                        style={{
                           fontSize: "5em",
                           color: "#BC6C25",
                        }}
                     />
                  </div>
                  <div style={{ paddingLeft: "1vw", float: "left" }}>
                     <span style={{ fontSize: "1.8em" }}>{rentedCars}</span>
                     <Typography>Rented </Typography>
                  </div>
               </div>
            </Card>
         </Col>
         <Col span={6}>
            <Card
               className='sd-sm  dashboar-card'
               style={{ borderLeftColor: "red" }}
            >
               <div>
                  <div style={{ float: "left" }}>
                     <CarFilled
                        style={{
                           fontSize: "5em",
                           color: "red",
                        }}
                     />
                  </div>
                  <div style={{ paddingLeft: "1vw", float: "left" }}>
                     <span style={{ fontSize: "1.8em" }}>
                        {" "}
                        {underMaintenance}
                     </span>
                     <Typography>Under maintenance </Typography>
                  </div>
               </div>
            </Card>
         </Col>
      </Row>
   )
}
