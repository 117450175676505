import dayjs from "dayjs"
import { isEmpty } from "./utils"
import { DRIVERFEE } from "../data/enum"

export const computeTotalAmountPaid = (invoices) => {
   const initialValue = 0
   const sumWithInitial = invoices.reduce(
      (accumulator, currentValue) =>
         accumulator + parseInt(currentValue?.paidAmount ?? 0),
      initialValue
   )

   return sumWithInitial
}

export const computeTotalPayment = (payment) => {
   const {
      damageCost = 0,
      destinationFee = 0,
      extendFee = 0,
      rentalFee = 0,
      reservationFee = 0,
      withDriverFee = 0,
   } = payment
   let total = 0
   total =
      parseInt(damageCost ?? 0) +
      parseInt(destinationFee ?? 0) +
      parseInt(extendFee ?? 0) +
      parseInt(rentalFee ?? 0) +
      parseInt(reservationFee ?? 0) +
      parseInt(withDriverFee ?? 0)
   return total
}

export const getTotalMinutes = ({ start, end }) => {
	let minutes = 0
	if (dayjs(end).isValid() && dayjs(start).isValid()) {
		minutes = dayjs(end)?.diff(start, "minutes")
	}
	return minutes
}

export const getTotalHours = ({ start, end }) => {
   let hrs = 0
   if (dayjs(end).isValid() && dayjs(start).isValid()) {
      hrs = dayjs(end)?.diff(start, "hours")
   }
   return hrs
}

const computeFiveHoursTier = ({ totalHours, carRate }) => {
   const exceedHrPayment = (totalHours - 5) * carRate?.exceedingHours
   return carRate?.fiveHours + (exceedHrPayment ?? 0)
}
const computeEightHoursTier = ({ totalHours, carRate }) => {
   const exceedHrPayment = (totalHours - 8) * carRate?.exceedingHours
   return carRate?.eightHours + exceedHrPayment
}

const computeTwelveHoursTier = ({ totalHours, carRate }) => {
   const exceedHrPayment = (totalHours - 12) * carRate?.exceedingHours
   return carRate?.twelveHours + exceedHrPayment
}

export const computeDurationFee = ({ selectedCar, start, end }) => {
   if (isEmpty(selectedCar)) return 0 // no car selected yet

   let totalAmount = 0
   const { carRate } = selectedCar

   const totalHours = getTotalHours({ start, end })

   if (totalHours > 4 && totalHours < 8) {
      totalAmount = computeFiveHoursTier({ totalHours, carRate })
   }
   if (totalHours > 7 && totalHours < 12) {
      totalAmount = computeEightHoursTier({ totalHours, carRate })
   }
   if (totalHours > 11 && totalHours < 24) {
      totalAmount = computeTwelveHoursTier({ totalHours, carRate })
   }

   const exceedHrPayment = Math.floor(totalHours % 24) * carRate?.exceedingHours

   if (totalHours > 23) {
      const day = Math.floor(totalHours / 24)
      const twentyFourHoursPayment = carRate?.twentyFourHours * day
      const remainder = Math.floor(totalHours % 24)
      if (remainder < 5) {
         totalAmount = twentyFourHoursPayment + exceedHrPayment
      }
      if (remainder > 4 && remainder < 8) {
         const totalHoursLeft = totalHours - day * 24

         totalAmount =
            twentyFourHoursPayment +
            computeFiveHoursTier({ totalHours: totalHoursLeft, carRate })
      }
      if (remainder > 7 && remainder < 12) {
         const totalHoursLeft = totalHours - day * 24

         totalAmount =
            twentyFourHoursPayment +
            computeEightHoursTier({ totalHours: totalHoursLeft, carRate })
      }
      if (remainder > 11 && remainder < 17) {
         const totalHoursLeft = totalHours - day * 24


         totalAmount =
            twentyFourHoursPayment +
            computeTwelveHoursTier({ totalHours: totalHoursLeft, carRate })
      }
      if (remainder > 16 && remainder < 20) {
         const totalHoursLeft = totalHours - day * 24 - 12
         totalAmount =
            twentyFourHoursPayment +
            carRate?.twelveHours +
            computeFiveHoursTier({ totalHours: totalHoursLeft, carRate })
      }

      if (remainder > 19 && remainder < 24) {
         const totalHoursLeft = totalHours - day * 24 - 12

         totalAmount =
            twentyFourHoursPayment +
            carRate?.twelveHours +
            computeEightHoursTier({ totalHours: totalHoursLeft, carRate })
      }
   }

   return totalAmount
}

export const computeWithDriverFee = ({withDriver, withDriverFee, start, end }) => {
	let totalAmount = 0 // OOTD

	if (!withDriver) return 0

 // const totalDay = computeTotalDays({ start: date?.start, end: date?.end }) 
	const totalHours = getTotalHours({ start, end })
	if (totalHours > 4 && totalHours < 9) {
		totalAmount = DRIVERFEE.fiveEight
	}
	if (totalHours > 8 && totalHours < 13) {
			totalAmount =  DRIVERFEE.nineTwelve
	}
	if (totalHours > 12 && totalHours < 25) {
		totalAmount =  DRIVERFEE.thirteen 
	}
	
	if (totalHours > 24) {
		const perDayPayment =  DRIVERFEE.thirteen
		const exceedHrPayment = totalHours > 24 ? (totalHours - 24) * DRIVERFEE.hourly : 0
		const day = Math.floor(totalHours / 24)
		const twentyFourHoursPayment = perDayPayment  * day
		const remainder = Math.floor(totalHours % 24)
		if(remainder < 5) {
			totalAmount = twentyFourHoursPayment + exceedHrPayment
		}
		if (remainder > 4 && remainder < 9) {
			totalAmount = twentyFourHoursPayment + DRIVERFEE.fiveEight
		}
		if (remainder > 8 && remainder < 13) {
			totalAmount = twentyFourHoursPayment + DRIVERFEE.nineTwelve
		}
		if (remainder > 12 && remainder < 24) {
			totalAmount = twentyFourHoursPayment + DRIVERFEE.thirteen
		}
		

	}

	return totalAmount + withDriverFee
}



export const calculateTotalPaidAmount = (paymentsMade) => {
	return paymentsMade?.reduce((acc, payment) => {
		const paidAmount = parseFloat(payment?.paidAmount || 0);
		return acc + paidAmount;
	}, 0);
}