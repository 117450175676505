import {
   Button,
   Col,
   DatePicker,
   Divider,
   Drawer,
   Form,
   Popconfirm,
   Row,
   Select,
} from "antd"
import React, { useContext, useEffect, useState } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import { GlobalContext } from "../../../context/GlobalContext"
import CarHeader from "./CarHeader"
import InformationSection from "./InformationSection"
import ReleaseChecklist from "./ReleaseChecklist"
import SwitchCarDrawerController from "../controller/SwitchCarDrawerController"
import dayjs from "dayjs"
import PaymentsRowController from "../controller/PaymentsRowController"
import CheckedAndRecievedBy from "./CheckedAndRecievedBy"
import PaymentsRow from "./PaymentsRow"

function SwitchCarDrawer() {
   const [form] = Form.useForm()

   const {
      isSwitchCarDrawerOpen,
      dispatch,
      bookReservation,
      isLoading,
      oldBookReservationValues,
      exceedingFee,
      rentalAmountLeft,
      withDriverAmountLeft,
      destinationID,
      adjustment,
   } = useContext(CalendarContext)
   const { cars } = useContext(GlobalContext)
   const { car, payment, invoices } = bookReservation
   const { damageCost } = oldBookReservationValues

   const [newCar, setNewCar] = useState(car)
   const [dateRelease, setDateRelease] = useState(dayjs())

   const totalDiscount = parseInt(payment?.discount ?? 0)

   const { computBalance } = PaymentsRowController({
      payment,
      invoices,
      totalDiscount,
      damageCost,
      adjustment,
      form,
   })

   const {
      onChangeCar,
      closeModal,
      filterCarSelection,
      switchCar,
      handleChangeDateRelease,
   } = SwitchCarDrawerController({
      form,
      setNewCar,
      dispatch,
      oldBookReservationValues,
      computBalance,
      bookReservation,
      car,
      newCar,
      setDateRelease,
      exceedingFee,
      rentalAmountLeft,
      withDriverAmountLeft,
   })

   useEffect(() => {
      form.setFieldsValue({
         typeOfDamage: "minor",
         rentalPT1: 1,
         orcr: true,
         tools: true,
         jack: true,
         spareTire: true,
         dirtyStained: false,
         stinkyOdor: false,
         dateRelease: dayjs(),
         balance: computBalance(),
         ootd: destinationID,
         rentalFee: 0,
         destinationFee: 0,
         withDriverFee: 0,
      })

      //eslint-disable-next-line
   }, [])

   return (
      <Drawer
         closeIcon={isLoading ? false : true}
         maskClosable={isLoading ? false : true}
         title={null}
         onClose={closeModal}
         open={isSwitchCarDrawerOpen}
         width={"58vw"}
         style={{ borderLeft: `3px solid ${newCar?.color}` }}
         extra={
            <Popconfirm
               description='Are you sure you?'
               onCancel={null}
               onConfirm={switchCar}
               okText='Yes'
               cancelText='No'
               key='switch'
            >
               <Button
                  className='sd-sm'
                  style={{
                     backgroundColor: `${newCar?.color}`,
                     color: "white",
                     borderColor: "#f6c439",
                     border: "1px solid !important",
                  }}
                  loading={isLoading}
               >
                  SWITCH CAR
               </Button>
            </Popconfirm>
         }
      >
         <Form
            id='form'
            name='basic'
            layout='horizontal'
            form={form}
            onFinish={() => null}
            onFinishFailed={null}
            autoComplete='off'
         >
            <Row gutter={[8, 12]}>
               <Form.Item
                  label='Car'
                  name='car'
                  rules={[
                     {
                        required: true,
                        message: "Please select a car!",
                     },
                  ]}
                  style={{
                     width: "100%",
                  }}
               >
                  <Select
                     style={{
                        width: "100%",
                     }}
                     onChange={onChangeCar}
                     options={cars.filter(filterCarSelection)}
                     showSearch
                     filterOption={(input, option) =>
                        (option?.label ?? "")
                           .toLowerCase()
                           .includes(input.toLowerCase())
                     }
                  />
               </Form.Item>
               <CarHeader car={newCar} />

               <InformationSection car={newCar} />

               <Divider
                  style={{
                     backgroundColor: newCar?.color,
                  }}
               />
               <Form.Item
                  label='Date Release'
                  name='dateRelease'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Please select date",
                     },
                  ]}
               >
                  <DatePicker
                     onChange={handleChangeDateRelease}
                     format='MM-DD-YYYY HH:mm'
                     showTime={true}
                  />
               </Form.Item>
               <div>
                  <span className='nti'> End Date: </span>

                  <i>
                     {dayjs(bookReservation.end).format("MMMM DD, YYYY (h A)")}
                  </i>
               </div>
               <PaymentsRow form={form} isSwitched={true} />

               {/* <PaymentSectionSwitchedCar
                  newCar={newCar}
                  dateRelease={dateRelease}
									form={form}
               /> */}
               <Divider
                  style={{
                     backgroundColor: newCar?.color,
                  }}
               />
               <ReleaseChecklist />
               <Divider
                  style={{
                     backgroundColor: car?.color,
                  }}
               />
               <Col
                  className='nti'
                  span={24}
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
               >
                  PROCESSED
               </Col>
               <CheckedAndRecievedBy />
            </Row>
         </Form>
      </Drawer>
   )
}

export default SwitchCarDrawer
