import React, { useContext, useEffect, useRef } from "react"

import { Card, DatePicker, Select, Space } from "antd"

import MonthlyUnitExpenses from "./MonthlyUnitExpenses"
import SummaryMonthlyInventory from "./SummaryMonthlyInventory"
import PrintPdfMontlyInventory from "./PrintPdfMontlyInventory"

import { useReactToPrint } from "react-to-print"
import { GlobalContext } from "../../../context/GlobalContext"
import CutOffTable from "./CutOffTable"

import SERVER, { handleError } from "../../../lib/serverAxios"
import { MonthlySalesReportContext } from "../../../context/MonthlySalesReportContext"
import {
   CHANGE_CAR,
   CHANGE_SELECTED_CAR,
   SET_DATE,
   SET_LOADING,
   SET_MONTHLY_UNI_INVENTORY_REPORTS,
} from "../../../context/reducer/MonthlySalesReportReducer"
import MonthlySalesReportController from "../controllers/MonthlySalesReportController"
import { isEmpty } from "../../../lib/utils"
import MUSRFooter from "./MUSRFooter"

const PageMonthlySalesReport = () => {
   const { cars } = useContext(GlobalContext)
   const { selectedDate, dispatch, cutoff, selectedCar, previousBook } =
      useContext(MonthlySalesReportContext)

   const { disabledTo, onSearch, dateRangeTxt, getLastOdometer, lastMonthTxt } =
      MonthlySalesReportController({
         selectedDate,
         selectedCar,
         dispatch,
         previousBook,
         cutoff,
      })

   const componentRef = useRef()
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   const handleChangeCar = (value, car) => {
      dispatch({ type: CHANGE_CAR, cutoff: car?.cutoff, car })
   }

   const computeSubtotalUnitExpenses = (unitExpense) => {
      // Initialize variables to store total amount and car wash
      let totalAmount = 0
      let totalMaintenance = 0
      let totalReferral = 0
      let totalPickupOrDelivery = 0

      // Iterate through the unitExpense array
      unitExpense.forEach((expense) => {
         // Parse the amount and carWash values to numbers
         const maintenance = parseFloat(expense.maintenance)
         const referral = parseFloat(expense.referral)
         const pickupOrDelivery = parseFloat(expense.pickupOrDelivery)
         const amount = maintenance + referral + pickupOrDelivery

         // Add the parsed values to the totals
         totalMaintenance += maintenance
         totalReferral += referral
         totalPickupOrDelivery += pickupOrDelivery
         totalAmount += amount
      })
      return {
         totalAmount,
         totalMaintenance,
         totalPickupOrDelivery,
         totalReferral,
      }
   }

   const formulateUnitExpenseData = (_unitExpense) => {
      const unitExpense = _unitExpense.map((expense) => {
         return {
            ...expense,
            id: expense?.id,
            amount:
               parseFloat(expense?.maintenance) +
               parseFloat(expense?.referral) +
               parseFloat(expense?.pickupOrDelivery),
         }
      })
      const {
         totalAmount,
         totalMaintenance,
         totalPickupOrDelivery,
         totalReferral,
      } = computeSubtotalUnitExpenses(unitExpense)
      const data = [...unitExpense]
      const length = unitExpense.filter((expense) => !isEmpty(expense.description))?.length

      for (let i = length; i < 4; i++) {
         data.push({
            id: i + "addedRow",
            carWash: 0,
            amount: 0,
						description: "***hidden***"
         })
      }
      data.push({
         description: "Total Amount",
         amount: totalAmount,
         maintenance: totalMaintenance,
         pickupOrDelivery: totalPickupOrDelivery,
         referral: totalReferral,
         carWash: 0,
      })

      return data
   }

   const getMontlyUnitInventoryReports = async (value) => {
      dispatch({ type: SET_LOADING, isLoading: true })
      const date = value || selectedDate
      try {
         const { data } = await SERVER.get(
            `/api/reports/monthly?selectedDateString=${date}&cutoff=${cutoff}&carId=${selectedCar?.id}`
         )

         const unitExpense = await formulateUnitExpenseData(data?.unitExpense)
         dispatch({
            type: SET_MONTHLY_UNI_INVENTORY_REPORTS,
            bookReservations: data?.bookReservations,
            unitExpense,
         })
      } catch (error) {
         dispatch({ type: SET_LOADING, isLoading: false })

         handleError(error)
      }
   }

   const handleChangeDate = async (value, dateString) => {
      try {
         dispatch({ type: SET_DATE, selectedDate: value })
         getLastOdometer(value)
         await getMontlyUnitInventoryReports(value)
      } catch (error) {
         handleError(error)
      }
   }

   useEffect(() => {
      dispatch({ type: CHANGE_SELECTED_CAR, car: cars?.[0] })
      //eslint-disable-next-line
   }, [])

   useEffect(() => {
      dispatch({ type: SET_LOADING, isLoading: true })

      setTimeout(() => {
         getMontlyUnitInventoryReports()
         getLastOdometer()
      }, 1000)
      //eslint-disable-next-line
   }, [selectedCar])

   return (
      <div>
         <div ref={componentRef}>
            <div style={{ lineHeight: "80%" }}>
               <center>
                  <h2 level={2} type='secondary' onClick={null}>
                     Monthly Unit Inventory Report (
                     {selectedCar?.name?.toUpperCase()})
                  </h2>
                  <p level={5} type='secondary'>
                     {dateRangeTxt()}
                  </p>
                  {!isEmpty(previousBook) ? (
                     <p level={5} type='secondary'>
                        Last Odo Recorded as of {lastMonthTxt()} is{" "}
                        <b> {previousBook?.mileage?.end}</b>
                     </p>
                  ) : (
                     "No booked for the last 3 months"
                  )}
               </center>
            </div>

            <div
               className='print-hidden'
               style={{
                  padding: "1px",
                  marginBottom: "1vw",
                  textAlign: "right",
               }}
            >
               <Space size={"large"}>
                  <Space>
                     <span className='nti'> Car:</span>
                     <Select
                        value={selectedCar?.value}
                        defaultValue={selectedCar?.value}
                        style={{ width: "10vw" }}
                        onChange={handleChangeCar}
                        options={cars}
                        showSearch
                        onSearch={onSearch}
                     />
                  </Space>

                  <span className='nti'> Date:</span>
                  <DatePicker
                     style={{ width: "10vw" }}
                     onChange={handleChangeDate}
                     format='MMMM'
                     disabledDate={disabledTo}
                     picker='month'
                     value={selectedDate}
                  />
               </Space>
            </div>

            <Card
               className='sd-sm'
               style={{ marginTop: "0px", pageBreakAfter: "always" }}
            >
               <CutOffTable />
            </Card>
            <MonthlyUnitExpenses />
            {/* <CarRentalTable /> This is not needed according to Raffy and Sheikh yusuf */}
            <SummaryMonthlyInventory />
						<MUSRFooter />
         </div>

         <PrintPdfMontlyInventory handlePrint={handlePrint} />
      </div>
   )
}

export default PageMonthlySalesReport

const styles = {
   rowContainer: {
      textAlign: "right",
      background: " #fafafa",
      fontWeight: "bold",
   },
}
