import React, { useContext } from "react"
import { Col, Form, InputNumber, Row } from "antd"

import { CalendarContext } from "../../../context/CalendarContext"
import { isEmpty, totalHours, totalHoursDays } from "../../../lib/utils"
import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import CarStatusTag from "../../../common/components/CarStatusTag"
import { getBranchName } from "../../../lib/api"
import MininumHrs from "./MininumHrs"

dayjs.extend(isToday)

export default function AddBookedFooter({
   minDisplay,
   computeDurationFee,
   computeReservationFee,
   form,
}) {
   const { selectedCar, date, selectedDateStr, minDays } =
      useContext(CalendarContext)

   const reservationFee = Form.useWatch("reservationFee", form)
   const rentalFee = Form.useWatch("rentalFee", form)
   const destinationFee = Form.useWatch("destinationFee", form)
   const withDriverFee = Form.useWatch("withDriverFee", form)

   const isDateSelectedToday = dayjs(selectedDateStr).isToday()

   const _totalHours = totalHours({ start: date?.start, end: date?.end })

   const totalHoursDaysTxt = totalHoursDays({
      start: date?.start,
      end: date?.end,
   })

   const grandTotal = () => {
      const totalAmount =
         parseFloat(reservationFee || 0) +
         parseFloat(rentalFee || 0) +
         parseFloat(destinationFee || 0) +
         parseFloat(withDriverFee || 0)

      return totalAmount.toLocaleString("en-PH", {
         currency: "PHP",
         style: "currency",
      })
   }

   return (
      <Row>
         <Col span={12} key='dasdqew22'>
            <div key='Hours'>
               <span className='nti'> Duration:</span>
               {totalHoursDaysTxt}{" "}
               <MininumHrs
                  destinationFee={destinationFee}
                  minDays={minDays}
                  _totalHours={_totalHours}
               />
            </div>
            <div key='Reservation'>
               <Form.Item
                  label='Reservation Fee'
                  name='reservationFee'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Reservation fee is required",
                     },
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            </div>
            <div key='Hours'>
               <Form.Item
                  label='Rental Fee'
                  name='rentalFee'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Rental fee is required",
                     },
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            </div>

            <div key='withDriverFee'>
               <Form.Item
                  label='With Driver Fee'
                  name='withDriverFee'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "With driver fee is required",
                     },
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            </div>

            <div key='Destination'>
               <Form.Item
                  label='Destination Fee'
                  name='destinationFee'
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  rules={[
                     {
                        required: true,
                        message: "Destination fee is required",
                     },
                  ]}
               >
                  <InputNumber />
               </Form.Item>
            </div>

            <div key='1row'>&nbsp;</div>

            <div key='Amount'>
               <span className='nti'> Total Amount:</span>
               <b>
                  <i> {grandTotal()}</i>
               </b>
            </div>
         </Col>
         <Col span={12} key='dsad21424jsa'>
            {isDateSelectedToday && (
               <div key='status' style={{ display: "flex" }}>
                  <span className='nti'> Status:&nbsp;</span>
                  <b>
                     {!isEmpty(selectedCar) ? (
                        <CarStatusTag status={selectedCar?.status} />
                     ) : (
                        ""
                     )}
                  </b>
               </div>
            )}
            <div key='status'>
               <span className='nti'> Branch:</span>
               <b> {getBranchName(selectedCar?.branchId)} </b>
            </div>
            <div key='Gas'>
               <span className='nti'> Gas:</span>
               <b>
                  {" "}
                  {selectedCar?.gas} {selectedCar && "bar"}{" "}
               </b>
            </div>
            <div key='Odometer'>
               <span className='nti'> Odometer:</span>
               <b> {selectedCar?.odometer} </b>
            </div>
            <div key='OdomTypeeter'>
               <span className='nti'> Type:</span>
               <b> {selectedCar?.type.toUpperCase()} </b>
            </div>
         </Col>
      </Row>
   )
}
