import React from "react"
import { convertToDay } from "../../../lib/utils"
import HoursORDay from "./HoursORDay"

export default function MininumHrs({ _totalHours, destinationFee, minDays }) {
   const hasDestination = destinationFee > 0 && minDays > _totalHours
   const isLessThanFiveHours = _totalHours < 5 && _totalHours !== 0
   if (destinationFee > 0 &&_totalHours   < minDays ) {
      return <HoursORDay minDays={minDays} />
   }
   if (isLessThanFiveHours > 0) {
      return <span style={{ color: "red" }}>(Minimum is 5hrs)</span>
   }
   return ""
}
