import React, { Fragment, useContext, useEffect, useState } from "react"
import "./styles.css"

import {
   Select,
   Modal,
   Row,
   Col,
   Form,
   TimePicker,
   DatePicker,
   Checkbox,
   Divider,
   Button,
   message,
   Switch,
   Space,
} from "antd"
import AddBookedFooter from "./AddBookedFooter"

import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import SERVER, { handleError } from "../../../lib/serverAxios"
import PaidSection from "./PaidSection"

import { GlobalContext } from "../../../context/GlobalContext"
import { CalendarContext } from "../../../context/CalendarContext"
import { CustomerContext } from "../../../context/CustomerContext"
import { SET_CUSTOMERS } from "../../../context/reducer/CustomerReducer"
import {
   ACTION_FULFILLED,
   ACTION_IN_PROGRESS,
   RESET_FORM_STATE,
} from "../../../context/reducer/CalendarReducer"

import SelectCarController from "../controller/SelectCarController"

import { getCustomers } from "../../../lib/services"
import { isEmpty, totalHours } from "../../../lib/utils"
import { computeWithDriverFee } from "../../../lib/calculation"

dayjs.extend(isToday)

const SelectCarForCalendarModal = ({
   isSelectCarModalOpen,
   setIsSelectCarModalOpen,
   calendarRef,
   fullCalendarState,
}) => {
   const { customers, dispatch: dispatchCustomer } = useContext(CustomerContext)

   const { outOfTownDestinations, cars } = useContext(GlobalContext)
   const {
      dispatch,
      selectedCar,
      destinationFee,
      minDays,
      withDriver,
      withDriverFee,
      date,
      isLoading,
      selectedDateStr,
      isCustomer,
   } = useContext(CalendarContext)

   const [state, setState] = useState({
      isOOTD: false,
      destinationFee: 0,
      minDays: 0,
      withDriver: 0,
      destinationID: 0,
      selectedCar: null,
   })

   const [form] = Form.useForm()

   const {
      onChangeOOTD,
      handleChangeCar,
      disabledTo,
      handleChangeOOTD,
      minDisplay,
      handleCancel,
      onFieldsChange,
      onFinishFailed,
      toggleWithDriver,
      computeDurationFee,
      computeReservationFee,
      isDateSelectedToday,
      filterCarSelection,
      toggleOwnerCustomer,
   } = SelectCarController({
      selectedDateStr,
      withDriver,
      dispatch,
      selectedCar,
      fullCalendarState,
      setIsSelectCarModalOpen,
      date,
      setState,
      form,
      minDays,
      destinationFee,
      withDriverFee,
   })

   const onSearch = async (value) => {
      try {
         const { data } = await SERVER.get(
            `/api/customer/search?fullname=${value}`
         )
         dispatchCustomer({ type: SET_CUSTOMERS, customers: data?.customers })
      } catch (error) {
         handleError(error)
      }
   }

   const handleOk = async () => {
      dispatch({ type: ACTION_IN_PROGRESS })
      try {
         let bookReservationsArray = []
         const bookReservationsValues = await form.getFieldsValue()
         let calendarApi = await calendarRef.current.getApi()
         const owner =
            isEmpty(bookReservationsValues?.customer) || isCustomer === false
         if (owner) {
            const {
               data: { bookReservations },
            } = await SERVER.post(`/api/books-reservation/owner`, {
               ...bookReservationsValues,
            })
            bookReservationsArray = bookReservations
         } else {
            const {
               data: { bookReservations },
            } = await SERVER.post(`/api/books-reservation`, {
               ...bookReservationsValues,
               payments: {
                  reservationFee: bookReservationsValues?.reservationFee,
                  withDriverFee: bookReservationsValues?.withDriverFee,
                  destinationFee: bookReservationsValues?.destinationFee,
                  rentalFee: bookReservationsValues?.rentalFee,
               },
               paid: {
                  reservation: bookReservationsValues.reservation,
               },
            })
            bookReservationsArray = bookReservations
         }

         calendarApi.unselect()
         calendarApi.addEvent({
            id: String(bookReservationsArray.id),
            allDay: false,
            title: selectedCar?.name,
            color: selectedCar?.color,
            start: dayjs(date.start).format(),
            end: dayjs(date.end).format(),
            hours: bookReservationsArray.hours,
         })
         message.success("Book/reservation successfully created.")
         setIsSelectCarModalOpen(false)
         dispatch({ type: ACTION_FULFILLED })
         dispatch({ type: RESET_FORM_STATE })
         //  window.location.reload()
      } catch (error) {
         handleError(error)
         dispatch({ type: ACTION_FULFILLED })
      }
   }

   const filterOption = (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

   const setCustomers = async () => {
      try {
         const data = await getCustomers()
         dispatchCustomer({
            type: SET_CUSTOMERS,
            customers: data?.customers?.rows,
         })
      } catch (error) {
         handleError(error)
      }
   }
   useEffect(() => {
      setCustomers()
      //eslint-disable-next-line
   }, [])
   useEffect(() => {
      form.setFieldValue("start", dayjs(selectedDateStr))
      setCustomers()
      //eslint-disable-next-line
   }, [fullCalendarState])

   const title = () => {
      return (
         <Space>
            Booking / Reservation
            <Switch
               checkedChildren='Customer'
               unCheckedChildren='Owner'
               defaultChecked
               onChange={toggleOwnerCustomer}
            />
         </Space>
      )
   }

   const _totalHours = totalHours({ start: date?.start, end: date?.end })
   const notMeetTheMinDaysOOTD = destinationFee > 0 && minDays > _totalHours

   return (
      <Modal
         title={title()}
         open={isSelectCarModalOpen}
         closeIcon={false}
         width={800}
         footer={[
            <Button disabled={isLoading} key='back' onClick={handleCancel}>
               Cancel
            </Button>,
            <Button
               form='book-form'
               htmlType='submit'
               type='primary'
               key='primary'
               loading={isLoading}
               disabled={
                  isLoading ||
                  (computeDurationFee() < 1 && isCustomer) ||
                  notMeetTheMinDaysOOTD
               }
            >
               {isDateSelectedToday ? "Book" : "Reserve"}
            </Button>,
         ]}
      >
         <Divider
            style={{
               backgroundColor: selectedCar?.color,
            }}
         />

         <Form
            id='book-form'
            name='basic'
            layout='vertical'
            form={form}
            style={{
               maxWidth: 800,
               marginTop: "1vw !important",
            }}
            initialValues={{
               remember: true,
            }}
            onFinish={() => handleOk()}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            autoComplete='off'
         >
            <Row style={{ marginTop: "1vw" }}>
               <Col span={24} key='car'>
                  <Form.Item
                     label='Car'
                     name='car'
                     rules={[
                        {
                           required: true,
                           message: "Please select a car!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Select
                        style={{
                           width: "100%",
                        }}
                        onChange={handleChangeCar}
                        options={cars.filter(filterCarSelection)}
                        showSearch
                        filterOption={(input, option) =>
                           (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        }
                     />
                  </Form.Item>
               </Col>
               {isCustomer && (
                  <Col span={24} key='customer'>
                     <Form.Item
                        label='Customer'
                        name='customer'
                        rules={[
                           {
                              required: true,
                              message: "Please select a customer!",
                           },
                        ]}
                     >
                        <Select
                           // defaultValue='suv'
                           style={{
                              width: "100%",
                           }}
                           options={customers}
                           onSearch={onSearch}
                           showSearch
                           filterOption={filterOption}
                        />
                     </Form.Item>
                  </Col>
               )}

               <Col span={12} key='start'>
                  <Form.Item
                     label={`Start (${dayjs(selectedDateStr).format(
                        "MM/DD/YYYY"
                     )})`}
                     name='start'
                     rules={[
                        {
                           required: true,
                           message: "Please input hours of rent!",
                        },
                     ]}
                  >
                     <TimePicker format='HH:mm' />
                  </Form.Item>
               </Col>
               <Col span={12} key='end'>
                  <Form.Item
                     label='End (Date Return)'
                     name='end'
                     rules={[
                        {
                           required: true,
                           message: "Please input hours of rent!",
                        },
                     ]}
                  >
                     <DatePicker
                        format='MM-DD-YYYY HH:mm'
                        showTime={true}
                        disabledDate={disabledTo}
                     />
                  </Form.Item>
               </Col>
               {isCustomer && (
                  <Fragment>
                     <Col
                        span={24}
                        key='with driver'
                        style={{ paddingTop: "5px" }}
                     >
                        <Form.Item name='withDriver' valuePropName='checked'>
                           <Checkbox onChange={toggleWithDriver}>
                              With Driver{" "}
                           </Checkbox>
                        </Form.Item>
                     </Col>

                     <Col
                        span={10}
                        key='Out of town destination'
                        style={{ paddingTop: "5px" }}
                     >
                        <Checkbox
                           checked={state.isOOTD}
                           onChange={onChangeOOTD}
                        >
                           Out of town destination:
                        </Checkbox>
                     </Col>
                     <Col span={12} key='ootd'>
                        {state.isOOTD && (
                           <Form.Item
                              rules={[
                                 {
                                    required: true,
                                    message: "Please select destination",
                                 },
                              ]}
                              name='ootd'
                              valuePropName='checked'
                           >
                              <Select
                                 style={{
                                    width: "100%",
                                 }}
                                 options={outOfTownDestinations}
                                 onChange={handleChangeOOTD}
                              />
                           </Form.Item>
                        )}
                     </Col>
                  </Fragment>
               )}
            </Row>
            {isCustomer && (
               <Fragment>
                  <Divider
                     style={{
                        backgroundColor: selectedCar?.color,
                     }}
                  />
                  <AddBookedFooter
                     form={form}
                     computeDurationFee={computeDurationFee}
                     minDisplay={minDisplay}
                     computeReservationFee={computeReservationFee}
                  />
                  {!isDateSelectedToday && (
                     <Fragment>
                        <Divider
                           style={{
                              backgroundColor: selectedCar?.color,
                           }}
                        />

                        <b> PAYMENT</b>
                        <PaidSection />
                     </Fragment>
                  )}
               </Fragment>
            )}
         </Form>
      </Modal>
   )
}

export default SelectCarForCalendarModal
