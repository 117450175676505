import React from "react"
import { convertToDay } from "../../../lib/utils"

export default function HoursORDay({ minDays }) {
	const totalDay = convertToDay(minDays)
   if (minDays < 24) {
      return <span style={{ color: "red" }}>(Minimum is {minDays}hrs)</span>
   } else {
      return <span style={{ color: "red" }}>(Minimum is {totalDay} {totalDay > 1 ? "days" : "day"})</span>
   }
}
