import React from "react"

import dayjs from "dayjs"

import { Tag } from "antd"
import { isEmpty } from "../../../lib/utils"
import {
   CheckCircleFilled,
   CloseCircleFilled,
   CloseCircleOutlined,
   ControlFilled,
   EnvironmentFilled,
   SwapOutlined,
} from "@ant-design/icons"
import isToday from "dayjs/plugin/isToday"
import EventController from "../controller/EventController"
import { BOOKSTATE, FLAG_BOOK } from "../../../data/enum"

dayjs.extend(isToday)

export default function EventContent({ eventInfo, bookReservations }) {
   const { start, end, title, id } = eventInfo.event
   //const carName = title.split("#***#")[0]
   // const state = title.split("#***#")[1]
   const [carName, state, flag] = title.split("#***#")

   const bookReservation = bookReservations.find(
      (reservation) => reservation.id === parseInt(id)
   )

   const { timePassed, isMinutePast, isBookDone, isReservationCancelled } =
      EventController({ state, bookReservation })

   //  console.table({
   //     title: title,
   //     dateReturned: isEmpty(bookReservation?.dateReturned),
   //     "!dateReleased": !isEmpty(bookReservation?.dateReleased),
   //     isMinutePast: isMinutePast,
   //     "!isBookDone": !isBookDone,
   //     "!isReservationCancelled": !isReservationCancelled,
   //  })

   const isOnGoing = state === BOOKSTATE.released
   const isSwitched = state === BOOKSTATE.switched

   const isOverDue =
      !isEmpty(bookReservation?.dateReleased) &&
      isMinutePast &&
      !isBookDone &&
      !isReservationCancelled

   return (
      <div className='sd-sm' style={styles.eventRender}>
         <i className='sd-sm' style={styles.carName}>
            {carName.toUpperCase()}&nbsp;
         </i>
         {isReservationCancelled ? (
            <CloseCircleFilled style={{ color: "#DDA15E" }} />
         ) : (
            <b>
               {dayjs(start).format("hh:mm A")} - {dayjs(end).format("hh:mm A")}
            </b>
         )}
         {isBookDone ? (
            <CheckCircleFilled style={{ marginLeft: "5px" }} />
         ) : null}
         {/* {bookReservation?.dateReturned} */}
         {isOverDue ? (
            <Tag color='red' style={{ marginLeft: "5px" }}>
               OVER DUE ({timePassed()})
            </Tag>
         ) : null}

         {isSwitched && <SwapOutlined style={{ marginLeft: "5px" }} />}
         {isOnGoing && <EnvironmentFilled style={{ marginLeft: "5px" }} />}
         {flag === FLAG_BOOK.switchedBooked && (
            <ControlFilled style={{ marginLeft: "5px" }} />
         )}
      </div>
   )
}

const styles = {
   eventRender: {
      padding: "2px",
      letterSpacing: "2px",
   },
   carName: {
      letterSpacing: "4px",
   },
}
