import React from 'react'
import CustomerManagementPage from './CustomerManagementPage'
import { CustomerProvider } from '../../../context/CustomerContext'

export default function CustomerManagement() {
	return (
		<CustomerProvider>
			<CustomerManagementPage />
		</CustomerProvider>
	)
}
