import { FLAG_BOOK } from "../../../data/enum"
import {
   calculateTotalPaidAmount,
   computeTotalAmountPaid,
} from "../../../lib/calculation"
import { safeDestructuringOfObject } from "../../../lib/utils"

const PaymentsRowController = ({
   invoices = [],
   payment = {},
   totalDiscount,
   paymentsMade,
   flag,
   payments,
   damageCost,
   paidAlready = 0,
   isFinished = false,
}) => {
   const paymentToCompute = isFinished ? payment : payments
   const _damageCost = damageCost || payment?.damageCost || 0

   const properties = [
      { property: "reservationFee", defaultValue: 0 },
      { property: "rentalFee", defaultValue: 0 },
      { property: "withDriverFee", defaultValue: 0 },
      { property: "destinationFee", defaultValue: 0 },
      { property: "extendFee", defaultValue: 0 },
   ]

   const {
      reservationFee,
      rentalFee,
      withDriverFee,
      destinationFee,
      extendFee,
      previousBalance,
   } = safeDestructuringOfObject(paymentToCompute, properties)

   const subTotal = [
      extendFee,
      reservationFee,
      rentalFee,
      withDriverFee,
      destinationFee,
      _damageCost,
      previousBalance,
   ].reduce((acc, val) => acc + parseInt(val || 0), 0) // will change this

   //   console.table({
   //  	extendFee: extendFee,
   //  	reservationFee: reservationFee,
   //  	rentalFee: rentalFee,
   //  	withDriverFee:_withDriverFee,
   //  	destinationFee: _destinationFee,
   //  	_damageCost:_damageCost,
   //  	previousBalance:previousBalance
   //  })

   const computBalanceSwitchedBooked = () => {
      let total = 0
      total =
         subTotal -
         totalDiscount - // previous discount - adjustment
         paidAlready -
         calculateTotalPaidAmount(paymentsMade || []) +
         payments?.previousBalance
      return total
   }

   const computBalance = () => {
      let total = 0
      total =
         subTotal -
         totalDiscount -
         computeTotalAmountPaid(invoices) -
         calculateTotalPaidAmount(paymentsMade || []) -
         payment?.paidAlready

      if (flag === FLAG_BOOK.switchedBooked) {
         total = total + parseInt(payment?.previousBalance || 0) // remove this because it's already been add on the previous book so any balance will be added to previousBalance
      }
      return total
   }

   return { subTotal, computBalance, computBalanceSwitchedBooked }
}

export default PaymentsRowController
