import dayjs from "dayjs"
import {
   checkIfNoReservationFee,
   computeTotalDays,
   convertToDay,
   isEmpty,
} from "../../../lib/utils"
import {
   RESET_FORM_STATE,
   RESET_OOTD,
   SET_DATE,
   SET_OOTD,
   SET_SELECTED_CAR,
   TOGGLE_OWNER_CUSTOMER,
   TOGGLE_W_DRIVER,
} from "../../../context/reducer/CalendarReducer"
import { CARSTATUS, CAR_TYPE } from "../../../data/enum"
import { computeWithDriverFee } from "../../../lib/calculation"

const SelectCarController = ({
   selectedDateStr,
   selectedCar,
   fullCalendarState,
   setState,
   date,
   setIsSelectCarModalOpen,
   form,
   dispatch,
   minDays,
   destinationFee,
   withDriverFee,
   withDriver,
}) => {
   const isDateSelectedToday = dayjs(selectedDateStr).isToday()
   const noReservationFee = checkIfNoReservationFee(selectedDateStr) // today or late input

   const filterCarSelection = (car) => {
      if (isDateSelectedToday) return car.status === CARSTATUS.available
      return true
   }

   const handleChangeOOTD = (_, data) => {
      const { minDays, fee, withDriverFee, id } = data
      dispatch({
         type: SET_OOTD,
         destinationFee: fee,
         minDays,
         withDriverFee,
         destinationID: id,
      })
   }

   const getTotalHours = () => {
      let hrs = 0
      if (dayjs(date?.end).isValid() && dayjs(date?.start).isValid()) {
         hrs = dayjs(date?.end)?.diff(date?.start, "hours")
      }
      return hrs
   }

   const computeFiveHoursTier = ({ totalHours, carRate }) => {
      const exceedHrPayment = (totalHours - 5) * carRate?.exceedingHours
      return carRate?.fiveHours + (exceedHrPayment ?? 0)
   }
   const computeEightHoursTier = ({ totalHours, carRate }) => {
      const exceedHrPayment = (totalHours - 8) * carRate?.exceedingHours
      return carRate?.eightHours + exceedHrPayment
   }

   const computeTwelveHoursTier = ({ totalHours, carRate }) => {
      const exceedHrPayment = (totalHours - 12) * carRate?.exceedingHours
      return carRate?.twelveHours + exceedHrPayment
   }
   const computeDurationFee = () => {
      if (isEmpty(selectedCar)) return 0 // no car selected yet

      let totalAmount = 0
      const { carRate, type } = selectedCar

      const totalHours = getTotalHours()

      if (totalHours > 4 && totalHours < 8 && type !== CAR_TYPE.truck) {
         totalAmount = computeFiveHoursTier({ totalHours, carRate })
      }
      if (totalHours > 7 && totalHours < 12 && type !== CAR_TYPE.truck) {
         totalAmount = computeEightHoursTier({ totalHours, carRate })
      }
      if (totalHours > 11 && totalHours < 24) {
         totalAmount = computeTwelveHoursTier({ totalHours, carRate })
      }

      const exceedHrPayment =
         Math.floor(totalHours % 24) * carRate?.exceedingHours

      if (totalHours > 23) {
         const day = Math.floor(totalHours / 24)
         const twentyFourHoursPayment = carRate?.twentyFourHours * day
         const remainder = Math.floor(totalHours % 24)
         if (remainder < 5) {
            console.log("** 1")
            totalAmount = twentyFourHoursPayment + exceedHrPayment
         }
         if (remainder > 4 && remainder < 8) {
            const totalHoursLeft = totalHours - day * 24

            console.log("** 2")

            totalAmount =
               twentyFourHoursPayment +
               computeFiveHoursTier({ totalHours: totalHoursLeft, carRate })
         }
         if (remainder > 7 && remainder < 12) {
            const totalHoursLeft = totalHours - day * 24

            console.log("** 3")

            totalAmount =
               twentyFourHoursPayment +
               computeEightHoursTier({ totalHours: totalHoursLeft, carRate })
         }
         if (remainder > 11 && remainder < 17) {
            const totalHoursLeft = totalHours - day * 24

            totalAmount =
               twentyFourHoursPayment +
               computeTwelveHoursTier({ totalHours: totalHoursLeft, carRate })
         }
         if (remainder > 16 && remainder < 20) {
            console.log("** 5")
            const totalHoursLeft = totalHours - day * 24 - 12
            totalAmount =
               twentyFourHoursPayment +
               carRate?.twelveHours +
               computeFiveHoursTier({ totalHours: totalHoursLeft, carRate })
            console.log(
               "TOTAL",
               twentyFourHoursPayment + "carRate?.twelveHours",
               carRate?.twelveHours,
               ":",
               computeFiveHoursTier({ totalHours: totalHoursLeft, carRate })
            )
         }

         if (remainder > 19 && remainder < 24) {
            console.log("** 6")
            const totalHoursLeft = totalHours - day * 24 - 12

            totalAmount =
               twentyFourHoursPayment +
               carRate?.twelveHours +
               computeEightHoursTier({ totalHours: totalHoursLeft, carRate })
         }
      }

      return totalAmount
   }

   const handleCancel = () => {
      dispatch({ type: RESET_FORM_STATE })
      setIsSelectCarModalOpen(false)
   }
   const disabledTo = (current) => {
      return (
         current <
         dayjs(fullCalendarState?.startStr).subtract(1, "day").endOf("day")
      )
   }
   const foundCar = (e) => {
      return e.id === selectedCar.id
   }

   const handleChangeCar = (value, data) => {
      dispatch({ type: SET_SELECTED_CAR, car: data })
   }

   const onChangeOOTD = (e) => {
      setState((prevState) => ({
         ...prevState,
         isOOTD: e.target.checked,
      }))
      dispatch({ type: RESET_OOTD })
   }

   const toggleWithDriver = (e) => {
      dispatch({ type: TOGGLE_W_DRIVER, withDriver: e.target.checked })
   }

   const onFieldsChange = async (changedFields) => {
      const carValues = await form.getFieldsValue()
      const { start = date.start, end = date.end } = carValues

      const startOrEndDateChange = !isEmpty(changedFields)
         ? changedFields[0].name?.includes("start") ||
           changedFields[0].name?.includes("end")
         : false

      if (startOrEndDateChange) {
         dispatch({ type: SET_DATE, start, end })
      }
   }

   const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo)
   }

   const minDisplay = () => {
      const _minDay = convertToDay(minDays)
      if (_minDay > 0) {
         return _minDay + " days"
      } else {
         return <>{minDays} hrs </>
      }
   }

   const computeReservationFee = () => {
      if (noReservationFee) return 0
      if (isEmpty(selectedCar?.carRate?.reservationFee)) {
         return 0
      }
      const reservationFee =
         selectedCar?.carRate?.reservationFee *
         computeTotalDays({ start: date?.start, end: date?.end })
      return reservationFee
   }

   const toggleOwnerCustomer = (checked) => {
      dispatch({ type: TOGGLE_OWNER_CUSTOMER, checked })
   }

   return {
      toggleOwnerCustomer,
      toggleWithDriver,
      minDisplay,
      onFinishFailed,
      onFieldsChange,
      onChangeOOTD,
      handleChangeCar,
      foundCar,
      disabledTo,
      handleCancel,
      handleChangeOOTD,
      getTotalHours,
      computeDurationFee,
      computeReservationFee,
      computeWithDriverFee,
      isDateSelectedToday,
      filterCarSelection,
   }
}

export default SelectCarController
