import React, { useEffect, useState } from "react"
import Table from "../../../common/components/Table"
import { Typography } from "antd"
import { isEmpty } from "../../../lib/utils"
import { getOverDueBooksAndReservations } from "../../calendar/api/calendarAPI"
import dayjs from "dayjs"

const { Title } = Typography

const OverdueBooked = ({ isLoading, updateCount }) => {
   const [bookReservations, setBookReservations] = useState([])

   const getOverdueBooked = async () => {
      const [bookReservationsArray, errorMsg] =
         await getOverDueBooksAndReservations()
      if (errorMsg) return
      setBookReservations(bookReservationsArray)
   }

   useEffect(() => {
      getOverdueBooked()
   }, [updateCount])

   const columns = [
      {
         title: "Expected Returned",
         dataIndex: "end",
         key: "end",
         render: (end) => {
            return <span style={{	fontSize: "12px"}}> {dayjs(end).format("MMMM DD, YYYY (hh A)")}</span>
         },
      },
			{
				title: "Car",
				dataIndex: "car",
				key: "car",
				render: (car) => {
					 return (
							<div
								 style={{
										backgroundColor: car.color,
										color: "white",
										padding: "2px",
										fontSize: "12px"

								 }}

							>
								 &nbsp;{car?.name?.toUpperCase()}
							</div>
					 )
				},
		 },
      {
         title: "Customer",
         dataIndex: "customer",
         key: "customer",
         render: (customer, record) => {
            const middleName = isEmpty(record?.customer?.middleName)
               ? ""
               : record?.customer?.middleName?.charAt(0)?.toUpperCase() + "."
            return (
               <Typography
                  style={{
                     color:
                        !isEmpty(record?.customer?.offense) ||
                        record?.customer?.category === 3
                           ? "red"
                           : "black",
											fontSize: "12px"
                  }}
               >
                  {customer?.firstName} {middleName} &nbsp;
                  {record?.customer?.lastName}
               </Typography>
            )
         },
      },
			{
				title: "Contact No.",
				key: "contact_no",
				dataIndex: "customer",
				width: 100,
				render: (_, { customer }) => (
					<span style={{	fontSize: "12px"}}>
							1. {customer?.contactNo1}
							<br />
							2. {customer?.contactNo2}
					 </span>
				),
		 },
      {
         title: "No.",
         key: "no",
         dataIndex: "end",
				 render: (end) => {
		      const days = dayjs()?.diff(end, "day")
					const dayString =
					days > 0 ? `${days} days` : `${days} day`
			
					return <span  style={{	fontSize: "12px", fontWeight: "bold"}}> {dayString} </span>

				 }
      },
   ]

   return (
      <div>
         <div>
            <div style={{ float: "left" }}>
               <Title level={5}>Overdue</Title>
            </div>
            <div style={{ float: "right" }}></div>
         </div>
         <Table
            isLoading={isLoading}
            columns={columns}
            dataSource={bookReservations}
         />
      </div>
   )
}

export default OverdueBooked
