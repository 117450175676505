import { Form } from 'antd'
import React from 'react'
import { EditableContext } from './CarExpensesTable'

export default function EditableRow({ index, ...props }) {
	const [form] = Form.useForm()
	return (
		 <Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					 <tr {...props} />
				</EditableContext.Provider>
		 </Form>
	)
}
