import React, { useReducer } from 'react'

import { MonthlySalesReportReducer } from './reducer/MonthlySalesReportReducer'

import dayjs from 'dayjs'

const initialState = {
   isLoading: false,
   selectedCar: {id: 1},
	 selectedDate: dayjs(),
	 selectedDay: dayjs(),
	 cutoff: 30,
	 bookReservations: [],
	 unitExpense: [],
	 unitTotalGross: 0,
	 startDateCutoff: dayjs(),
	 previousBook: {}
}

export const MonthlySalesReportContext = React.createContext()

export const MonthlySalesReportProvider = (props) => {
   const [state, dispatch] = useReducer(
      MonthlySalesReportReducer,
      initialState
   )

   return (
      <MonthlySalesReportContext.Provider
         value={{
            ...state,
            dispatch,
         }}
      >
         {props.children}
      </MonthlySalesReportContext.Provider>
   )
}
