import { Col, Row } from "antd"
import React from "react"

export default function MUSRFooter() {
   return (
      <Row className="pdf-print" style={{marginTop: "2vw"}}>
         <Col span={3}></Col>
         <Col span={7} style={{ textAlign: "center" }}>
            Remitted By:
            <div style={{ borderTop: "1px solid black", marginTop: "3vw" }}>
               Signature Over Printed Name
            </div>
         </Col>
         <Col span={4}></Col>
         <Col span={7} style={{ textAlign: "center" }}>
            Recieved By:
            <div style={{ borderTop: "1px solid black", marginTop: "3vw" }}>
               Signature Over Printed Name
            </div>
         </Col>
         <Col span={3}></Col>
      </Row>
   )
}
