import React, { Fragment, useContext } from "react"

import { Checkbox, Col, Divider, Form, Input, InputNumber, Select } from "antd"

import { CalendarContext } from "../../../context/CalendarContext"
import { typeOfDamageData } from "../../../data/data"
import { CHANGE_DAMAGE_COST, TOGGLE_HAS_DAMAGE } from "../../../context/reducer/CalendarReducer"

const { TextArea } = Input

export default function ChargeDamageRow() {

   const { bookReservation, dispatch, hasDamage } = useContext(CalendarContext)

   const { car } = bookReservation

   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            CHARGE DETAILS
         </Col>

         <Col span={8}>
            <Form.Item
               name='hasDamage'
							 valuePropName="checked"
               style={{
                  width: "100%",
               }}
            >
               <Checkbox onChange={(e) => dispatch({type: TOGGLE_HAS_DAMAGE, hasDamage: e?.target?.checked })}>
                  Has Damage ?
               </Checkbox>
            </Form.Item>
            {hasDamage && (
               <>
                  <Form.Item
                     label='Type of damage'
                     name='typeOfDamage'
                     rules={[
                        {
                           required: true,
                           message: "Please select type of damage!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Select
                        style={{ width: "100%" }}
                        options={typeOfDamageData}
                     />
                  </Form.Item>

                  <Form.Item
                     name='specify'
										 label="Specify"
                     rules={[
                        {
                           required: true,
                           message: "Please specify the damage",
                        },
                     ]}
                     style={{
                        width: "100%",
                        marginTop: "40px",
                     }}
                  >
                   
                        <TextArea
                           rows={8}
                           autoSize={{
                              minRows: 2,
                              maxRows: 8,
                           }}
                        />
                  </Form.Item>

                  <Form.Item
                     name='damageCost'
										 label="Damage Cost"
                     rules={[
                        {
                           required: true,
                           message: "Please input the cost!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                    
                        <InputNumber onChange={(damageCost) => dispatch({type: CHANGE_DAMAGE_COST, damageCost: damageCost })} style={{ width: "100%" }} />
                  </Form.Item>
               </>
            )}
         </Col>
         <Col span={16}></Col>
      </Fragment>
   )
}
