import SERVER, { handleError } from '../../../lib/serverAxios'
import {
   SET_BOOK_RESERVATION,
   TOGGLE_DRAWER,
} from '../../../context/reducer/CalendarReducer'

const CalendarPageController = ({ dispatch }) => {
   const handleEventClick = async (clickInfo) => {
      try {
         const id = parseInt(clickInfo.event.id)

         const {
            data: { bookReservations },
         } = await SERVER.get(`/api/books-reservation/fine-one?id=${id}`)

         dispatch({
            type: SET_BOOK_RESERVATION,
            bookReservation: bookReservations,
         })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: true })
      } catch (error) {
        handleError(error)
      }

      // clickInfo.event.remove()
   }
   return { handleEventClick }
}

export default CalendarPageController
