import { Button, Card } from "antd"
import { Typography } from "antd"

import React, { useContext, useEffect, useState } from "react"
import Table from "../../../common/components/Table"
import {
   getBooksAndReservationsReleaseForToday,
} from "../../calendar/api/calendarAPI"
import dayjs from "dayjs"
import { BOOKSTATE } from "../../../data/enum"
import { isEmpty } from "../../../lib/utils"
import { CalendarContext } from "../../../context/CalendarContext"
import ReleaseForTodayController from "../controller/ReleaseForTodayController"
const { Title } = Typography

export default function ReleaseForToday({updateCount, isSelectCarModalOpen }) {
   const { dispatch, isBookReservationDrawerOpen } = useContext(CalendarContext)
   useContext(CalendarContext)

   const { handleReleaseReturn } = ReleaseForTodayController({ dispatch })

   const [state, setState] = useState({
      isAddUpdateOpen: false,
      isLoading: false,
   })
   const [bookReservations, setBookReservations] = useState([])

   const columns = [
      {
         title: "Car",
         dataIndex: "car",
         key: "car",
         render: (car) => {
            return (
               <div
                  style={{
                     backgroundColor: car.color,
                     color: "white",
                     padding: "2px",
                  }}
               >
                  &nbsp;{car?.name?.toUpperCase()}
               </div>
            )
         },
      },
      {
         title: "Customer",
         dataIndex: "customer",
         key: "customer",
         render: (customer, record) => {
            const middleName = isEmpty(customer?.middleName)
               ? ""
               : customer?.middleName?.charAt(0)?.toUpperCase() + "."
            return (
               <Typography>
                  {customer?.firstName} {middleName} &nbsp;
                  {customer?.lastName}
               </Typography>
            )
         },
      },
      {
         title: "Date",
         dataIndex: "state",
         key: "date",
         render: (state, record) => {
            if (state === BOOKSTATE.reserved)
               return <div>{dayjs(record?.start).format("hh A")}</div>
            if (state === BOOKSTATE.released)
               return <div>{dayjs(record?.end).format("hh A")}</div>
         },
      },
      {
         title: "Action",
         dataIndex: "state",
         key: "action",
         render: (state, record, index) => {
            if (state === BOOKSTATE.reserved)
               return (
                  <Button
                     onClick={() => handleReleaseReturn(record.id)}
                     type='link'
                  >
                     Release
                  </Button>
               )
            if (state === BOOKSTATE.released)
               return (
                  <Button
                     onClick={() => handleReleaseReturn(record.id)}
                     type='link'
                  >
                     Return
                  </Button>
               )
         },
      },
   ]

   const getBookReservation = async () => {
      const [bookReservationsArray, errorMsg] =
         await getBooksAndReservationsReleaseForToday()
      if (errorMsg) return
      setBookReservations(bookReservationsArray)
   }
   useEffect(() => {
      getBookReservation()
   }, [updateCount])

   useEffect(() => {
      if (isBookReservationDrawerOpen === false) getBookReservation()
   }, [isBookReservationDrawerOpen])

   useEffect(() => {
      if (isSelectCarModalOpen === false) getBookReservation()
   }, [isSelectCarModalOpen])

   return (
      <Card className='sd-sm' style={{ width: "100%" }}>
         <Title level={5}>Release/Return For Today </Title>
         <div></div>
         <Table
            isLoading={state?.isLoading}
            columns={columns}
            dataSource={bookReservations}
         />
      </Card>
   )
}
