import React, { useContext } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import UserDropDownMenuController from '../controllers/UserDropDownMenuController'
import { Button, Dropdown, Space } from 'antd'
import { GlobalContext } from '../../../context/GlobalContext'

const UserDropDownMenu = () => {
   const { user } = useContext(GlobalContext)

   let navigate = useNavigate()

   const { handleLogout } = UserDropDownMenuController({
      navigate,
   })

   const items = [
      {
         key: '1',
         label: (
            <div onClick={handleLogout}>
               <Button className='btn-link-black'>Logout</Button>
            </div>
         ),
      },
   ]

   return (
      <div>
         <Dropdown
            menu={{
               items,
            }}
         >
            <Space>
               <Button className='btn-link'>
                  {user?.firstName}&nbsp;{user?.lastName}
                  <DownOutlined />
               </Button>
            </Space>
         </Dropdown>
      </div>
   )
}

export default UserDropDownMenu
