import React, { useContext, useState, useEffect } from "react"

import {
   Button,
   Col,
   Divider,
   Form,
   Input,
   InputNumber,
   Modal,
   Row,
   Select,
} from "antd"

import AddUpdateCarController from "../controller/AddUpdateCarController"

import { CarContext } from "../../../context/CarContext"
import { ADD, UPDATE } from "../../../data/magicString"
import { GlobalContext } from "../../../context/GlobalContext"
import { branchesData, generateDayData } from "../../../data/data"
import { getRandomDarkColor } from "../../../lib/utils"

export default function AddUpdateCar() {
   const [form] = Form.useForm()

   const [state, setState] = useState({
      color: getRandomDarkColor(),
      carName: "",
   })

   const { isAddCarModalOpen, addOrUpdate, dispatch, car, carIndex } =
      useContext(CarContext)
   const {
      types,
      cars,
      dispatch: globalDispatch,
      selectedBranch,
   } = useContext(GlobalContext)

   const {
      onChangeColor,
      colorLabel,
      title,
      handleCancel,
      handleOk,
      onFieldsChange,
			getCars
   } = AddUpdateCarController({
      addOrUpdate,
      dispatch,
      globalDispatch,
      form,
      setState,
      state,
      cars,
			car,
			carIndex
   })

   useEffect(() => {
      if (addOrUpdate === UPDATE) {
         setState((prevState) => ({
            ...prevState,
            color: car.color,
						carName: car.name
         }))
        
         form.setFieldsValue(getCars())
      }
			if (addOrUpdate === ADD) {
			 
				form.setFieldsValue({"reservationFee": 500})
		 }

			

      return () => {
        // form.resetFields()
      }
      //eslint-disable-next-line
   }, [addOrUpdate, car])

   return (
      <Modal
         title={title()}
         open={isAddCarModalOpen}
         okText={addOrUpdate === UPDATE ? "Update" : "Add"}
         onCancel={handleCancel}
         width={"50vw"}
         footer={[
            <Button key='back' onClick={handleCancel}>
               Cancel
            </Button>,
            <Button form='form' htmlType='submit' key='submit' type='primary'>
               {addOrUpdate}
            </Button>,
         ]}
      >
         <Form
            id='form'
            name='basic'
            layout='vertical'
            form={form}
            style={{
               maxWidth: 800,
               marginTop: "1vw !important",
            }}
            initialValues={{
               reservationFee: 500,
               color: state?.color?.replace("#", ""),
               branchId: selectedBranch.id,
							 cutoff: 30
            }}
            onFinish={() => handleOk()}
            onFieldsChange={onFieldsChange}
            autoComplete='off'
         >
            <Row style={{ marginTop: "1vw" }}>
               <Col span={15} key='name'>
                  <Form.Item
                     label='Name'
                     name='name'
                     rules={[
                        {
                           required: true,
                           message: "Name is required!",
                        },
                     ]}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='Plate no.'
                     name='plate'
                     rules={[
                        {
                           required: true,
                           message: "Plate no. is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='Type'
                     name='type'
                     rules={[
                        {
                           required: true,
                           message: "Please select a type!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Select
                        style={{
                           width: "100%",
                        }}
                        options={types}
                     />
                  </Form.Item>
                  <Form.Item
                     label='Odometer'
                     name='odometer'
                     rules={[
                        {
                           required: true,
                           message: "Please select a car!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <InputNumber
                        style={{
                           width: "50%",
                        }}
                     />
                  </Form.Item>
{/* 									
                  <Form.Item
                     label='Owner'
                     name='owner'
                     rules={[
                        {
                           required: true,
                           message: "Please select a car!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Select options={owners} />
                  </Form.Item> */}
                  <Form.Item
                     label='Gas'
                     name='gas'
                     rules={[
                        {
                           required: true,
                           message: "Gas is required field!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <InputNumber />
                  </Form.Item>
									<Form.Item
                     label='Cutoff Day'
                     name='cutoff'
                    
                     style={{
                        width: "100%",
                     }}
                  >
                      <Select options={generateDayData()} />
                  </Form.Item>
                  <Form.Item
                     label={colorLabel()}
                     name='color'
                     rules={[
                        {
                           required: true,
                           message: "Color is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input
                        addonBefore='#'
                        onChange={onChangeColor}
                        placeholder='ffffff'
                        maxLength={6}
                        style={{ borderRadius: "0 10px 10px 0 !important" }}
                     />
                  </Form.Item>
                  <Form.Item
                     label='Branch'
                     name='branchId'
                     rules={[
                        {
                           required: true,
                           message: "Please select a car!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Select options={branchesData} />
                  </Form.Item>
               </Col>
               <Col span={1} key='divider1'>
                  <Divider type='vertical' style={{ height: "100%" }} />
               </Col>
               <Col span={6} key='branch'>
                  <Form.Item
                     label='5 hrs'
                     name='fiveHours'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='8 hrs'
                     name='eightHours'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='12 hrs'
                     name='twelveHours'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='24 hrs'
                     name='twentyFourHours'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='Exceeding hours'
                     name='exceedingHours'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
                  <Form.Item
                     label='Reservation fee'
                     name='reservationFee'
                     rules={[
                        {
                           required: true,
                           message: "This field is required!",
                        },
                     ]}
                     style={{
                        width: "100%",
                     }}
                  >
                     <Input />
                  </Form.Item>
               </Col>
            </Row>
         </Form>
      </Modal>
   )
}
