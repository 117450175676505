import React, { useContext, useEffect } from "react"
import {
   Button,
   Col,
   DatePicker,
   Divider,
   Form,
   Input,
   InputNumber,
   Modal,
   Row,
   Select,
} from "antd"
import { isEmpty } from "../../../lib/utils"
import AddCustomerController from "../controllers/AddCustomerController"
import dayjs from "dayjs"
import { CustomerContext } from "../../../context/CustomerContext"
import { UPDATE } from "../../../data/magicString"

const { TextArea } = Input;

const AddCustomer = ({
   customers,
   customerManagementState,
   customerManagementSetstate,
   customer,
   setCustomer,
}) => {
   const { isAddCustomerModalOpen, addOrUpdate } = customerManagementState

   const { dispatch } = useContext(CustomerContext)

   const [form] = Form.useForm()

   const { handleOk, handleCancel } = AddCustomerController({
      setCustomer,
      customer,
      customers,
      addOrUpdate,
      form,
      customerManagementSetstate,
      dispatch,
   })

   const title = () => {
      return `${addOrUpdate} customer`
   }

   useEffect(() => {
      if (!isEmpty(customer))
         form.setFieldsValue({
            ...customer,
            birthday: dayjs(customer.birthday),
         })

      //eslint-disable-next-line
   }, [addOrUpdate, customer])

   const onFinishFailed = (errorInfo) => {}

   return (
      <>
         <Modal
            title={title()}
            open={isAddCustomerModalOpen}
            okText={addOrUpdate === "Update" ? "Update" : "Add"}
            onCancel={handleCancel}
            width={"50vw"}
            footer={[
               <Button key='back' onClick={handleCancel}>
                  Cancel
               </Button>,
               <Button
                  form='customer-form'
                  htmlType='submit'
                  key='submit'
                  type='primary'
               >
                  {addOrUpdate}
               </Button>,
            ]}
         >
            <Form
               id='customer-form'
               layout='vertical'
               name='basic'
               style={{
                  marginTop: "20px",
               }}
               initialValues={{
                  remember: true,
               }}
               onFinish={() => handleOk()}
               onFinishFailed={onFinishFailed}
               autoComplete='off'
               form={form}
            >
               <Row gutter={[24, 24]}>
                  <Col span={10}>
                     <Form.Item
                        label='First name'
                        name='firstName'
                        rules={[
                           {
                              required: true,
                              message: "Please input first name!",
                           },
                        ]}
                     >
                        <Input style={{ width: "100%" }} />
                     </Form.Item>

                     <Form.Item label='Middle name' name='middleName'>
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Last name'
                        name='lastName'
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Address'
                        name='address'
                        rules={[
                           {
                              required: true,
                              message: "Please input address!",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Birthday'
                        name='birthday'
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                        ]}
                     >
                        <DatePicker style={{ width: "100%" }} />
                     </Form.Item>
                     <Form.Item
                        label='Gender'
                        name='gender'
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                        ]}
                     >
                        <Select
                           options={[
                              {
                                 value: "male",
                                 label: "Male",
                              },
                              {
                                 value: "female",
                                 label: "Female",
                              },
                           ]}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={2}>
                     <Divider type='vertical' style={{ height: "100%" }} />
                  </Col>
                  <Col span={10}>
                     <Form.Item
                        label='Facebook Account'
                        name='facebook'
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Email Address'
                        name='email'
                        rules={[
                           {
                              type: "email",
                              message: "The input is not valid E-mail!",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label='Contact no. 1'
                        name='contactNo1'
                        hasFeedback
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                           () => ({
                              validator(_, value) {
                                 if (
                                    isEmpty(value) ||
                                    (value.toString().length === 10 &&
                                       value > 8999999999)
                                 ) {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(
                                    new Error("Mobile number is not valid!")
                                 )
                              },
                           }),
                           ({ getFieldValue }) => ({
                              validator(_, value) {
                                 if (
                                    isEmpty(value) ||
                                    getFieldValue("contact_2") !== value
                                 ) {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(
                                    new Error(
                                       "Contact No. 1 can't be the same with contact no. 2!"
                                    )
                                 )
                              },
                           }),
                        ]}
                     >
                        <InputNumber
                           className='mobile-number'
                           addonBefore='+63'
                           style={{
                              width: "100%",
                           }}
                        />
                     </Form.Item>
                     <Form.Item
                        label='Contact no. 2'
                        name='contactNo2'
                        rules={[
                           {
                              required: true,
                              message: "Please input last name!",
                           },
                           () => ({
                              validator(_, value) {
                                 if (
                                    isEmpty(value) ||
                                    (value.toString().length === 10 &&
                                       value.toString().length === 10 &&
                                       value > 8999999999)
                                 ) {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(
                                    new Error("Mobile number is not valid!")
                                 )
                              },
                           }),
                           ({ getFieldValue }) => ({
                              validator(_, value) {
                                 if (
                                    isEmpty(value) ||
                                    getFieldValue("contact_1") !== value
                                 ) {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(
                                    new Error(
                                       "Contact No. 2 can't be the same with contact no. 1!"
                                    )
                                 )
                              },
                           }),
                        ]}
                     >
                        <InputNumber
                           className='mobile-number'
                           addonBefore='+63'
                           style={{ width: "100%" }}
                        />
                     </Form.Item>
                     <Form.Item label='Company/Agency' name='company'>
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label="Driver's license no."
                        name='driverLicenseNo'
                        // rules={[
                        //    {
                        //       required: true,
                        //       message: 'Please input driver license no.!',
                        //    },
                        // ]}
                     >
                        <Input />
                     </Form.Item>
                  </Col>
               </Row>
               <Divider />
               <Row>
                  <Col span={10}>
                     <Form.Item
                        label='Category'
                        name='category'
                        rules={[
                           {
                              required: true,
                              message: "Please select a category!",
                           },
                        ]}
                     >
                        <Select
                           options={[
                              {
                                 value: 1,
                                 label: "Ordinary",
                              },
                              {
                                 value: 2,
                                 label: "VIP",
                              },
                              {
                                 value: 3,
                                 label: "Banned",
                              },
                           ]}
                        />
                     </Form.Item>
                     {addOrUpdate === UPDATE && (
                        <Form.Item label='Note' name='offense'>
                           <TextArea  rows={8} />
                        </Form.Item>
                     )}
                  </Col>
                  <Col span={2}></Col>
                  <Col span={10}></Col>
               </Row>
            </Form>
         </Modal>
      </>
   )
}

export default AddCustomer
