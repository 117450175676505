import { message } from "antd"
import SERVER, { handleError } from "../../../lib/serverAxios"
import {
   ACTION_FULFILLED,
   ACTION_IN_PROGRESS,
   SWITCH_VEHICLE,
   TOGGLE_DRAWER,
   TOGGLE_SWITCH_CAR_DRAWER,
} from "../../../context/reducer/CalendarReducer"
import { isEmpty } from "../../../lib/utils"
import dayjs from "dayjs"
import {
   computeDurationFee,
   computeWithDriverFee,
} from "../../../lib/calculation"

const BookReservationDrawerController = ({
   form,
   id,
   dispatch,
   bookReservation,
}) => {
   const {
      car,
      start,
      dateReleased,
      ootd,
      payment,
      withDriver,
      customer,
   } = bookReservation


   const cancelledBookReservation = async () => {
      try {
         await SERVER.post(`/api/books-reservation/cancelled?id=${id}`)

         dispatch({ type: ACTION_FULFILLED })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: false })
         message.success("Successfully.", 2)
         window.location.reload()
      } catch (error) {
         handleError(error)
         dispatch({ type: ACTION_FULFILLED })
      }
   }

   const returnVehicle = async () => {
      try {
         await form.validateFields() // Validate the form fields before
         const formValues = await form.getFieldsValue()
         if (isEmpty(customer)) {
            const values = {
               ...formValues,
               id,
               dateReturn: !isEmpty(formValues?.dateReturn)
                  ? formValues?.dateReturn.format()
                  : dayjs().format(),
            }
            await SERVER.post(`/api/books-reservation/return-owner`, {
               ...values,
            })
         } else {
            const newRentalFee =
               computeDurationFee({
                  selectedCar: car,
                  start: dateReleased || start,
                  end: formValues?.dateReturn,
               }) || 0

            const ootdWithDriverFee = ootd?.withDriverFee || 0
            const newWithDriverFee =
               computeWithDriverFee({
                  withDriver: withDriver,
                  withDriverFee: ootdWithDriverFee,
                  start: dateReleased || start,
                  end: formValues?.dateReturn,
               }) || 0

            const values = {
               ...formValues,
               id,
               dateReturn: !isEmpty(formValues?.dateReturn)
                  ? formValues?.dateReturn.format()
                  : dayjs().format(),
               payment: {
                  rentalFee:
                     newRentalFee > payment?.rentalFee
                        ? newRentalFee
                        : payment?.rentalFee,
                  withDriverFee:
                     newWithDriverFee > payment?.withDriverFee
                        ? newWithDriverFee
                        : payment?.withDriverFee,
               },
            }
            await SERVER.post(`/api/books-reservation/return`, {
               ...values,
            })
         }

         dispatch({ type: ACTION_FULFILLED })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: false })

         message.success("Successfully.", 2)
         window.location.reload()
      } catch (error) {
         handleError(error)
         dispatch({ type: ACTION_FULFILLED })
      }
   }

   const updateBook = async () => {
		dispatch({ type: ACTION_IN_PROGRESS })

      try {
				await form.validateFields(['end', 'rentalFee', 'destinationFee', 'withDriverFee'])

         const formValues = await form.getFieldsValue()
        
         const values = {
            end: formValues.end,
            id,
            rentalFee: formValues?.rentalFee,
						withDriverFee:formValues?.withDriverFee,
						destinationFee: formValues?.destinationFee
         }
         await SERVER.post(`/api/books-reservation/update`, {
            ...values,
         })

         dispatch({ type: ACTION_FULFILLED })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: false })

         message.success("Successfully.", 2)
         window.location.reload()
      } catch (error) {
         handleError(error)
         dispatch({ type: ACTION_FULFILLED })
      }
   }

   const switchVehicle = async () => {
      try {
         await form.validateFields()
         const formValues = await form.getFieldsValue()
         const values = {
            ...formValues,
            id,
            dateReturn: !isEmpty(formValues?.dateReturn)
               ? formValues?.dateReturn.format()
               : dayjs().format(),
         }

         dispatch({ type: SWITCH_VEHICLE, oldBookReservationValues: values })
         dispatch({
            type: TOGGLE_SWITCH_CAR_DRAWER,
            isSwitchCarDrawerOpen: true,
         })
      } catch (error) {
         handleError(error)
      }
   }

   const releaseVehicle = async () => {
      dispatch({ type: ACTION_IN_PROGRESS })
      try {
         await form.validateFields()
         const formValues = await form.getFieldsValue()

         const values = {
            ...formValues,
            id,
            dateRelease: formValues.dateRelease.format(),
         }
         if (isEmpty(customer)) {
            await SERVER.post(`/api/books-reservation/release-owner`, {
               ...values,
            })
         } else {
            await SERVER.post(`/api/books-reservation/release`, {
               ...values,
            })
         }

         dispatch({ type: ACTION_FULFILLED })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: false })

         message.success("Successfully.", 2)
      } catch (error) {
         handleError(error)
         dispatch({ type: ACTION_FULFILLED })
      }
   }

   return {
      switchVehicle,
      releaseVehicle,
      returnVehicle,
      cancelledBookReservation,
      updateBook,
   }
}

export default BookReservationDrawerController
