import { Card, Col, Row, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { MonthlySalesReportContext } from "../../../context/MonthlySalesReportContext"
import { convertConvertToPHCurrency } from "../../../lib/utils"

const { Title } = Typography

const SummaryMonthlyInventory = () => {
   const { unitTotalGross, unitExpense } = useContext(MonthlySalesReportContext)

   const [expenses, setExpenses] = useState({
      totalCarWash: 0,
      totalExpenses: 0,
   })

   useEffect(() => {
      const totalCarWash = unitExpense?.reduce((total, expense) => {
         return total + parseFloat(expense?.carWash)
      }, 0)

      const totalExpenses = unitExpense?.reduce((total, expense, index) => {
         return total + parseFloat(expense?.amount)
      }, 0)

      setExpenses((prevState) => ({
         ...prevState,
         totalCarWash,
         totalExpenses : totalExpenses / 2,
      }))
   }, [unitExpense])


   const computeCompanyCommision = () => {
      const total = unitTotalGross * 0.3 || 0
      if (isNaN(total)) return -1
      return total
   }

   const computeCompanyNetIncome = () => {
      const total = parseInt(expenses?.totalCarWash) + parseInt(computeCompanyCommision())
      if (isNaN(total)) return -1
      return total
   }

   const unitOwnerNetIncome = unitTotalGross - computeCompanyNetIncome() - expenses?.totalExpenses

   return (
      <div style={{ pageBreakBefore: "auto" }}>
         <Title level={3} className='nti title-report' type='secondary'>
            Summary
         </Title>
         <Card className='sd-sm'>
            <Row
               style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px  solid black",
                  borderRight: "1px  solid black",
               }}
            >
               <Col
                  span={12}
                  className='text-align-center'
                  style={style.colLeft}
               >
                  <b> Unit Total Gross</b>
               </Col>
               <Col span={12} className='text-align-right' style={style.paddingRight}>
                  <b> {convertConvertToPHCurrency(unitTotalGross || 0)} </b>
               </Col>
            </Row>
            <Row>
               <Col
                  span={24}
                  style={{
                     textAlign: "center",
                     backgroundColor: "#590900",
                     color: "#ffffff",
                  }}
               >
                  <b> DEDUCTION </b>
               </Col>
            </Row>
            <Row style={{ border: "1px solid black" }}>
               <Col
                  span={12}
                  className='text-align-center'
                  style={style.colLeft}
               >
                  <b> Company Commision (30%) </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-right'
                  style={style.colRight}
               >
                  <b>
                     {" "}
                     {convertConvertToPHCurrency(
                        computeCompanyCommision()
                     )}{" "}
                  </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-center'
                  style={style.colLeft}
               >
                  <b> Expenses </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-right'
                  style={style.colRight}
               >
                  <b> {convertConvertToPHCurrency(expenses?.totalExpenses || 0)} </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-center'
                  style={style.colLeft}
               >
                  <b> Carwash </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-right'
                  style={style.colRight}
               >
                  <b> {convertConvertToPHCurrency(expenses?.totalCarWash || 0)} </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-center'
                  style={style.colLeft}
               >
                  <b> Company's Net Income (Commission & Carwash) </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-right'
                  style={style.colRight}
               >
                  <b>
                     {" "}
                     {convertConvertToPHCurrency(
                        computeCompanyNetIncome()
                     )}{" "}
                  </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-center'
                  style={{
                     borderRight: "1px solid black",
                     backgroundColor: "#606C38",
                     fontWeight: "bold",
                     fontStyle: "italic",
                     color: "#ffffff",
                     fontSize: "1.1em",
                  }}
               >
                  <b> Unit Owner Net Income </b>
               </Col>
               <Col
                  span={12}
                  className='text-align-right'
                  style={{
                     backgroundColor: "#606C38",
                     color: "#ffffff",
                     fontWeight: "bold",
                     fontStyle: "italic",
                     fontSize: "1.1em",
										 paddingRight: "5px"
                  }}
               >
                  <b> {convertConvertToPHCurrency(unitOwnerNetIncome)} </b>
               </Col>
            </Row>
         </Card>
      </div>
   )
}

export default SummaryMonthlyInventory

const style = {
   colLeft: {
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
   },
   colRight: {
      borderBottom: "1px solid black",
			paddingRight: "5px"
   },
	 paddingRight: {
		paddingRight: "5px"
	 }
}
