import React, { useEffect } from "react"

import { Card, Layout } from "antd"
//ROUTER
import { Navigate, useNavigate } from "react-router-dom"
//Components
import HeaderContent from "../components/HeaderContent"
import AdminMenuController from "../../../modules/menu/controllers/AdminMenuController"

//Third party packages
const { Content } = Layout

function PrivateRoute(props) {
   const isLoggedIn = localStorage.getItem("isLogin") || false
   const Component = props.component
   const Menu = props?.menu
   const { hasHeader = true } = props
   const navigate = useNavigate()
   const setActiveMenu = () => null
   const { handleMenu } = AdminMenuController({ navigate, setActiveMenu })

   useEffect(() => {
      if (hasHeader && isLoggedIn) {
         const route = localStorage?.getItem("activeMenu")
         handleMenu(route)
      }
      //eslint-disable-next-line
   }, [])

   const layout = (props) => (
      <>
         <Layout>
            {hasHeader && <HeaderContent Menu={Menu} />}
            <Layout
               style={{
                  background: "#ffff6",
                  display: "flex",
               }}
            >
               <Content
                  className='public-content'
                  style={{
                     float: "left",
                     background: "#ffff6",
                     marginTop: "5vw",
									marginBottom: "5vw",
                     justifyContent: "left",
                     textAlign: "left",
                     padding: "0 50px",
                     height: "100vh",
                  }}
               >
                  <Component {...props} />
									<Card style={{visibility:"hidden"}}> Yeah</Card>
               </Content>
            </Layout>
         </Layout>
      </>
   )
   return (
      <>
         {!isLoggedIn ? (
            <Navigate to='/login' replace={true} />
         ) : (
            <> {layout(props)} </>
         )}
      </>
   )
}

export default PrivateRoute
