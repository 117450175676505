
const UserDropDownMenuController = ({ navigate }) => {

    const handleLogout =  () => {
        localStorage.removeItem("isLogin")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        localStorage.removeItem("customers")
        localStorage.removeItem("cars")
        localStorage.removeItem("activeMenu")


				
        navigate("/login")
    }

   

    return { handleLogout }
}

export default UserDropDownMenuController
