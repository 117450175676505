import dayjs from "dayjs"
import { BOOKSTATE } from "../../../data/enum"
import { isEmpty } from "../../../lib/utils"

export const isRelease = ({ state, start, end }) => {
   const dayPassed = dayjs().diff(dayjs(start), "day")
  // const isEndDateNotPassed = dayjs().diff(dayjs(end), "day") < 1  // can still release 
   const isForRelease =
      state === BOOKSTATE.reserved && dayPassed > -1 

   return isForRelease
}

// export const withDriver = ({ payment, withDriverFee = 0 }) => {
//    return !isEmpty(payment?.withDriverFee)
//       ? parseInt(payment?.withDriverFee || 0) - parseInt(withDriverFee || 0) > 0
//          ? true
//          : false
//       : false
// }
