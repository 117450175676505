import './App.css'
import './Ant.css'
import './FullCalendar.css'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'

//Components
import PublicRoute from './routes/public/view/PublicRoute'
import PrivateRoute from './routes/private/view/PrivateRoute'
import Login from './modules/login/view/Login'
import AdminMenu from './modules/menu/components/AdminMenu'
import CustomerManagement from './modules/CustomerManagement/view/CustomerManagement'
import Dashboard from './modules/dashboard/view/Dashboard'
import OOTD from './modules/out_of_town_destination/view/OOTD'
import Calendar from './modules/calendar/view/Calendar'
import DailySalesReport from './modules/daily_sales_report/view/DailySalesReport'
import CustomerInvoice from './modules/CustomerManagement/components/CustomerInvoice'
import Cars from './modules/cars/view/Cars'


import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CarLiveStatus from './modules/live_status/view/CarLiveStatus'
import MonthlySalesReport from './modules/monthly_sales_report/view/MonthlySalesReport'


dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault("Manila/Philippines")

function App() {
  
   return (
      <div className='App'>
         <BrowserRouter>
            <Routes>
               <Route
                  path='/login'
                  element={<PublicRoute component={Login} />}
               />

               <Route
                  path='/dashboard'
                  element={
                     <PrivateRoute component={Dashboard} menu={AdminMenu} />
                  }
               />
               <Route
                  path='/'
                  element={
                     <PrivateRoute component={Dashboard} menu={AdminMenu} />
                  }
               />
               <Route
                  path='/out-of-town-destination'
                  element={
                     <PrivateRoute
                        component={OOTD}
                        menu={AdminMenu}
                     />
                  }
               />
               <Route
                  path='/customer-management'
                  element={
                     <PrivateRoute
                        component={CustomerManagement}
                        menu={AdminMenu}
                     />
                  }
               />
               <Route
                  path='/calendar'
                  element={
                     <PrivateRoute component={Calendar} menu={AdminMenu} />
                  }
               />
               <Route
                  path='/daily'
                  element={
                     <PrivateRoute
                        component={DailySalesReport}
                        menu={AdminMenu}
                     />
                  }
               />
               <Route
                  path='/cars'
                  element={<PrivateRoute component={Cars} menu={AdminMenu} />}
               />
							  <Route
                  path='/live'
                  element={<PrivateRoute component={CarLiveStatus} menu={AdminMenu} />}
               />
               <Route
                  path='/monthly-sales-report'
                  element={
                     <PrivateRoute
                        component={MonthlySalesReport}
                        menu={AdminMenu}
                     />
                  }
               />
               <Route
                  path='/customer-invoice'
                  element={
                     <PrivateRoute
                        component={CustomerInvoice}
                        hasHeader={false}
                     />
                  }
               />

               <Route path='*' element={<Navigate to='/' />} />
            </Routes>
         </BrowserRouter>
      </div>
   )
}

export default App
