import { Image, Upload } from "antd"
import React from "react"
import { useContext } from "react"
import { CustomerContext } from "../../../context/CustomerContext"
import { baseURL } from "../../../lib/serverAxios"

const noImageUrl = `/images/no-image.jpeg`

export default function IdImage({ id, handleChange, beforeUpload, src }) {
	
	const { customerID } = useContext(CustomerContext)

	const uri = `${baseURL}/api/customer/upload-image`

	return (
		<Upload
			name="id"
			listType='picture-card'
			className='avatar-uploader'
			showUploadList={false}
			action={uri}
			beforeUpload={beforeUpload}
			onChange={(info) => handleChange({ info, id: id })}
			multiple={true}
			data={{ id: customerID, fieldname: id }}
		>
			<Image preview={false} width={200} src={src || "/images/dsdsdsadsdsa"} fallback={noImageUrl} />
		</Upload>
	)

}
