import { handleError } from "../../../lib/serverAxios"
import {
   SET_LOADING,
   SET_UNIT_EXPENSES,
} from "../../../context/reducer/CarReducer"
import { getUnitExpense } from "../../../lib/services"

const CarMonthlyUnitExpensesDrawerController = ({
   car,
   dispatch,
   selectedDate,
}) => {
	const title = () => {
		return car?.name
	}
   const initializeData = async () => {
      dispatch({ type: SET_LOADING, isLoading: true })

      try {
         const unitExpenses = await getUnitExpense({ selectedDate, car })
         dispatch({ type: SET_UNIT_EXPENSES, unitExpenses: unitExpenses })
      } catch (error) {
         dispatch({ type: SET_LOADING, isLoading: false })
         handleError(error)
      }
   }
   return { title, initializeData }
}

export default CarMonthlyUnitExpensesDrawerController
