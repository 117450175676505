import { Col, Row } from "antd"
import React, { useState } from "react"
import "./style.css"
import UploadAndDisplayImageController from "../controllers/UploadAndDisplayImageController"
import { ID } from "../../../data/enum"
import IdImage from "./IdImage"
import { isEmpty } from "../../../lib/utils"

export default function UploadAndDisplayImage({
   customerID,
   setImageUrl,
   imageUrl,
}) {
   const [loading, setLoading] = useState(false)

   const { handleChange, beforeUpload } =
      UploadAndDisplayImageController({ loading, setLoading, setImageUrl })

   return (
      <div
         style={{
            width: "100%",
         }}
      >
         <Row gutter={[24, 24]}>
            <Col span={5}>&nbsp;</Col>
            <Col span={14}>
               {!isEmpty(imageUrl?.selfie) && (
                  <IdImage
                     handleChange={handleChange}
                     beforeUpload={beforeUpload}
                     imageUrl={imageUrl}
                     id={ID.selfie}
                     src={imageUrl?.selfie}
                  />
               )}
            </Col>
            <Col span={5}>&nbsp;</Col>
         </Row>
         <Row gutter={[24, 24]} style={{ marginTop: "5vw" }}>
            <Col span={12}>
               {!isEmpty(imageUrl?.firstFront) && (
                  <IdImage
                     handleChange={handleChange}
                     beforeUpload={beforeUpload}
                     imageUrl={imageUrl}
                     id={ID.firstFront}
                     src={imageUrl?.firstFront}
                  />
               )}
            </Col>
            <Col span={12}>
               {!isEmpty(imageUrl?.firstFront) && (
                  <IdImage
                     handleChange={handleChange}
                     beforeUpload={beforeUpload}
                     imageUrl={imageUrl}
                     id={ID.firstBack}
                     src={imageUrl?.firstBack}
                  />
               )}
            </Col>
            <Col span={12}>
               {!isEmpty(imageUrl?.firstFront) && (
                  <IdImage
                     handleChange={handleChange}
                     beforeUpload={beforeUpload}
                     imageUrl={imageUrl}
                     id={ID.secondFront}
                     src={imageUrl?.secondFront}
                  />
               )}
            </Col>
            <Col span={12}>
               {!isEmpty(imageUrl?.firstFront) && (
                  <IdImage
                     handleChange={handleChange}
                     beforeUpload={beforeUpload}
                     imageUrl={imageUrl}
                     id={ID.secondBack}
                     src={imageUrl?.secondBack}
                  />
               )}
            </Col>
         </Row>
      </div>
   )
}
