import dayjs from "dayjs"
import { BOOKSTATE } from "../../../data/enum"

const EventController = ({ bookReservation, state }) => {
   const timePassed = () => {
      if (dayjs().diff(bookReservation?.end, "minute") > 1440) {
         // DAYS
         const days =
            dayjs().diff(bookReservation?.end, "day") > 1 ? " days" : " day"
         return dayjs().diff(bookReservation?.end, "day") + days
      }

      if (dayjs().diff(bookReservation?.end, "minute") > 59) {
         // HRS
         const hrs =
            dayjs().diff(bookReservation?.end, "hour") > 1 ? " hrs" : " hr"
         return dayjs().diff(bookReservation?.end, "hour") + hrs
      }

      if (dayjs().diff(bookReservation?.end, "minute") < 60) {
         // MINUTES
         const minutes =
            dayjs().diff(bookReservation?.end, "minute") > 1
               ? " minutes"
               : " minute"
         return dayjs().diff(bookReservation?.end, "minute") + minutes
      }

      return "Invalid Date"
   }

   const isReservationCancelled = state === BOOKSTATE.cancelled
   const isBookDone = state === BOOKSTATE.done || state === BOOKSTATE.switched
   const isMinutePast = dayjs().diff(bookReservation?.end, "minute") > 1

   return { timePassed, isMinutePast, isBookDone, isReservationCancelled }
}

export default EventController
