import { branchesData } from "../data/data"
import { isEmpty } from "./utils"

export const getBranchName = (branchId) => {
   if (isEmpty(branchId)) return
   const branch = branchesData.find((branch) => branch.value === branchId)
   return branch.label
}

export const getStartAndEndDate = ({ selectedDate, cutoff }) => {
   let startDate
   let endDate

   const currentMonth = selectedDate.month() + 1
   const lastDateOfMonth = selectedDate.endOf("month").date()
   const isEndCutOffEndofTheMonth = cutoff >= lastDateOfMonth || cutoff === 30
   if (isEndCutOffEndofTheMonth) {
      let day = cutoff
      if (currentMonth === 2 || cutoff === 30) {
         day = selectedDate.endOf("month").date()
      }
      startDate = selectedDate.set("date", 1)
      endDate = selectedDate.date(day)
   }
   if (!isEndCutOffEndofTheMonth) {
      startDate = selectedDate.date(cutoff).subtract(1, "month").add(1, "day")
      endDate = selectedDate.date(cutoff)
   }
   return [startDate, endDate]
}
