import React from 'react'
import { Checkbox, Col, Form } from 'antd'

export default function ReturnChecklist() {
   return (
      <>
         <Col span={4}>
            <span className='nti'>Return </span>
            <Form.Item style={{marginBottom: '0px'}}valuePropName='checked' name='orcr'>
               <Checkbox className='checked-list'  >
                  OR/CR
               </Checkbox>
            </Form.Item>
            <Form.Item  className='checked-list' valuePropName='checked' name='tools'>
               <Checkbox  className='checked-list' >Tools</Checkbox>
            </Form.Item>
            <Form.Item  className='checked-list' valuePropName='checked' name='stinkyOdor'>
               <Checkbox  className='checked-list' >Stinky Odor</Checkbox>
            </Form.Item>
         </Col>
         <Col span={4}>
            &nbsp;
            <Form.Item style={{marginBottom: '0px'}} valuePropName='checked' name='spareTire'>
               <Checkbox  className='checked-list' >Spare-Tire</Checkbox>
            </Form.Item>
            <Form.Item  style={{marginBottom: '0px'}} valuePropName='checked' name='jack'>
               <Checkbox  className='checked-list' >Jack</Checkbox>
            </Form.Item>
            <Form.Item valuePropName='checked' name='dirtyStained'>
               <Checkbox  className='checked-list' >Dirty Stained</Checkbox>
            </Form.Item>
         </Col>
      </>
   )
}


