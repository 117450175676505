import axios from "axios"
import { ISEmsg } from "../data/enum"
import { message } from "antd"
import { isEmpty } from "./utils"

export const baseURL =
   process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:1991"
export const enviroment = process.env.REACT_APP_ENV || "localhost"
const SERVER = axios.create({
   baseURL: baseURL,
})

const getAccessToken = () => {
   return localStorage.getItem("token")
}

// export const handleError = (error) => {
// 	const { response } = error

// 	if (error && error.response && error.response.data) {
// 		if (!response) {
// 			return { success: false, errorType: 4, msg: "Server error, most probably no internet connection establish" } //errorType 4 => front end error
// 		}
// 		const statusUnauthorized = response.status === 401 || response.status === 400
// 		if (statusUnauthorized) {
// 			localStorage.removeItem('token')
// 			localStorage.removeItem('isLogin')
// 			return {
// 				success: false,
// 				errorType: 3,
// 				msg: response.data.msg,
// 			}
// 		}
// 		const internalServerError = response.status === 500
// 		if (internalServerError) {
// 			return {
// 				success: false,
// 				errorType: 3,
// 				msg: response.data.msg,
// 			}
// 		}
//   } else return error.message || ISEmsg

// }

export const handleError = (error) => {
   const { response } = error

   if (error && error.response && error.response.data) {
      if (!response) {
         return message.error(
            "Server error, most probably no internet connection establish"
         )
      }
      const statusUnauthorized =
         response.status === 401 || response.status === 403
      if (statusUnauthorized) {
         localStorage.removeItem("token")
         localStorage.removeItem("isLogin")
         return message.error(response.data.msg)
      }
      const internalServerError = response.status === 500
      if (internalServerError) {
         return message.error(response.data.msg)
      }
			if(response.status === 400) {
				return message.error(response.data.msg)
			}
   } else if (error.message === "canceled") {
      return
   } else if (!isEmpty(error.errorFields)) {
      error.errorFields.forEach((err) => {
         message.error(err.errors?.[0], 3)
      })
   } else return message.error(error.message || ISEmsg)
}

SERVER.interceptors.request.use(
   function (config) {
      const token = getAccessToken()
      const bearer = `Bearer ${token}`

      config.headers.Authorization = bearer
      return config
   },
   function (error) {
      return Promise.reject(error)
   }
)

export default SERVER
