import React, { useContext } from "react"
import Table from "../../../common/components/Table"
import { Card, Typography } from "antd"
import { convertConvertToPHCurrency, isEmpty, toUpperCaseFirstLetterWord } from "../../../lib/utils"
import { MonthlySalesReportContext } from "../../../context/MonthlySalesReportContext"
import dayjs from "dayjs"
const { Title } = Typography

const MonthlyUnitExpenses = () => {
   const { unitExpense } = useContext(MonthlySalesReportContext)
   console.log("🚀 ~ MonthlyUnitExpenses ~ unitExpense:", unitExpense)

   const columns = [
      {
         title: "Date",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (createdAt) => {
            if (isEmpty(createdAt)) return ""
            return <span> {dayjs(createdAt)?.format("MM/DD/YYYY")} </span>
         },
      },
      {
         title: "Description",
         key: "description",
         dataIndex: "description",
         render: (description) => {
            if (description === "***hidden***") return ""
            return <span> {toUpperCaseFirstLetterWord(description)} </span>
         },
      },
      {
         title: "Maintenance",
         key: "maintenance",
         dataIndex: "maintenance",
         align: "right",
         render: (maintenance, _, index) => {
            const footerTotal = unitExpense.length - 1 === index
            if (isEmpty(maintenance)) return ""
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(parseFloat(maintenance) || 0)}
                  </span>
               )
            } else {
               return (
                  <span>
                     {convertConvertToPHCurrency(parseFloat(maintenance) || 0)}{" "}
                  </span>
               )
            }
         },
      },
      {
         title: "Pickup / Deliver",
         dataIndex: "pickupOrDelivery",
         key: "pickupOrDelivery",
         align: "right",
         render: (pickupOrDelivery, _, index) => {
            const footerTotal = unitExpense?.length - 1 === index
            if (isEmpty(pickupOrDelivery)) return ""
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(
                        parseFloat(pickupOrDelivery) || 0
                     )}
                  </span>
               )
            } else {
               return (
                  <span>
                     {convertConvertToPHCurrency(
                        parseFloat(pickupOrDelivery) || 0
                     )}{" "}
                  </span>
               )
            }
         },
      },
      {
         title: "Referral",
         dataIndex: "referral",
         key: "referral",
         align: "right",
         render: (referral, _, index) => {
            const footerTotal = unitExpense?.length - 1 === index
            if (isEmpty(referral)) return ""
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(parseFloat(referral) || 0)}
                  </span>
               )
            } else {
               return (
                  <span>
                     {convertConvertToPHCurrency(parseFloat(referral) || 0)}{" "}
                  </span>
               )
            }
         },
      },

      {
         title: "Amount",
         dataIndex: "amount",
         key: "amount",
         align: "right",
         render: (amount, _, index) => {
            const footerTotal = unitExpense?.length - 1 === index
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(parseFloat(amount) || 0)}
                  </span>
               )
            } else {
               return (
                  <span>
                     {convertConvertToPHCurrency(parseFloat(amount) || 0)}{" "}
                  </span>
               )
            }
         },
      },
   ]

   return (
      <div>
         <Title level={3} className='nti title-report' type='secondary'>
            Monthly Unit Expense
         </Title>
         <Card className='sd-sm' style={{ marginTop: "1vw" }}>
            <Table
               dataSource={unitExpense.filter(
                  (expense) => !isEmpty(expense.description)
               )}
               columns={columns}
            />
         </Card>
      </div>
   )
}

export default MonthlyUnitExpenses
