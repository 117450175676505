import { Card } from "antd"
import { Line } from "react-chartjs-2"
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   PointElement,
   LineElement,
   Filler,
} from "chart.js"

import React, { Fragment } from "react"
import { convertoMonthString } from "../data/dashboardData"

const IncomesChart = ({ dataIncomes, payments }) => {
   const monthsIncomes = dataIncomes?.map(
      (item) => convertoMonthString[item.month]
   )
   const monthsPayments = payments?.map(
      (item) => convertoMonthString[item.month]
   )

   const totalPaidAmounts = dataIncomes.map((item) => item.totalPaidAmount)
   const totalReservationFee = payments.map((item) => item.totalReservationFee)
   const totalRentalFee = payments.map((item) => item.totalRentalFee)
   const withDriverFee = payments.map((item) => item.withDriverFee)
   const destinationFee = payments.map((item) => item.destinationFee)
   const damageCost = payments.map((item) => item.damageCost)
   const discount = payments.map((item) => item.discount)

   const chartDataPayment = {
      type: "line",
      title: "PAYMENTS",
      labels: monthsPayments,
      background_color: ["#606C38"],
      datasets: [
         {
            label: "Reservation fee",
            data: totalReservationFee,
            borderColor: "#590900",
            backgroundColor: "#590900",
         },
         {
            label: "Rental fee",
            data: totalRentalFee,
            borderColor: "#DDA15E",
            backgroundColor: "#DDA15E",
         },
         {
            label: "With driver fee",
            data: withDriverFee,
            borderColor: randomRGB(),
            backgroundColor: randomRGB(),
         },
         {
            label: "Destination fee",
            data: destinationFee,
            borderColor: randomRGB(),
            backgroundColor: randomRGB(),
         },
         {
            label: "Damage cost",
            data: damageCost,
            borderColor: "red",
            backgroundColor: "red",
         },
         {
            label: "Adjustment",
            data: discount,
            borderColor: randomRGB(),
            backgroundColor: randomRGB(),
         },
      ],
   }

   const chartDataIncome = {
      type: "line",
      title: "TOTAL AMOUNT",
      labels: monthsIncomes,
      background_color: ["#606C38"],
      datasets: [
         {
            label: "Paid",
            data: totalPaidAmounts,
            borderColor: "#606C38",
            backgroundColor: "#606C38",
         },
      ],
   }

   function randomRGB() {
      var x = Math.floor(Math.random() * 256)
      var y = Math.floor(Math.random() * 256)
      var z = Math.floor(Math.random() * 256)
      var RGBColor = "rgb(" + x + "," + y + "," + z + 0.2 + ")"
      return RGBColor
   }

   ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
      PointElement,
      LineElement,
      Filler
   )

   const optionsPayment = {
      responsive: true,
      plugins: {
         legend: {
            position: "top",
         },
         title: {
            display: true,
            text: chartDataPayment.title,
         },
      },
   }

   const dataPayment = {
      labels: chartDataPayment.labels,
      datasets: [...chartDataPayment?.datasets],
   }

   const optionsIncome = {
      responsive: true,
      plugins: {
         legend: {
            position: "top",
         },
         title: {
            display: true,
            text: chartDataIncome.title,
         },
      },
   }

   const dataIncome = {
      labels: chartDataIncome.labels,
      datasets: [...chartDataIncome?.datasets],
   }

   return (
      <Fragment>
         <Card className='sd-sm'>
            <Line options={optionsIncome} data={dataIncome} />
         </Card>

         <Card className='sd-sm' style={{ marginTop: "1vw" }}>
            <Line options={optionsPayment} data={dataPayment} />
         </Card>
      </Fragment>
   )
}

export default IncomesChart
