import React, { useContext, useState, useRef } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Col, Divider, Row, Select, Space, message } from "antd"
import CarStatusTag from "../../../common/components/CarStatusTag"
import { branchObject, carStatusWAllData } from "../../../data/data"
import CarLiveStatusFloatBtn from "./CarLiveStatusFloatBtn"
import { getCars } from "../../../lib/services"
import { handleError } from "../../../lib/serverAxios"
import { SET_CARS, SET_LOADING } from "../../../context/reducer/GlobalReducer"

export default function CarLiveStatusPage() {
   const { cars, types, dispatch } = useContext(GlobalContext)
   const [status, setStatus] = useState("all")
   const [isLoading, setLoading] = useState(false)


   const controllerRef = useRef(null)

   const handleChange = (value, car) => {
      setStatus(value)
   }
   const handleRefreshData = async () => {
		setLoading(true)
	
      if (controllerRef.current) {
         controllerRef.current.abort()
      }

      const controller = new AbortController()
      controllerRef.current = controller

      try {
         const data = await getCars(controller.signal)

         dispatch({
            type: SET_CARS,
            cars: data.cars,
         })
				 setLoading(false)
     
      } catch (error) {
         handleError(error)
				 setLoading(false)
      }
   }
   return (
      <div>
         <Space>
            <span className='nti'> Status:</span>
            <Select
               defaultValue='all'
               style={{ width: "10vw" }}
               onChange={handleChange}
               options={carStatusWAllData}
               showSearch
            />
         </Space>

         <Divider />
         <div
            style={{
               overflowX: "scroll",
               whiteSpace: "nowrap",
               minHeight: "80vh",
            }}
         >
            {types.map((type) => {
               return (
                  <div
                     style={{
                        display: "inline-block",
                        width: "300px",
                        marginRight: "10px",
                     }}
                  >
                     <h2> {type.label.toUpperCase()}</h2>
                     <Row gutter={[0, 8]}>
                        {cars
                           .filter(
                              (car) =>
                                 car.type === type.value &&
                                 (status === car.status || status === "all")
                           )
                           .map((car) => {
                              return (
                                 <Col span={12}>
                                    <div
                                       style={{
                                          border: "1px solid #ccc",
                                          borderRadius: "5px",
                                          padding: "10px",
                                          position: "relative",
                                       }}
                                    >
                                       <div
                                          style={{
                                             color: "white",
                                             backgroundColor: car.color,
                                             textAlign: "center",
                                             position: "absolute",
                                             top: 0,
                                             left: 0,
                                             right: 0,
                                             padding: "5px 0",
                                             borderRadius: "5px 5px 0 0",
                                          }}
                                       >
                                          {car.name.toUpperCase()}
                                       </div>
                                       <p
                                          style={{
                                             marginTop: "30px",
                                             textAlign: "center",
                                          }}
                                       >
                                          <CarStatusTag
                                             fontSize='0.7em'
                                             status={car.status}
                                          />
                                          <span style={{ fontSize: "0.7em" }}>
                                             {" "}
                                             {car.status === "available" ? (
                                                branchObject[car?.branchId]
                                             ) : (
                                                <>&nbsp;</>
                                             )}{" "}
                                          </span>
                                       </p>
                                    </div>
                                 </Col>
                              )
                           })}
                     </Row>
                  </div>
               )
            })}
         </div>
         <CarLiveStatusFloatBtn isLoading={isLoading} handleRefreshData={handleRefreshData} />
      </div>
   )
}
