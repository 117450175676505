import React, { useContext, useEffect } from "react"

import { DatePicker, Drawer, Space } from "antd"

import dayjs from "dayjs"
import { CarContext } from "../../../context/CarContext"
import {
   CLOSE_UNIT_MONTLY_EXPENSES_DRAWER,
   SET_DATE,
   SET_LOADING,
   SET_UNIT_EXPENSES,
} from "../../../context/reducer/CarReducer"
import CarMonthlyUnitExpensesDrawerController from "../controller/CarMonthlyUnitExpensesDrawerController"
import UnitExpensesForm from "./UnitExpensesForm"
import { handleError } from "../../../lib/serverAxios"
import { getUnitExpense } from "../../../lib/services"
import CarExpensesTable from "./CarExpensesTable"

export default function CarMonthlyUnitExpensesDrawer() {
   const { isCarMonthlyUnitExpensesDrawerOpen, dispatch, car, selectedDate } =
      useContext(CarContext)

   const onClose = () => {
      dispatch({ type: CLOSE_UNIT_MONTLY_EXPENSES_DRAWER })
   }

   const { initializeData, title } = CarMonthlyUnitExpensesDrawerController({
      car,
      selectedDate,
      dispatch,
   })

   useEffect(() => {
      initializeData()

      return () => {}
      //eslint-disable-next-line
   }, [])

   const disabledTo = (current) => {
      return current > dayjs().add(1, "day").endOf("day")
   }

   const handleChangeDate = async (value, dateString) => {
      dispatch({ type: SET_LOADING, isLoading: true })

      try {
         dispatch({ type: SET_DATE, selectedDate: value })
         const unitExpenses = await getUnitExpense({ selectedDate: value, car })
         dispatch({ type: SET_UNIT_EXPENSES, unitExpenses })
      } catch (error) {
         dispatch({ type: SET_LOADING, isLoading: false })
         handleError(error)
      }
   }
   return (
      <Drawer
         width={"65vw"}
         title={title()}
         onClose={onClose}
         open={isCarMonthlyUnitExpensesDrawerOpen}
         extra={
            <Space>
               <span className='nti'> Date:</span>
               <DatePicker
                  style={{ width: "10vw" }}
                  onChange={handleChangeDate}
                  format='MMMM'
                  disabledDate={disabledTo}
                  picker='month'
                  value={selectedDate}
               />
            </Space>
         }
      >
         <UnitExpensesForm />
         <CarExpensesTable />
      </Drawer>
   )
}
