import dayjs from "dayjs"

export const rowkey = (record) => {
   return record?.UUID || record?.id
}

export const checkIfNoReservationFee = (selectedDateStr) => {
   const today = dayjs()

   let noReservationFee = false
   if (
      dayjs(selectedDateStr).isSame(today, "day") ||
      dayjs(selectedDateStr).isBefore(today, "day")
   ) {
		noReservationFee = true
   } else {
		noReservationFee = false
   }
   return noReservationFee
}

export const safeDestructuringOfObject = (objectName, properties) => {
   const result = {}
   properties.forEach(({ property, defaultValue }) => {
      result[property] =
         objectName && objectName[property] !== undefined
            ? objectName[property]
            : defaultValue
   })
   return result
}

export const isEmpty = (value) => {
   return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value?.trim().length === 0)
   )
}

export const totalPageNum = (total = 0, per_page = 50) => {
   return Math.ceil(total / per_page)
}

export const toUpperCaseFirstLetterWord = (sentence = "") => {
   if (isEmpty(sentence)) return ""
   const words = sentence?.split(" ")

   return words
      ?.map((word) => {
         return word?.[0]?.toUpperCase() + word?.substring(1)
      })
      .join(" ")
}

export const convertToDay = (numberOfHours = 0) => {
   const Days = Math.floor(numberOfHours / 24)
   return Days
}

export const getRandomNumber = (limit) => {
   return Math.floor(Math.random() * limit)
}

export const totalHours = ({ start, end }) => {
   const isDateValid = dayjs(end).isValid() && dayjs(start).isValid()
   if (!isDateValid) return 0
   let hrs = 0
   hrs = dayjs(end)?.diff(start, "hours")
   return hrs
}

export const computeDurationForReport = ({ start, end }) => {
   let hrs = 0
   let days = 0
   hrs = totalHours({ start, end })
   days = dayjs(end)?.diff(start, "days")
   const leftHrs = hrs - days * 24
   const dayString =
      leftHrs > 0 ? `${days} days & ${leftHrs} hrs` : `${days} days`
   const hrString = `${hrs} hrs `
   const duration = hrs < 24 ? hrString : dayString

   return duration
}

export const totalHoursDays = ({ start, end }) => {
   let hrs = 0
   let days = 0
   hrs = totalHours({ start, end })
   days = dayjs(end)?.diff(start, "days")
   const dayString = `${days} days (${hrs} hrs)`
   const hrString = `${hrs} hrs `
   const duration = hrs < 24 ? hrString : dayString

   return <b className={hrs < 1 ? "c-red" : "c-black"}>{duration}</b>
}
export const convertConvertToPHCurrency = (number = 0) => {
   return number.toLocaleString("en-PH", {
      currency: "PHP",
      style: "currency",
   })
}

export const computeTotalDays = ({ start, end }) => {
   const isDateValid = dayjs(end).isValid() && dayjs(start).isValid()
   if (!isDateValid) return 1
   return dayjs(end)?.diff(start, "days") === 0
      ? 1
      : dayjs(end)?.diff(start, "days")
}

export const getRandomDarkColor = (hasHashTag = true) => {
   // Get the current time in milliseconds
   const currentTime = new Date().getTime()

   // Generate a unique seed value based on the current time
   const seed = `${Math.random()}${currentTime}`

   // Set the seed value
   Math.seed = seed

   // Generate random values for red, green, and blue components
   const red = Math.floor(Math.random() * 128)
   const green = Math.floor(Math.random() * 128)
   const blue = Math.floor(Math.random() * 128)

   // Convert values to hexadecimal format
   const redHex = red.toString(16).padStart(2, "0")
   const greenHex = green.toString(16).padStart(2, "0")
   const blueHex = blue.toString(16).padStart(2, "0")

   // Combine hexadecimal values to create a color string
   const hashTag = hasHashTag ? "#" : ""
   const color = `${hashTag}${redHex}${greenHex}${blueHex}`

   return color
}

export const getBase64 = (img, callback) => {
   const reader = new FileReader()
   reader.addEventListener("load", () => callback(reader.result))
   reader.readAsDataURL(img)
}
