import React from 'react'
import { CalendarProvider } from '../../../context/CalendarContext'
import CalendarPage from '../components/CalendarPage'
import { CustomerProvider } from '../../../context/CustomerContext'

export default function Calendar() {
   return (
      <CalendarProvider>
         <CustomerProvider>
            <CalendarPage />
         </CustomerProvider>
      </CalendarProvider>
   )
}
