import React, { useContext } from "react"
import "./styles.css"

import StaffDashboard from "../components/StaffDashboard"
import { CalendarProvider } from "../../../context/CalendarContext"
import { CustomerProvider } from "../../../context/CustomerContext"
import { GlobalContext } from "../../../context/GlobalContext"
import AdminDashboard from "../components/AdminDashboard"

// Under maintenance
// ROI

const Dashboard = () => {
   const { user } = useContext(GlobalContext)
   return (
      <CalendarProvider>
         <CustomerProvider>
            <div>{user?.type === "admin" ? <AdminDashboard /> : <StaffDashboard />}</div>
         </CustomerProvider>
      </CalendarProvider>
   )
}

export default Dashboard
