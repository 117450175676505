import React, { useReducer } from 'react'

import { CarReducer } from './reducer/CarReducer'
import { ADD } from '../data/magicString'
import dayjs from 'dayjs'

const initialState = {
   isLoading: false,
   isAddCarModalOpen: false,
	 addOrUpdate: ADD,
	 car: {},
	 carIndex: -1,
	 selectedDate: dayjs(),
	 unitExpenses: []
}

export const CarContext = React.createContext()

export const CarProvider = (props) => {
   const [carState, dispatch] = useReducer(
      CarReducer,
      initialState
   )

   return (
      <CarContext.Provider
         value={{
            ...carState,
            dispatch,
         }}
      >
         {props.children}
      </CarContext.Provider>
   )
}
