import React from "react"
import FloatingButtonCustom from "../../../common/components/FloatingButtonCustom"
import { FloatButton } from "antd"
import { CloudSyncOutlined, LoadingOutlined } from "@ant-design/icons"

export default function CarLiveStatusFloatBtn({ handleRefreshData, isLoading  }) {
   return ( 
      <FloatingButtonCustom>
         <FloatButton
            style={styles.fabStyleRefresh}
            icon={isLoading ? <LoadingOutlined style={styles.plusCircleIcon} />  :  <CloudSyncOutlined style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleRefreshData}
         />
      </FloatingButtonCustom>
   )
}

const styles = {
   fabStyleRefresh: {
      height: 50,
      width: 50,
      backgroundColor: "rgba(0,255,255,0)",
      paddingRight: "70px !important",
   },
	 plusCircleIcon: {
		overflow: "visible !important",
		fontSize: "3em",
 },
}
