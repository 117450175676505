import React from 'react'

const FloatingButtonCustom = ({ children }) => {
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'right',
            textAlign: 'right',
         }}
      >
         <div className='float-left'></div>
         <div className='mr-5 float-right'>{children}</div>
      </div>
   )
}

export default FloatingButtonCustom
