import React, { useContext, useEffect } from "react"

import { SET_OUT_OF_TOWN_DESTINATIONS } from "../../../context/reducer/GlobalReducer"
import { GlobalContext } from "../../../context/GlobalContext"
import { OOTDProvider } from "../../../context/OOTDContext"

import { getOOTDS } from "../../../lib/services"
import { handleError } from "../../../lib/serverAxios"

import OutOfTownDestinationPage from "./OutOfTownDestinationPage"

export default function OOTD() {
   const { dispatch } = useContext(GlobalContext)

   useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal;
			
			(async () => {
         try {
            const data = await getOOTDS(signal)

            dispatch({
               type: SET_OUT_OF_TOWN_DESTINATIONS,
               outOfTownDestinations: data.ootds,
            })
         } catch (error) {
					handleError(error)
         }
      })()
      return () => {
         controller.abort()
      }
      //eslint-disable-next-line
   }, [])

   return (
      <OOTDProvider>
         <OutOfTownDestinationPage />
      </OOTDProvider>
   )
}
