import React, { useContext, useState } from "react"
import { Card, Form, Input, Button, message, Image } from "antd"
import "./styles.css"
import { useNavigate } from "react-router-dom"
import SERVER from "../../../lib/serverAxios"
import { isEmpty } from "../../../lib/utils"
import { GlobalContext } from "../../../context/GlobalContext"
import Logo from "../../../assets/autowarks-removebg-preview.png"
import Heromoso from "../../../assets/hermosocarrental.png"
import {
   LOGGED_IN,
   SET_USER_DATA,
} from "../../../context/reducer/GlobalReducer"

function Login() {
   const { state, dispatch } = useContext(GlobalContext)
   const [isloading, setLoading] = useState(false)

   let navigate = useNavigate()
   //username === "autowarksadmin788" && password === "YWZbd3)9PQJR" //employe_heromoso (*_*)employeeheromoso[#_@]
   const onFinish = async (values) => {
      try {
         setLoading(true)
         const {
            data: { user, token },
         } = await SERVER.post(`/api/auth/login`, { ...values })
         if (!isEmpty(user)) {
            await localStorage.setItem("isLogin", true)
            await localStorage.setItem("token", token)

            const _user = JSON.stringify(user)
            localStorage.setItem("user", _user)
            dispatch({ type: SET_USER_DATA, user: user })
            dispatch({ type: LOGGED_IN, user: user })

            setLoading(false)
            navigate("/dashboard")
         } else {
            const msg = "Username or password is incorrect!"
            message.error(msg)
            setLoading(false)
         }
      } catch (error) {
         console.log("dsadasd")
         let msg = error?.response?.data?.msg ?? error?.message ?? error
         message.error(msg)
         setLoading(false)
      }
   }

   return (
      <div class='outer'>
         <Image
            preview={false}
            width={200}
            src={Logo}
            style={{ marginTop: "6vw" }}
         />

         <div class='middle'>
            <div class='inner'>
               <Card
                  style={{
                     borderRadius: "10px 10px 10px 10px",
                     boxShadow: "3px 4px 10px -4px rgba(0, 0, 0, 0.75)",
                     border: "0.8799999952316284px solid #B5B5B5",
                     padding: "1vh 1vw",
                  }}
               >
                  <div style={{ marginTop: "1vw", marginBottom: "2vw" }}>
                     <p id='welcome-txt' className='pr-c'>
                        Welcome to <br /> Car renting portal
                     </p>
                  </div>

                  <Form
                     name='normal_login'
                     className='login-form'
                     initialValues={{ remember: true }}
                     onFinish={onFinish}
                  >
                     <Form.Item
                        name='username'
                        rules={[
                           {
                              required: true,
                              message: "Please input your username!",
                           },
                        ]}
                     >
                        <Input placeholder='Username' className='login-input' />
                     </Form.Item>
                     <Form.Item
                        name='password'
                        rules={[
                           {
                              required: true,
                              message: "Please input your Password!",
                           },
                        ]}
                     >
                        <Input.Password
                           placeholder='Password'
                           className='login-input'
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button
                           type='primary'
                           htmlType='submit'
                           className='login-form-button rd-8 wd-100 login-btn sd-sm'
                           loading={isloading}
                           style={{ width: "100%" }}
                        >
                           LOGIN
                        </Button>
                     </Form.Item>
                  </Form>
               </Card>
            </div>
         </div>
         <Image
            preview={false}
            width={200}
            src={Heromoso}
            style={{ marginTop: "6vw" }}
         />
      </div>
   )
}

export default Login
