import React, { Fragment, useContext, useState } from "react"
import { Button, Card, Dropdown, Space } from "antd"
import {
   convertConvertToPHCurrency,
   toUpperCaseFirstLetterWord,
} from "../../../lib/utils"
import Table from "../../../common/components/Table"
import CarsFloatBtn from "../components/CarsFloatBtn"
import { GlobalContext } from "../../../context/GlobalContext"
import CarStatusTag from "../../../common/components/CarStatusTag"
import AddUpdateCar from "../components/AddUpdateCar"

import {
   CaretDownOutlined,
   EditFilled,
   ToolFilled,
} from "@ant-design/icons"
import { branchesData, carStatus } from "../../../data/data"
import CarPageController from "../controller/CarPageController"
import { CarContext } from "../../../context/CarContext"
import { CARSTATUS } from "../../../data/enum"
import CarMonthlyUnitExpensesDrawer from "../components/CarMonthlyUnitExpensesDrawer"

const CarsPage = () => {
   const {
      types,
      cars,
      dispatch: globalDispatch,
   } = useContext(GlobalContext)
   const { dispatch, isCarMonthlyUnitExpensesDrawerOpen } =
      useContext(CarContext)

   const [state, setState] = useState({
      isAddUpdateOpen: false,
      isLoading: false,
   })

   const {
      openCarMonthlyUnitExpensesDrawer,
      handleChangeStatus,
      openUpdateModal,
      handleRefreshData,
   } = CarPageController({
      dispatch,
      setState,
      globalDispatch,
   })

   const items = [
      {
         key: CARSTATUS.available,
         label: <Button className='btn-link-black'> Available</Button>,
      },
      {
         key: CARSTATUS.rented,
         label: <Button className='btn-link-black'>Rented</Button>,
      },
      {
         key: CARSTATUS.inOwner,
         label: <Button className='btn-link-black'>In-owner</Button>,
      },
      {
         key: CARSTATUS.undermaintenance,
         label: <Button className='btn-link-black'>Undermaintenance</Button>,
      },
   ]

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (name, record) => {
            return (
               <div
                  style={{
                     backgroundColor: record.color,
                     color: "white",
                     padding: "2px",
                  }}
               >
                  &nbsp;{name?.toUpperCase()}
               </div>
            )
         },
      },
      {
         title: "Plate no.",
         dataIndex: "plate",
         key: "plate",
      },
      {
         title: "Type",
         dataIndex: "type",
         key: "type",
         filters: types,
         onFilter: (value, record) => record.type === value,
         render: (type) => {
            return <div>{type?.toUpperCase()}</div>
         },
      },
      {
         title: "Branch",
         key: "branchId",
         dataIndex: "branchId",
         filters: branchesData,
         onFilter: (value, record) => record.branchId === value,
         render: (branchId) => {
            const branch = branchesData.find(
               (branch) => branch.value === branchId
            )
            return <div>{toUpperCaseFirstLetterWord(branch?.label)}</div>
         },
      },
      {
         title: "Odometer",
         key: "odometer",
         dataIndex: "odometer",
      },
      {
         title: "Gas",
         key: "gas",
         dataIndex: "gas",
      },
      // {
      //    title: "Owner",
      //    key: "owner",
      //    dataIndex: "owner",
      //    render: (owner) => owner?.fullName,
      // 	 filters: owners,
      // 	 onFilter: (value, record) =>  record.owner.id === value
      // },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         width: 180,
         filters: carStatus,
         onFilter: (value, record) => record.status.indexOf(value) === 0,
         render: (status, record, index) => (
            <Dropdown
               menu={{
                  items,
                  onClick: ({ key }) =>
                     handleChangeStatus({ key, record, index }),
               }}
            >
               <Space size={1}>
                  <CarStatusTag status={status} /> <CaretDownOutlined />{" "}
               </Space>
            </Dropdown>
         ),
      },
      {
         title: "Action",
         key: "action",
         render: (_, record, index) => (
            <Space size='middle'>
               <Button
                  onClick={() => openUpdateModal(record, index)}
                  shape='circle'
                  icon={<EditFilled style={{ color: "#DDA15E" }} />}
               />
               <Button
                  onClick={() =>
                     openCarMonthlyUnitExpensesDrawer(record, index)
                  }
                  shape='circle'
                  icon={<ToolFilled style={{ color: "#DDA15E" }} />}
               />
            </Space>
         ),
      },
   ]

   const carRatesColumn = [
      {
         title: "5 hrs",
         dataIndex: "fiveHours",
         key: "fiveHours",
         render: (fiveHours) => {
            return <div>{convertConvertToPHCurrency(fiveHours)}</div>
         },
      },
      {
         title: "8 hrs",
         dataIndex: "eightHours",
         key: "eightHours",
         render: (amount) => {
            return <div>{convertConvertToPHCurrency(amount)}</div>
         },
      },
      {
         title: "12 hrs",
         dataIndex: "twelveHours",
         key: "twelveHours",
         render: (amount) => {
            return <div>{convertConvertToPHCurrency(amount)}</div>
         },
      },
      {
         title: "24 hrs",
         dataIndex: "twentyFourHours",
         key: "twentyFourHours",
         render: (amount) => {
            return <div>{convertConvertToPHCurrency(amount)}</div>
         },
      },
      {
         title: "Exceeding hrs",
         dataIndex: "exceedingHours",
         key: "exceedingHours",
         render: (amount) => {
            return <div>{convertConvertToPHCurrency(amount)}</div>
         },
      },
      {
         title: "Reservation Fee",
         dataIndex: "reservationFee",
         key: "reservationFee",
         render: (amount) => {
            return <div>{convertConvertToPHCurrency(amount)}</div>
         },
      },
   ]

   return (
      <Fragment>
         <Card className='sd-sm'>
            <div>
               <div style={{ float: "left" }}></div>
               <div style={{ float: "right" }}></div>
            </div>
            <Table
               isLoading={state.isLoading}
               columns={columns}
               dataSource={cars}
               expandable={{
                  expandedRowRender: (record) => (
                     <Table
                        pagination={false}
                        columns={carRatesColumn}
                        style={{
                           marginTop: "0.2vw",
                           marginBottom: "3vw",
                           marginLeft: "2vw",
                        }}
                        dataSource={[{ ...record.carRate }]}
                        footer={() => <b>Total Amount: 8000</b>}
                     />
                  ),
               }}
            />
            <AddUpdateCar />
            <CarsFloatBtn handleRefreshData={handleRefreshData} />
         </Card>
         {isCarMonthlyUnitExpensesDrawerOpen && (
            <CarMonthlyUnitExpensesDrawer />
         )}
      </Fragment>
   )
}

export default CarsPage
