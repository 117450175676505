import { message } from "antd"
import dayjs from "dayjs"
import { customerCategoryObject } from "../../data/data"

export const SET_LOADING = "SET_LOADING"
export const SET_CUSTOMER= "SET_CUSTOMER"
export const OPEN_UPLOAD_MODAL = "OPEN_UPLOAD_MODAL"
export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const INSERT_CUSTOMER = "INSERT_CUSTOMER"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const ARCHIVE_CUSTOMER = "ARCHIVE_CUSTOMER"
export const SET_CUSTOMER_BOOKS = "SET_CUSTOMER_BOOKS"


export const CustomerReducer = (state, action) => {
   let customer, customers, stringifiedCustomers
   switch (action.type) {
		case SET_CUSTOMER_BOOKS:
			return {
				 ...state,
				 customerBooks: action.customerBooks,
			}
		case SET_CUSTOMER:
			return {
				 ...state,
				 customer: action.customer,
				 customerID: action.customer.id
			}
      case "SET_LOADING":
         return {
            ...state,
            isLoading: action.isLoading,
         }
      case "OPEN_UPLOAD_MODAL":
         return {
            ...state,
            isUploadIDOpen: action.isUploadIDOpen,
            customerID: action.customerID,
         }
      case "ARCHIVE_CUSTOMER":
         let _customers = [...state.customers]
         _customers.splice(action?.index, 1)
         return {
            ...state,
            customers: _customers,
         }
      case "UPDATE_CUSTOMER":
         customer = action?._customer
         customers = [...state.customers]
         customers.splice(customer?.index, 1, customer)

         stringifiedCustomers = JSON.stringify(customers)
         localStorage.setItem("customers", stringifiedCustomers)
         return {
            ...state,
            customers,
         }
      case "INSERT_CUSTOMER":
         customer = action.customer
         customer.label = `${customer?.firstName} ${customer?.lastName} - ${
            customer?.gender
         } - ${dayjs(customer.birthday).format("MM/DD/YYYY")} - ${
            customer?.category
         }`
         customer.value = customer?.id
         customers = [{ ...customer }, ...state.customers]
         stringifiedCustomers = JSON.stringify(customers)
         localStorage.setItem("customers", stringifiedCustomers)
         return {
            ...state,
            customers: customers,
         }
      case SET_CUSTOMERS:
         customers = action.customers.map((customer) => {
            return {
               ...customer,
               label: `${customer?.firstName} ${customer?.lastName} - ${
                  customer?.gender
               } - ${dayjs(customer.birthday).format("MM/DD/YYYY")} - ${
                  customerCategoryObject[customer?.category]
               }`,
               value: customer.id,
            }
         })
         stringifiedCustomers = JSON.stringify(customers)
         localStorage.setItem("customers", stringifiedCustomers)
         return {
            ...state,
            customers: customers,
         }

      default:
         message.error("Out of range CustomerReducer")
         return state
   }
}
