	import SERVER, { handleError } from '../../../lib/serverAxios'
import {
   SET_BOOK_RESERVATION,
   TOGGLE_DRAWER,
} from '../../../context/reducer/CalendarReducer'

const ReleaseForTodayController = ({ dispatch }) => {
   const handleReleaseReturn = async (id) => {
      try {
         const {
            data: { bookReservations },
         } = await SERVER.get(`/api/books-reservation/fine-one?id=${id}`)

         dispatch({
            type: SET_BOOK_RESERVATION,
            bookReservation: bookReservations,
         })
         dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: true })
      } catch (error) {
        handleError(error)
      }

      // clickInfo.event.remove()
   }
   return { handleReleaseReturn }
}

export default ReleaseForTodayController

