import { CARSTATUS } from "./enum"

export const branchesWAllData = [
   { label: "ALL", value: "all", color: "red" },
   { label: "Autowarks", value: "autowarks", color: "red" },
   { label: "Hermoso", value: "hermoso", color: "yello" },
]

export const branchObject = {
  1: "Autowarks",
	2: "Hermoso"
}

export const customerCategoryObject = {
   1: "Ordinary",
   2: "VIP",
   3: "Banned",
}

export const customerCategoryData = [
   { key: 1, text: "Ordinary", label: "Ordinary", value: 1 },
   { key: 2, text: "VIP", label: "VIP", value: 2 },
]

export const branchesData = [
   { key: 1, text: "Autowarks", label: "Autowarks", value: 1, color: "red" },
   { key: 2, text: "Hermoso", label: "Hermoso", value: 2, color: "yello" },
]
export function generateDayData() {
	const dayData = [];
	for (let i = 1; i <= 30; i++) {
			dayData.push({ key: i, label: `${i}`, value: i });
	}
	return dayData;
}

export const carTypesData = [
   { label: "ALL", value: "all", color: "red" },
   { label: "Hatchback", value: "hatchback", color: "red" },
   { label: "Sedan", value: "sedan", color: "red" },
   { label: "Crossover", value: "crossover", color: "yello" },
]
export const carStatusWAllData = [
	{ label: "ALL", value: "all" },
	{
		 label: "Available",
		 text: CARSTATUS.available,
		 value: CARSTATUS.available,
	},
	{ label: "Rented", text: CARSTATUS.rented, value: CARSTATUS.rented },
	{ label: "In-owner", text: CARSTATUS.inOwner, value: CARSTATUS.inOwner },
	{
		 label: "Undermaintenance",
		 text: CARSTATUS.undermaintenance,
		 value: CARSTATUS.undermaintenance,
	},
]

export const carStatus = [
   {
      label: "Available",
      text: CARSTATUS.available,
      value: CARSTATUS.available,
   },
   { label: "Rented", text: CARSTATUS.rented, value: CARSTATUS.rented },
   { label: "In-owner", text: CARSTATUS.inOwner, value: CARSTATUS.inOwner },
   {
      label: "Undermaintenance",
      text: CARSTATUS.undermaintenance,
      value: CARSTATUS.undermaintenance,
   },
]

export const stateData = [
   { label: "ALL", value: "all" },
   { label: "Active", value: "active" },
   { label: "Inactive", value: "inactive" },
]

export const typeOfDamageData = [
   { label: "Minor", value: 1 },
   { label: "Major", value: 2 },
]

export const paymentTypeData = [
   { key: 1, text: "Cash", label: "Cash", value: 1 },
   { key: 2, text: "GCash", label: "GCash", value: 2 },
   { key: 3, text: "Bank", label: "Bank", value: 3 },
]

export const modeOfPaymentObject = {
   1: "Cash",
   2: "Gcash",
   3: "Bank",
}
