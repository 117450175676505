
export const TOGGLE_ADD_UPDATE_CAR_MODAL = "TOGGLE_ADD_UPDATE_CAR_MODAL"
export const SET_CAR = "SET_CAR"
export const OPEN_UPDATE = "OPEN_UPDATE"
export const OPEN_CarMonthly_Unit_Expenses_Drawer= "OPEN_CarMonthly_Unit_Expenses_Drawer"
export const CLOSE_UNIT_MONTLY_EXPENSES_DRAWER = "CLOSE_UNIT_MONTLY_EXPENSES_DRAWER"
export const SET_DATE = "SET_DATE"
export const SET_UNIT_EXPENSES = "SET_UNIT_EXPENSES"
export const UPDATE_UNIT_EXPENSES = "UPDATE_UNIT_EXPENSES"
export const INSERT_NEW_UNIT_EXPENSE = "INSERT_NEW_UNIT_EXPENSE"

export const SET_LOADING = "SET_LOADING"




export const CarReducer = (state, action) => {
	let unitExpense, unitExpenses
   switch (action.type) {
		case INSERT_NEW_UNIT_EXPENSE:
				unitExpense = {...action.unitExpense}
				unitExpenses = [...state?.unitExpenses]
				unitExpenses.push(unitExpense)

				return {
					 ...state,
					 unitExpenses,
					 isLoading: false
				}
		case SET_LOADING:
			return {
				 ...state,
				 isLoading: action?.isLoading,
			}
			case UPDATE_UNIT_EXPENSES:
				unitExpense = action.unitExpense
				unitExpenses = [...state?.unitExpenses]
				unitExpenses.splice(action?.index, 1, unitExpense)

				return {
					 ...state,
					 unitExpenses,
					 isLoading: false
				}
		case SET_UNIT_EXPENSES:
			return {
				 ...state,
				 unitExpenses: action?.unitExpenses,
				 isLoading: false
			}
		case SET_DATE:
			return {
				 ...state,
				 selectedDate: action.selectedDate,
			}
		case "CLOSE_UNIT_MONTLY_EXPENSES_DRAWER":
			return {
				 ...state,
				 isCarMonthlyUnitExpensesDrawerOpen: false,
			}
		case "OPEN_CarMonthly_Unit_Expenses_Drawer":
			return {
				 ...state,
				 isCarMonthlyUnitExpensesDrawerOpen: true,
				 car: action?.car,
				 carIndex: action?.carIndex,
			}
		case "OPEN_UPDATE":
			return {
				 ...state,
				 car: action.car,
				 carIndex: action.carIndex,
			}
      case "SET_CAR":
         return {
            ...state,
            car: action.car,
						isAddCarModalOpen: false
         }
      case "TOGGLE_ADD_UPDATE_CAR_MODAL":
         return {
            ...state,
						addOrUpdate: action?.addOrUpdate,
            isAddCarModalOpen: action.isAddCarModalOpen,
         }
      default:
         return state
   }
}
