import dayjs from "dayjs"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { isEmpty } from "../../../lib/utils"

const RentedStatusController = ({ setCars }) => {

   const getCarsWithRecentBooked = async () => {
      try {
         const {
            data: { cars },
         } = await SERVER.get(`/api/cars/most-recent-booked`)
         const _cars = await cars?.map((car) => {
            return {
               ...car,
               daysNotBeingRented: isEmpty(car?.recentReservation)
                  ? dayjs().diff(car?.createdAt, "day")
                  : dayjs().diff(car?.recentReservation?.dateReturned, "day"),
            }
         })

         const sortedCars = _cars
            .sort((a, b) => b.daysNotBeingRented - a.daysNotBeingRented)
            .filter((e) => e.daysNotBeingRented > 2)
         setCars(sortedCars)
      } catch (error) {
         handleError(error)
      }
   }
	 
   return { getCarsWithRecentBooked }
}

export default RentedStatusController
