import { PrinterFilled } from '@ant-design/icons'
import { Affix } from 'antd'
import React from 'react'

const PrintPdfMontlyInventory = ({ handlePrint }) => {
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'right',
            textAlign: 'right',
         }}
      >
         <div className='float-left'></div>
         <div className='mr-5 float-right'>
            <Affix offsetBottom={30}>
               <PrinterFilled
                  onClick={handlePrint}
                  style={{
                     color: '#590900',
                     fontSize: '3em',
                     backgroundColor: 'white',
                  }}
               />
            </Affix>
         </div>
      </div>
   )
}

export default PrintPdfMontlyInventory
