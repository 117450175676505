import React, { useReducer } from 'react'
import {
   CalendarReducer,
} from './reducer/CalendarReducer'

const initialCalendarState = {
   isLoading: false,
   isBookReservationDrawerOpen: false,
   isOOTD: false,
	 isCDchecked: false,
   destinationFee: 0,
   minDays: 0,
   withDriverFee: 0, // OOTD
   withDriver: false,
   destinationID: 0,
   selectedCar: null,
	 startStr: '',
	 bookReservation: {},
   date: {
      start: '',
      end: '',
   },
	 hasDamage: false,
	 damageCost: 0, // use in PaymentRow
   bookReservations: [],
	 rentalAmountLeft: 0,
	 withDriverAmountLeft: 0,
	 exceedingFee: 0,
	 isCustomer: true,
	 adjustment: 0,
	 editable: false
}

export const CalendarContext = React.createContext()

export const CalendarProvider = (props) => {
   const [calendarState, dispatch] = useReducer(
      CalendarReducer,
      initialCalendarState
   )

   return (
      <CalendarContext.Provider
         value={{
            ...calendarState,
            dispatch,
         }}
      >
         {props.children}
      </CalendarContext.Provider>
   )
}
