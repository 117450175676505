import React from 'react'
import { Checkbox, Col, Form } from 'antd'

export default function ReleaseChecklist() {
   return (
      <>
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: 'bold', textDecoration: 'underline' }}
         >
            CHECKLIST
         </Col>
         <Col span={4}>
            <span className='nti'>Release </span>
            <Form.Item style={{marginBottom: '0px'}} valuePropName='checked' name='orcr'>
               <Checkbox >
                  OR/CR
               </Checkbox>
            </Form.Item>
            <Form.Item  style={{marginBottom: '0px'}} valuePropName='checked' name='tools'>
               <Checkbox>Tools</Checkbox>
            </Form.Item>
            <Form.Item  valuePropName='checked' name='stinkyOdor'>
               <Checkbox>Stinky Odor</Checkbox>
            </Form.Item>
         </Col>
         <Col span={4}>
            &nbsp;
            <Form.Item style={{marginBottom: '0px'}} valuePropName='checked' name='spareTire'>
               <Checkbox>Spare-Tire</Checkbox>
            </Form.Item>
            <Form.Item style={{marginBottom: '0px'}} valuePropName='checked' name='jack'>
               <Checkbox>Jack</Checkbox>
            </Form.Item>
            <Form.Item valuePropName='checked' name='dirtyStained'>
               <Checkbox>Dirty Stained</Checkbox>
            </Form.Item>
         </Col>
      </>
   )
}


