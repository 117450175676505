import { Col, DatePicker, Divider, Form, Space } from "antd"
import React, { Fragment, useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import dayjs from "dayjs"
import { isEmpty } from "../../../lib/utils"
import { isRelease } from "../../commons/controllers/bookReservationsStatesController"

export default function DateSection() {
   const { bookReservation, editable } = useContext(CalendarContext)

   const { car, start, end, dateReleased, dateReturned, state } =
      bookReservation

   const isForRelease = isRelease({ state, start, end })

   const disabledTo = (current) => {
      return current > dayjs().endOf("day")
   }
	 const disabledEndDate = (current) => {
		return current < dayjs(start).endOf("day").subtract(1, 'day')
 }

   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            DATE
         </Col>

         <Col span={12}>
            <Space direction='vertical'>
               <div>
                  <span className='nti'> Start Date: </span>
                  <b>
                     <i>{dayjs(start).format("MMMM DD, YYYY (h:mm A)")}</i>
                  </b>
               </div>

               {editable ? (
                  <Form.Item
                     label='End Date'
                     name='end'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
										 rules={[
											{
												 required: true,
												 message: "End date is required",
											},
									 ]}
                  >
                     <DatePicker
                        format='MM-DD-YYYY HH:mm'
                        showTime={true}
                        disabledDate={disabledEndDate}
                     />
                  </Form.Item>
               ) : (
                  <div>
                     <span className='nti'> End Date: </span>
                     <b>
                        <i>{dayjs(end)?.format("MMMM DD, YYYY (h:mm A)")}</i>
                     </b>
                  </div>
               )}
            </Space>
         </Col>
         <Col span={12}>
            <Space direction='vertical'>
               <div>
                  <b>
                     {isForRelease ? (
                        <Form.Item
                           label='Date Release'
                           name='dateRelease'
                           style={{
                              width: "100%",
                              marginBottom: "0px",
                           }}
                        >
                           <DatePicker
                              format='MM-DD-YYYY HH:mm'
                              showTime={true}
                              disabledDate={disabledTo}
                           />
                        </Form.Item>
                     ) : (
                        ""
                     )}
                     {!isEmpty(dateReleased) && (
                        <div>
                           <span className='nti'> Date Release: </span>

                           <i>
                              {dayjs(dateReleased).format(
                                 "MMMM DD, YYYY (h:mm A)"
                              )}
                           </i>
                        </div>
                     )}
                  </b>
               </div>
               <div>
                  {!isEmpty(dateReleased) && isEmpty(dateReturned) ? (
                     <Form.Item
                        label='Date Returned'
                        name='dateReturn'
                        style={{
                           width: "100%",
                        }}
                     >
                        <DatePicker
                           format='MM-DD-YYYY HH:mm'
                           showTime={true}
                           defaultValue={dayjs()}
                           disabledDate={disabledTo}
                        />
                     </Form.Item>
                  ) : (
                     ""
                  )}
                  {!isEmpty(dateReturned) && (
                     <div>
                        {" "}
                        <span className='nti'> Date Return: </span>
                        <b>
                           <i>
                              {dayjs(dateReturned).format(
                                 "MMMM DD, YYYY (h:mm A)"
                              )}
                           </i>
                        </b>
                     </div>
                  )}
               </div>
            </Space>
         </Col>
      </Fragment>
   )
}
