import React, { useContext, useEffect, useState } from "react"
import "../view/styles.css"

import { Card, Col, Row } from "antd"
import OverdueBooked from "./OverdueBooked"
import DashboardFloatBtn from "./DashboardFloatBtn"
import ReleaseForToday from "./ReleaseForToday"
import RentedStatus from "./RentedStatus"
import BookReservationDrawer from "../../calendar/components/BookReservationDrawer"
import SwitchCarDrawer from "../../calendar/components/SwitchCarDrawer"
import { CalendarContext } from "../../../context/CalendarContext"
import BookOrReserveModal from "./BookOrReserveModal"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { getCars } from "../../../lib/services"
import { GlobalContext } from "../../../context/GlobalContext"
import { SET_CARS } from "../../../context/reducer/GlobalReducer"
import FourCardsAdmin from "./FourCardsAdmin"
import IncomesChart from "./IncomesChart"
// Under maintenance
// ROI

const AdminDashboard = () => {
   const { isBookReservationDrawerOpen, isSwitchCarDrawerOpen } =
      useContext(CalendarContext)

   const { dispatch } = useContext(GlobalContext)

   const [state, setState] = useState({
      isAddUpdateModalOpen: false,
      addOrUpdate: "Add",
      updateCount: 1,
   })
   const [isSelectCarModalOpen, setIsSelectCarModalOpen] = useState(false)
   const [dataIncomes, setDataIncomes] = useState([])
   const [payments, setPayments] = useState([])


   const handleRefreshData = () => {
      setState((prevState) => ({
         ...prevState,
         updateCount: prevState?.updateCount + 1,
         isLoading: true,
      }))
   }

   const initialize = async () => {
      try {
         const { cars } = await getCars()
         dispatch({
            type: SET_CARS,
            cars,
         })
         const {
            data: { invoices },
         } = await SERVER.get("/api/invoice/this-year")
         setDataIncomes(invoices)
         const {
            data: { payments },
         } = await SERVER.get("/api/invoice/this-year-payments")
				 setPayments(payments)
				} catch (error) {
         handleError(error)
      }
   }

   useEffect(() => {
      initialize()
      setState((prevState) => ({
         ...prevState,
         isLoading: false,
      }))
      //eslint-disable-next-line
   }, [state?.updateCount])

   return (
      <div style={{ marginBottom: "1vw" }}>
         <FourCardsAdmin updateCount={state?.updateCount} />

         <Row gutter={[8, 8]} style={{ marginTop: "1vw" }}>
            <Col span={12}>
               <ReleaseForToday
                  updateCount={state?.updateCount}
                  isSelectCarModalOpen={isSelectCarModalOpen}
               />
               <RentedStatus updateCount={state?.updateCount} />
            </Col>
            <Col span={12}>
               <Card className='sd-sm' style={{ width: "100%" }}>
                  <OverdueBooked
                     updateCount={state?.updateCount}
                     isLoading={state.isLoading}
                  />
               </Card>
            </Col>
         </Row>
         <Row style={{ marginTop: "1vw" }}>
            <Col span={24}>
               <IncomesChart payments={payments} dataIncomes={dataIncomes} />
            </Col>
         </Row>
         {!!isBookReservationDrawerOpen && <BookReservationDrawer />}
         {!!isSwitchCarDrawerOpen && <SwitchCarDrawer />}
         {!!isSelectCarModalOpen && (
            <BookOrReserveModal
               isSelectCarModalOpen={isSelectCarModalOpen}
               setIsSelectCarModalOpen={setIsSelectCarModalOpen}
            />
         )}
         <DashboardFloatBtn
            isLoading={state?.isLoading}
            setIsSelectCarModalOpen={setIsSelectCarModalOpen}
            handleRefreshData={handleRefreshData}
         />
      </div>
   )
}

export default AdminDashboard
