export const BOOKSTATE = {
	cancelled: "cancelled",
	done: "done",
	switched: "switched", //car switched
	ongoing: "ongoing",
	reserved: "reserved",
	released: "released",
	voided: "voided",
	returned: "returned"
}
export const FLAG_BOOK = {
	normal: "normal",
	switchedBooked: "switchedBooked",
	beenSwitched: "beenSwitched", //car switched
}

export const TYPE_OF_DAMAGE = {
   1: "Minor",
   2: "Major",
}

export const CARSTATUS = {
  available:  'available', 
	rented: 'rented',
	inOwner: 'in-owner',
	undermaintenance: 'undermaintenance'
}

export const RELEASED = 'released'
export const RETURNED = 'returned'
export const ISEmsg = 'Internal Server Error: No Internet Connection!'


export const ID = {
	selfie: "selfie",
	firstFront: "firstFront",
	firstBack: "firstBack",
	secondFront: "secondFront",
	secondBack: "secondBack"
}

export const USERTYPE = {
  admin: "admin",
  employee: "employee"
}

export const DRIVERFEE = {
	fiveEight: 750,
	nineTwelve: 850,
	thirteen: 1200,
	hourly: 50
}

export const CAR_TYPE = {
  truck: "truck",
}