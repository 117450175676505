import React, { useContext } from "react"
import { PlusCircleFilled, CloudSyncOutlined } from "@ant-design/icons"
import { FloatButton } from "antd"
import FloatingButtonCustom from "../../../common/components/FloatingButtonCustom"
import { CarContext } from "../../../context/CarContext"
import { TOGGLE_ADD_UPDATE_CAR_MODAL } from "../../../context/reducer/CarReducer"
import { ADD } from "../../../data/magicString"

const CarsFloatBtn = ({ handleRefreshData }) => {
   const { dispatch } = useContext(CarContext)
   return (
      <FloatingButtonCustom>
         <FloatButton
            style={styles.fabStyle}
            icon={<PlusCircleFilled style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={() =>
               dispatch({
                  type: TOGGLE_ADD_UPDATE_CAR_MODAL,
                  addOrUpdate: ADD,
                  isAddCarModalOpen: true,
               })
            }
         />
         <FloatButton
            style={styles.fabStyleRefresh}
            icon={<CloudSyncOutlined style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleRefreshData}
         />
      </FloatingButtonCustom>
   )
}

export default CarsFloatBtn

const styles = {
   fabStyleRefresh: {
      height: 50,
      width: 50,
      backgroundColor: "rgba(0,255,255,0)",
      paddingRight: "70px !important",
   },
   fabStyle: {
      height: 50,
      width: 50,
      backgroundColor: "rgba(0,255,255,0)",
      paddingRight: "70px !important",
      marginBottom: "3.5vw",
   },
   plusCircleIcon: {
      overflow: "visible !important",
      fontSize: "3em",
   },
}
