import dayjs from "dayjs"
import { TOGGLE_SWITCH_CAR_DRAWER } from "../../../context/reducer/CalendarReducer"
import { CARSTATUS } from "../../../data/enum"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { isEmpty } from "../../../lib/utils"
import {
   computeDurationFee,
   computeWithDriverFee,
   getTotalHours,
} from "../../../lib/calculation"

const SwitchCarDrawerController = ({
   newCar,
   car,
   bookReservation,
   computBalance,
   form,
   setNewCar,
   dispatch,
   oldBookReservationValues,
   setDateRelease,
   exceedingFee,
   rentalAmountLeft,
}) => {
   const handleChangeDateRelease = (date) => {
      setDateRelease(date)
   }

   const durationFeeOldCar = computeDurationFee({
      selectedCar: car,
      start: bookReservation?.dateReleased,
      end: oldBookReservationValues?.dateReturn,
   })

   const onChangeCar = (value, selectedCar) => {
      setNewCar(selectedCar)
   }

   const closeModal = () => {
      dispatch({ type: TOGGLE_SWITCH_CAR_DRAWER, isSwitchCarDrawerOpen: false })
   }

   const filterCarSelection = (_car) => {
      if (_car.status === CARSTATUS.available) return true
   }

   const segregateDestinationFee = () => {
      let oldDestinationFee = 0,
         newDestinationFee = 0

      const consumeHrs = getTotalHours({
         start: bookReservation?.dateReleased,
         end: oldBookReservationValues?.dateReturn,
      })
      const totalHrs = getTotalHours({
         start: bookReservation?.dateReleased,
         end: bookReservation?.end,
      })

      const cityProper = isEmpty(bookReservation?.ootd)
      if (cityProper) {
         return [0, 0]
      }
      const minDaysNotMet = consumeHrs < bookReservation?.ootd.minDays
      if (minDaysNotMet) {
         return [0, bookReservation?.payment?.destinationFee]
      }

      const hasShareDestinationFee = !minDaysNotMet
      if (hasShareDestinationFee) {
         const percentage = (consumeHrs / totalHrs) * 100
         const _oldDestinationFee = Math.floor(
            (percentage / 100) * bookReservation?.payment.destinationFee
         )
         const newDestinationFee =
            parseInt(bookReservation?.payment?.destinationFee) -
            _oldDestinationFee
         return [_oldDestinationFee, newDestinationFee]
      }

      return [oldDestinationFee, newDestinationFee] // just  an safety measure
   }
   segregateDestinationFee()

   const switchCar = async () => {
      const balance = await computBalance()

      try {
         await form.validateFields()

         const formValues = form.getFieldsValue()

         const durationFee = computeDurationFee({
            selectedCar: newCar,
            start: formValues?.dateRelease,
            end: bookReservation?.end,
         })

         const withDriverFee = computeWithDriverFee({
            withDriver: bookReservation?.withDriver,
            withDriverFee: bookReservation?.ootd?.withDriverFee,
            start: formValues?.dateRelease,
            end: bookReservation?.end,
         })

         const [oldDestinationFee, newDestinationFee] =
            await segregateDestinationFee()

         const values = {
            ...formValues,
            dateRelease: !isEmpty(formValues?.dateRelease)
               ? formValues?.dateRelease.format()
               : dayjs().format(),
         }

         const oldValues = {
            ...bookReservation,
            ...oldBookReservationValues,
            payments: {
               rentalFee: durationFeeOldCar,
               destinationFee: oldDestinationFee,
            },
         }

         await SERVER.post(`/api/books-reservation/switch-car`, {
            newValues: values,
            oldBookReservationValues: oldValues,
         })
         closeModal()
         window.location.reload()
      } catch (error) {
         handleError(error)
      }
   }

   return {
      handleChangeDateRelease,
      onChangeCar,
      closeModal,
      filterCarSelection,
      switchCar,
   }
}

export default SwitchCarDrawerController
