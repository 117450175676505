import { message } from "antd"

export const TOGGLE_LOADING = "TOGGLE_LOADING"
export const SET_SELECTED_CAR = "SET_SELECTED_CAR"
export const SET_OOTD = "SET_OOTD"
export const SET_DATE = "SET_DATE"
export const TOGGLE_W_DRIVER = "TOGGLE_W_DRIVER"
export const RESET_OOTD = "RESET_OOTD"
export const SET_BOOK_RESERBVATIONS = "SET_BOOK_RESERBVATIONS"
export const ACTION_IN_PROGRESS = "ACTION_IN_PROGRESS"
export const ACTION_FULFILLED = "ACTION_FULFILLED"
export const RESET_FORM_STATE = "RESET_FORM_STATE"
export const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const TOGGLE_SWITCH_CAR_DRAWER = "TOGGLE_SWITCH_CAR_DRAWER"
export const SWITCH_VEHICLE = "SWITCH_VEHICLE"
export const SET_BOOK_RESERVATION = "SET_BOOK_RESERVATION"
export const SET_SELECTED_DATE = "SET_SELECTED_DATE"
export const TOGGLE_HAS_DAMAGE = "TOGGLE_HAS_DAMAGE"
export const CHANGE_DAMAGE_COST = "CHANGE_DAMAGE_COST"
export const SAVE_EXCEEDING_FEE = "SAVE_EXCEEDING_FEE"
export const SAVE_RENTAL_AMOUNT_LEFT = "SAVE_RENTAL_AMOUNT_LEFT"
export const SAVE_AMOUNTS_LEFT = "SAVE_AMOUNTS_LEFT"
export const TOGGLE_OWNER_CUSTOMER = "TOGGLE_OWNER_CUSTOMER"
export const TOGGLE_CD = "TOGGLE_CD"
export const CHANGE_ADJUSTMENT = "CHANGE_ADJUSTMENT"
export const TOGGLE_EDITABLE = "TOGGLE_EDITABLE"






export const CalendarReducer = (state, action) => {
   switch (action.type) {
		case TOGGLE_EDITABLE:
			return {
				 ...state,
				 editable: action.editable,
			}
		case CHANGE_ADJUSTMENT:
			return {
				 ...state,
				 adjustment: action.adjustment,
			}
		case TOGGLE_CD:
			return {
				 ...state,
				 isCDchecked: action.isCDchecked,
			}
		case TOGGLE_OWNER_CUSTOMER:
			return {
				 ...state,
				 isCustomer: action.checked,
			}
	  	case SAVE_AMOUNTS_LEFT:
			return {
				 ...state,
				 rentalAmountLeft: action.oldCarRentalFee,
				 withDriverAmountLeft: action.withDriverAmountLeft
			}
      case SAVE_EXCEEDING_FEE:
         return {
            ...state,
            exceedingFee: action.exceedingFee,
         }
      case SWITCH_VEHICLE:
         return {
            ...state,
            oldBookReservationValues: action.oldBookReservationValues,
         }
      case "CHANGE_DAMAGE_COST":
         return {
            ...state,
            damageCost: action.damageCost,
         }
      case "TOGGLE_HAS_DAMAGE":
         return {
            ...state,
            hasDamage: action.hasDamage,
         }
      case "SET_SELECTED_DATE":
         return {
            ...state,
            selectedDateStr: action.selectedDateStr,
         }
      case "SET_BOOK_RESERVATION":
         return {
            ...state,
            bookReservation: action.bookReservation,
         }
      case ACTION_IN_PROGRESS:
         return {
            ...state,
            isLoading: true,
         }
      case "ACTION_FULFILLED":
         return {
            ...state,
            isLoading: false,
         }

      case "TOGGLE_DRAWER":
         return {
            ...state,
            isBookReservationDrawerOpen: action.isBookReservationDrawerOpen,
						editable: false
         }
      case TOGGLE_SWITCH_CAR_DRAWER:
         return {
            ...state,
            isSwitchCarDrawerOpen: action.isSwitchCarDrawerOpen,
         }
      case "TOGGLE_LOADING":
         return {
            ...state,
            isLoading: !state.isLoading,
         }
      case "SET_SELECTED_CAR":
         return {
            ...state,
            selectedCar: action.car,
         }
      case "RESET_OOTD":
         return {
            ...state,
            destinationFee: 0,
            minDays: null,
            withDriverFee: 0,
         }
      case RESET_FORM_STATE:
         return {
            ...state,
            isOOTD: false,
            destinationFee: 0,
            minDays: null,
            withDriverFee: 0,
            withDriver: false,
            selectedCar: null,
            date: {
               start: "",
               end: "",
            },
         }
      case "SET_OOTD":
         return {
            ...state,
            destinationFee: action.destinationFee,
            minDays: action.minDays,
            withDriverFee: action.withDriverFee,
            destinationID: action.destinationID,
         }

      case "SET_DATE":
         return {
            ...state,
            date: {
               start: action.start,
               end: action.end,
            },
         }
      case "TOGGLE_W_DRIVER":
         return {
            ...state,
            withDriver: action.withDriver,
         }

      case "SET_BOOK_RESERBVATIONS":
         const bookReservations = action.bookReservations.map((booked) => {
            return {
               ...booked,
               title: booked.car.name + "#***#" + booked?.state + "#***#"  + booked?.flag,
               color: booked.car.color,
               state: booked.state,
               id: booked.id,
            }
         })
         return {
            ...state,
            bookReservations: bookReservations,
            isLoading: false,
         }
      default:
         return state
   }
}
