import { message } from "antd"
import {
   SET_OOTD,
   TOGGLE_ADD_UPDATE_OOTD_MODAL,
} from "../../../context/reducer/OOTDReducer"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { ISEmsg } from "../../../data/enum"
import { INSERT_OOD, UPDATE_OOTD } from "../../../context/reducer/GlobalReducer"
import { ADD } from "../../../data/magicString"

const AddUpdateOOTDController = ({
   ootd,
   globalDispatch,
   form,
   addOrUpdate,
   dispatch,
   ootdIndex,
}) => {
   const title = () => {
      return `${addOrUpdate.toUpperCase()} Out of Town Destination`
   }
   const handleAddOOTD = async () => {
      try {
         const fieldsValue = await form.getFieldsValue()
         const { data } = await SERVER.post("/api/ootd", {
            ...fieldsValue,
         })
         globalDispatch({ type: INSERT_OOD, ootd: data?.ootd })
         dispatch({
            type: TOGGLE_ADD_UPDATE_OOTD_MODAL,
            isAddCarModalOpen: false,
         })
         message.success("Car successfully created.")
         form.resetFields()
      } catch (error) {
         handleError(error)
      }
   }

   const handleUpdateOOTD = async () => {
      try {
         const fieldsValue = await form.getFieldsValue()
         const { data } = await SERVER.put("/api/ootd", {
            id: ootd.id,
            ...fieldsValue,
         })
         form.resetFields()
         globalDispatch({
            type: UPDATE_OOTD,
            ootd: data?.ootd,
            ootdIndex,
         })
         dispatch({
            type: TOGGLE_ADD_UPDATE_OOTD_MODAL,
            addOrUpdate,
            isAddCarModalOpen: false,
         })
         message.success("Car successfully updated.")
      } catch (error) {
         handleError(error)
      }
   }

   const handleOk = () => {
      if (addOrUpdate === ADD) {
         handleAddOOTD()
      } else {
         handleUpdateOOTD()
      }
   }

   const handleCancel = () => {
      dispatch({
         type: TOGGLE_ADD_UPDATE_OOTD_MODAL,
         isAddUpdateModalOpen: false,
         addOrUpdate,
      })
      dispatch({ type: SET_OOTD, car: {} })
      form.resetFields()
   }

   return { title, handleCancel, handleOk }
}
export default AddUpdateOOTDController
