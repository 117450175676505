import { message } from "antd"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { UPDATE_UNIT_EXPENSES } from "../../../context/reducer/CarReducer"
import { isEmpty } from "../../../lib/utils"

const CarExpensesTableController = ({ dispatch, unitExpenses }) => {
   const validateValues = (values) => {
      let hasError = false
      const { carWash, pickupOrDelivery, maintenance, referral, description } =
         values

      const isEmptyExpensesFields =
         (isEmpty(pickupOrDelivery) || parseInt(pickupOrDelivery) < 0) &&
         (isEmpty(maintenance) || parseInt(maintenance) < 0) &&
         (isEmpty(referral) || parseInt(referral) < 0)

      if (isEmptyExpensesFields && isEmpty(carWash)) {
         message.info("Field is empty")
         hasError = true
      }
      if (!isEmptyExpensesFields) {
         if (isEmpty(description)) {
            message.info("Description is empty.")
            hasError = true
         }
      }
      if (isEmptyExpensesFields && !isEmpty(carWash)) {
         if (!isEmpty(description)) {
            message.info(
               "Please remove description, it's not needed if carwash only."
            )
            hasError = true
         }
      }

      return hasError
   }

   const handleUpdateExpense = async (record, index) => {
      const newData = unitExpenses.filter((item) => item.id === record?.id)?.[0]
      const hasError = await validateValues(newData)
      if (hasError) return
      try {
         const uri = `/api/cars/unit-expense`
         const {
            data: { unitExpense },
         } = await SERVER.put(uri, { ...newData })
         dispatch({ type: UPDATE_UNIT_EXPENSES, index, unitExpense })
         message.success("Succesfully updated.")
      } catch (error) {
         handleError(error)
      }
   }

   return { handleUpdateExpense }
}

export default CarExpensesTableController
