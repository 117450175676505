const AdminMenuController = ({ navigate, setActiveMenu }) => {
   const handleMenu = (menu) => {
      setActiveMenu(menu)
      if (menu === "customer") {
         localStorage.setItem("activeMenu", "customer")
         navigate("/customer-management")
      }
      if (menu === "dashboard") {
         localStorage.setItem("activeMenu", "dashboard")
         navigate("/dashboard")
      }
      if (menu === "out-of-town-destination") {
         localStorage.setItem("activeMenu", "out-of-town-destination")
         navigate("/out-of-town-destination")
      }
      if (menu === "calendar") {
         setActiveMenu("calendar")
         localStorage.setItem("activeMenu", "calendar")
         navigate("/calendar")
      }
      if (menu === "live") {
         setActiveMenu("calendar")
         localStorage.setItem("activeMenu", "calendar")
         navigate("/live")
      }
      if (menu === "cars") {
         setActiveMenu("calendar")
         localStorage.setItem("activeMenu", "calendar")
         navigate("/cars")
      }
      if (menu === "car-rates") {
         setActiveMenu("car-rates")
         localStorage.setItem("activeMenu", "cars")
         navigate("/car-rates")
      }

      if (menu === "monthly") {
         setActiveMenu("reports")
         localStorage.setItem("activeMenu", "reports")
         navigate("/monthly-sales-report")
      }
			if (menu === "reports") {
				setActiveMenu("reports")
				localStorage.setItem("activeMenu", "reports")
				navigate("/monthly-sales-report")
		 }
   }
   return { handleMenu }
}
export default AdminMenuController
