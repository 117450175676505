import React, { useEffect, useState, useContext } from "react"
import {
   Button,
   Card,
   Popconfirm,
   Space,
   Input,
   Typography,
   Image,
   Tag,
   Popover,
} from "antd"

import PagePagination from "../../../common/components/PagePagination"

import {
   BookFilled,
   DeleteFilled,
   EditFilled,
   UploadOutlined,
   InfoCircleOutlined,
} from "@ant-design/icons"
import Table from "../../../common/components/Table"

import AddCustomer from "../components/AddCustomer"
import CustomerBooked from "../components/CustomerBooked"
import FloatingButtonCustomerManagement from "../components/FloatingButtonCustomerManagement"
import CustomerManagementController from "../controllers/CustomerManagementController"
import UploadIDModal from "../components/UploadIDModal"

import { CustomerContext } from "../../../context/CustomerContext"
import { OPEN_UPLOAD_MODAL } from "../../../context/reducer/CustomerReducer"

import { isEmpty } from "../../../lib/utils"
import { customerCategoryObject } from "../../../data/data"
import { GlobalContext } from "../../../context/GlobalContext"
import { USERTYPE } from "../../../data/enum"

const { Search } = Input

const CustomerManagementPage = () => {
   const [state, setstate] = useState({
      isViewCustomerBookedOpen: false,
      isAddCustomerModalOpen: false,
      addOrUpdate: "Add",
      isLoading: false,
      updatedCounter: 0,
   })
   const [customer, setCustomer] = useState({})

   const [pagination, setPagination] = useState({
      count: 0,
      perPage: 50,
      currentPage: 1,
   })

   const { customers, dispatch, isUploadIDOpen } = useContext(CustomerContext)
   const { user } = useContext(GlobalContext)

   const {
      handleArchiveCustomer,
      getCustomers,
      handleAddCustomer,
      handleViewBooked,
      handleUpdateCustomer,
      handleRefreshData,
      handleSearch,
      onShowSizeChange,
      onChangePagination,
   } = CustomerManagementController({
      setstate,
      setCustomer,
      customers,
      customer,
      setPagination,
      pagination,
      dispatch,
   })

   const columns = [
      {
         title: "ID",
         dataIndex: "UUID",
         key: state.updatedCounter,
         render: (UUID, record) => {
            const url = `/images/${UUID}-firstFront.jpeg`
            const noImageUrl = `/images/no-image.jpeg`
            return <Image width={150} src={url} fallback={noImageUrl} />
         },
      },
      {
         title: "Name",
         dataIndex: "firstName",
         key: "firstName",
         width: 300,
         render: (firstName, record) => {
            const middleName = isEmpty(record?.middleName)
               ? ""
               : record?.middleName?.charAt(0)?.toUpperCase() + "."
            return (
               <Typography
                  style={{
                     color:
                        !isEmpty(record.offense) || record.category === 3
                           ? "red"
                           : "black",
                  }}
               >
                  {firstName} {middleName} &nbsp;
                  {record.lastName}
               </Typography>
            )
         },
      },
      {
         title: "Address",
         dataIndex: "address",
         key: "address",
         width: 200,
      },
      {
         title: "FaceBook Acct",
         dataIndex: "facebook",
         key: "facebook",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Contact No.",
         key: "contactno",
         dataIndex: "customer",
         width: 150,
         render: (_, { contactNo1 = "", contactNo2 = "" }) => (
            <>
               1. {contactNo1}
               <br />
               2. {contactNo2}
            </>
         ),
      },
      {
         title: "Category",
         key: "category",
         dataIndex: "category",
         render: (category) => {
            let color = "geekblue"
            if (category === 1) {
               color = "geekblue"
            }
            if (category === 2) {
               color = "#E5B80B"
            }
            if (category === 3) {
               color = "volcano"
            }
            return (
               <Tag color={color}>
                  {customerCategoryObject[category]?.toUpperCase()}{" "}
               </Tag>
            )
         },
      },
      {
         title: "Note",
         key: "offense",
         dataIndex: "offense",
         render: (offense) => {
            if (isEmpty(offense)) {
               return ""
            } else {
               return (
                  <Popover content={offense} trigger='click'>
                     <InfoCircleOutlined /> Notes
                  </Popover>
               )
            }
         },
      },
      // {
      //    title: "Company/Agency",
      //    key: "company",
      //    dataIndex: "company",
      // },
      // {
      //    title: "Driver's license No.",
      //    dataIndex: "driverLicenseNo",
      //    key: "driverLicenseNo",
      // },
      {
         title: "Action",
         key: "action",
         render: (_, record, index) => (
            <Space size='middle'>
               <Button
                  onClick={() => handleUpdateCustomer(record, index)}
                  shape='circle'
                  icon={<EditFilled style={{ color: "#DDA15E" }} />}
               />
               <Button
                  onClick={() => handleViewBooked(record)}
                  type='primary'
                  shape='circle'
                  icon={<BookFilled />}
               />
               <Button
                  onClick={() =>
                     dispatch({
                        type: OPEN_UPLOAD_MODAL,
                        isUploadIDOpen: true,
                        customerID: record.UUID,
                     })
                  }
                  type='primary'
                  shape='circle'
                  icon={<UploadOutlined />}
               />
               {user.type === USERTYPE.admin && (
                  <Popconfirm
                     description='Are you sure you want to archive this user?'
                     onCancel={null}
                     onConfirm={() => handleArchiveCustomer(record, index)}
                     okText='Yes'
                     cancelText='No'
                     key='Popconfirm'
                  >
                     <Button
                        type='default'
                        shape='circle'
                        icon={<DeleteFilled />}
                        danger
                     />
                  </Popconfirm>
               )}
            </Space>
         ),
      },
   ]

   useEffect(() => {
      getCustomers()

      //eslint-disable-next-line
   }, [])

   return (
      <>
         <Card className='sd-sm'>
            <div>
               <div style={{ float: "left", marginBottom: "1vw" }}>
                  <Search
                     style={{ marginTop: "0.5vw", marginBottom: "0.5vw" }}
                     placeholder='Name / Last name / FB'
                     allowClear
                     enterButton='Search'
                     onSearch={handleSearch}
                  />
               </div>
               <div style={{ float: "right" }}>
                  <PagePagination
                     pagination={pagination}
                     onChangePagination={onChangePagination}
                     onShowSizeChange={onShowSizeChange}
                  />
               </div>
            </div>
            <Table
               pagination={false}
               columns={columns}
               dataSource={customers}
               isLoading={state.isLoading}
            />
         </Card>
         <CustomerBooked
            isViewCustomerBookedOpen={state.isViewCustomerBookedOpen}
            setCustomerManagementState={setstate}
         />
         <AddCustomer
            customers={customers}
            customer={customer}
            setCustomer={setCustomer}
            customerManagementState={state}
            customerManagementSetstate={setstate}
         />
         {isUploadIDOpen ? <UploadIDModal setstate={setstate} /> : ""}
         <FloatingButtonCustomerManagement
            handleAddCustomer={handleAddCustomer}
            handleRefreshData={handleRefreshData}
         />
      </>
   )
}

export default CustomerManagementPage
