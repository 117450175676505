import React, { useReducer } from 'react'

import { ADD } from '../data/magicString'
import { OOTDReducer } from './reducer/OOTDReducer'

const initialState = {
   isLoading: false,
   isAddUpdateModalOpen: false,
	 addOrUpdate: ADD,
	 ootd: {},
	 ootdIndex: -1
}

export const OOTDContext = React.createContext()

export const OOTDProvider = (props) => {
   const [carState, dispatch] = useReducer(
      OOTDReducer,
      initialState
   )

   return (
      <OOTDContext.Provider
         value={{
            ...carState,
            dispatch,
         }}
      >
         {props.children}
      </OOTDContext.Provider>
   )
}
