import { Form, Input, InputNumber, message } from "antd"
import React, { useContext, useEffect, useRef, useState } from "react"
import { EditableContext } from "./CarExpensesTable"

export default function EditableCell({
   title,
   editable,
   children,
   dataIndex,
   record,
   handleSave,
   ...restProps
}) {
   const [editing, setEditing] = useState(false)
   const inputRef = useRef(null)
   const form = useContext(EditableContext)
   useEffect(() => {
      if (editing) {
         inputRef.current.focus()
      }
   }, [editing])
   const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({
         [dataIndex]: record[dataIndex],
      })
   }
   const save = async () => {
      try {
         const values = await form.validateFields()
         toggleEdit()
         handleSave({
            ...record,
            ...values,
         })
      } catch (errInfo) {
				message.error(errInfo)
      }
   }
   let childNode = children
   if (editable) {
      if (editing && dataIndex !== "description") {
         childNode = (
            <Form.Item
               style={{
                  margin: 0,
               }}
               name={dataIndex}
            >
               <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
         )
      } else if (editing && dataIndex === "description") {
         childNode = (
            <Form.Item
               style={{
                  margin: 0,
               }}
               name={dataIndex}
            >
               <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
         )
      } else {
         childNode = (
            <div
               className='editable-cell-value-wrap'
               style={{
                  paddingRight: 24,
               }}
               onClick={toggleEdit}
            >
               {children}
            </div>
         )
      }
   }
   return <td {...restProps}>{childNode}</td>
}
