import { Dropdown, Col, Button, Space } from 'antd'
import React from 'react'
import {
	FundFilled,
	DownOutlined,
	CopyFilled
} from '@ant-design/icons'

const ReportsDropdown = ({handleMenu, activeMenu}) => {

	const items = [
		{
			key: 'Monthly',
			label: (
				<Button
					className='btn-link-black'
					onClick={() => handleMenu('monthly')}
				>
					<FundFilled  />   Monthly inventory report
				</Button>
			),
		}
	]

	return (
		<Col
				onClick={() => handleMenu('reports')}
				className={activeMenu === 'reports' ? 'active-item' : ''}
			>
				<div>
					<Dropdown
						menu={{
							items,
						}}
					>
						<Space>
							<Button className='btn-link'>
								<FundFilled /> Reports &nbsp;
								<DownOutlined />
							</Button>
						</Space>
					</Dropdown>
				</div>
			</Col>
	)
}

export default ReportsDropdown