import { message } from "antd"
import SERVER from "../../../lib/serverAxios"
import {
   ARCHIVE_CUSTOMER,
   SET_CUSTOMER,
   SET_CUSTOMERS,
	 SET_CUSTOMER_BOOKS,
} from "../../../context/reducer/CustomerReducer"

const CustomerManagementController = ({
   setstate,
   setCustomer,
   setPagination,
   pagination,
   dispatch,
}) => {
   const handleViewBooked = async (record) => {
		dispatch({type: SET_CUSTOMER, customer: record})
		const { data: {bookReservations} } = await SERVER.get(`/api/customer/book?id=${record.id}`)
		dispatch({type: SET_CUSTOMER_BOOKS, customerBooks: bookReservations})

      setstate((prevState) => ({
         ...prevState,
         isViewCustomerBookedOpen: true,
      }))
   }

   const handleUpdateCustomer = (record, index) => {
      try {
         setstate((prevState) => ({
            ...prevState,
            isAddCustomerModalOpen: true,
            addOrUpdate: "Update",
         }))

         setCustomer({ ...record, index })
      } catch (error) {
         message.error("Internal Server Error")
      }
   }

   const handleRefreshData = () => {
      setstate((prevState) => ({
         ...prevState,
         isLoading: true,
      }))
      setTimeout(() => {
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      }, 1000)
   }

   const handleAddCustomer = () => {
      setCustomer({})
      setstate((prevState) => ({
         ...prevState,
         isAddCustomerModalOpen: true,
         addOrUpdate: "Add",
      }))
   }

   const handleArchiveCustomer = async (record, index) => {
      try {
         setstate((prevState) => ({
            ...prevState,
            isLoading: true,
         }))

         await SERVER.delete(`/api/customer?id=${record?.id}`)

         dispatch({ type: ARCHIVE_CUSTOMER, index })
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      } catch (error) {
         message.error("Internal Server Error")
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      }
   }
   const handleSearch = async (value) => {
      try {
         setstate((prevState) => ({
            ...prevState,
            isLoading: true,
         }))
         const { data } = await SERVER.get(
            `/api/customer/search?fullname=${value}`
         )
         dispatch({ type: SET_CUSTOMERS, customers: data?.customers })
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      } catch (error) {
         console.log("!!!!!!!! ~ error:", error)
      }
   }

   const getCustomers = async (current = null, size = null) => {
      try {
         setstate((prevState) => ({
            ...prevState,
            isLoading: true,
         }))
         const { currentPage, perPage } = pagination
         const _currentPage = current ?? currentPage
         const _perPage = size ?? perPage
         const { data } = await SERVER.get(
            `/api/customer/filter?page=${_currentPage}&perPage=${_perPage}`
         )
         dispatch({ type: SET_CUSTOMERS, customers: data?.customers?.rows })
         setPagination((prevState) => ({
            ...prevState,
            count: data?.customers?.count,
         }))
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      } catch (error) {
         message.error("Error")
         setstate((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      }
   }

   const onShowSizeChange = (current, size) => {
      getCustomers(current, size)
			setPagination((prevState) => ({
				...prevState,
				perPage: size,
				currentPage: current,
			}))
   }
   const onChangePagination = (page, pageSize) => {
		setPagination((prevState) => ({
			...prevState,
			perPage: pageSize,
      currentPage: page,
		}))
      getCustomers(page, pageSize)
   }

   return {
      onShowSizeChange,
      onChangePagination,
      getCustomers,
      handleAddCustomer,
      handleViewBooked,
      handleUpdateCustomer,
      handleRefreshData,
      handleArchiveCustomer,
      handleSearch,
   }
}

export default CustomerManagementController
