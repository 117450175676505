import { Affix, Pagination } from "antd"
import React from "react"
import { totalPageNum } from "../../lib/utils"

const PagePagination = ({pagination, onChangePagination, onShowSizeChange}) => {
    return (
        <Affix offsetTop={60}>
            <div
                style={{
                    float: "right",
                    backgroundColor: "white",
                }}
               

            >
                <Pagination
                    pageSizeOptions={[10, 20, 50, 100]}
                    showSizeChanger
                    onChange={onChangePagination}
										onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    current={pagination.currentPage}
                    defaultPageSize={pagination.perPage}
                    total={pagination.count}
                    showQuickJumper={
                        totalPageNum(pagination.count, pagination.perPage) > 10
                    }
                />
            </div>
        </Affix>
    )
}

export default PagePagination
