import React, { useContext } from "react"
import { convertConvertToPHCurrency, isEmpty, rowkey } from "../../../lib/utils"
import dayjs from "dayjs"
import { CarContext } from "../../../context/CarContext"
import { Button, Space, Table, Typography } from "antd"
import { SaveOutlined } from "@ant-design/icons"
import EditableCell from "./EditableCell"
import EditableRow from "./EditableRow"
import CarExpensesTableController from "../controller/CarExpensesTableController"
import { SET_UNIT_EXPENSES } from "../../../context/reducer/CarReducer"

const { Title } = Typography

export const EditableContext = React.createContext(null)

export default function CarExpensesTable() {
   const { dispatch, unitExpenses, isLoading } = useContext(CarContext)

   const { handleUpdateExpense } = CarExpensesTableController({
      dispatch,
      unitExpenses,
   })

   const defaultColumns = [
      {
         title: "Date",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (createdAt) => {
            if (isEmpty(createdAt)) return ""
            return <span> {dayjs(createdAt)?.format("MM/DD/YYYY")} </span>
         },
      },
      {
         title: "Description",
         key: "description",
         dataIndex: "description",
         editable: true,
         render: (description) => {
            if (isEmpty(description)) return <span>&nbsp;</span>
            return <span>{description}</span>
         },
      },
      {
         title: "Car Wash",
         key: "carWash",
         dataIndex: "carWash",
         align: "right",
         editable: true,

         render: (maintenance) => {
            return (
               <span>
                  {convertConvertToPHCurrency(parseFloat(maintenance) || 0)}{" "}
               </span>
            )
         },
      },
      {
         title: "Maintenance",
         key: "maintenance",
         dataIndex: "maintenance",
         align: "right",
         editable: true,

         render: (maintenance) => {
            return (
               <span>
                  {convertConvertToPHCurrency(parseFloat(maintenance) || 0)}{" "}
               </span>
            )
         },
      },
      {
         title: "Pickup / Deliver",
         dataIndex: "pickupOrDelivery",
         key: "pickupOrDelivery",
         align: "right",
         editable: true,

         render: (pickupOrDelivery, _, index) => {
            return (
               <span>
                  {convertConvertToPHCurrency(
                     parseFloat(pickupOrDelivery) || 0
                  )}{" "}
               </span>
            )
         },
      },
      {
         title: "Referral",
         dataIndex: "referral",
         key: "referral",
         align: "right",
         editable: true,

         render: (referral, _, index) => {
            return (
               <span>
                  {convertConvertToPHCurrency(parseFloat(referral) || 0)}{" "}
               </span>
            )
         },
      },
      {
         title: "Updated By",
         dataIndex: "user",
         key: "updatedBy",
         render: (user, record) => {
            const middleName = isEmpty(user?.middleName)
               ? ""
               : user?.middleName?.charAt(0)?.toUpperCase() + "."
            return (
               <Typography>
                  {user?.firstName} {middleName} &nbsp;
                  {user?.lastName}
               </Typography>
            )
         },
      },
      {
         title: "Updated At",
         dataIndex: "updatedAt",
         key: "updatedAt",
         render: (updatedAt) => {
            if (isEmpty(updatedAt)) return ""
            return (
               <span> {dayjs(updatedAt)?.format("MM/DD/YYYY hh:mm A")} </span>
            )
         },
      },
      {
         title: "Action",
         key: "action",
         align: "right",
         render: (_, record, index) => (
            <Space size='middle'>
               <Button
                  onClick={() => handleUpdateExpense(record, index)}
                  shape='circle'
                  icon={<SaveOutlined style={{ color: "#DDA15E" }} />}
               />
            </Space>
         ),
      },
   ]

   const handleSave = (row) => {
      const newData = [...unitExpenses]
      const index = newData.findIndex((item) => row.id === item.id)
      const item = newData[index]
      newData.splice(index, 1, {
         ...item,
         ...row,
      })
      dispatch({ type: SET_UNIT_EXPENSES, unitExpenses: newData })
   }
   const components = {
      body: {
         row: EditableRow,
         cell: EditableCell,
      },
   }
   const columns = defaultColumns.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
         }),
      }
   })

   const defaultRowClassName = (record, index) => {
      return index % 2 === 0
         ? "table-row-light editable-row"
         : "table-row-dark editable-row"
   }

   return (
      <div>
         <Title level={5}> Unit Expenses</Title>

         <Table
            rowKey={rowkey}
            components={components}
            isLoading={isLoading}
            dataSource={unitExpenses}
            columns={columns}
            rowClassName={defaultRowClassName}
            pagination={false}
         />
      </div>
   )
}
