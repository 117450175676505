export const TOGGLE_ADD_UPDATE_OOTD_MODAL = "TOGGLE_ADD_UPDATE_OOTD_MODAL"
export const SET_OOTD = "SET_OOTD"
export const OPEN_UPDATE = "OPEN_UPDATE"
export const SET_LOADING = "SET_LOADING"




export const OOTDReducer = (state, action) => {
   switch (action.type) {
		case SET_LOADING:
			return {
				 ...state,
				 isLoading: action.isLoading,
			}
		case OPEN_UPDATE:
			return {
				 ...state,
				 ootd: action.ootd,
				 ootdIndex: action.ootdIndex,
			}
      case SET_OOTD:
         return {
            ...state,
            ootd: action.ootd,
						isAddUpdateModalOpen: false
         }
      case TOGGLE_ADD_UPDATE_OOTD_MODAL:
         return {
            ...state,
						addOrUpdate: action?.addOrUpdate || 'Add',
            isAddUpdateModalOpen: action.isAddUpdateModalOpen,
         }
      default:
         return state
   }
}
