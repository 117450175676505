import { message } from "antd"
import SERVER, { handleError } from "../../../lib/serverAxios"
import {
   INSERT_CUSTOMER,
   UPDATE_CUSTOMER,
} from "../../../context/reducer/CustomerReducer"

const AddCustomerController = ({
   customer,
   addOrUpdate,
   customerManagementSetstate,
   dispatch,
   form,
   setCustomer,
}) => {
   const addCustomer = async () => {
      try {
         const customerFieldsValue = await form.getFieldsValue()
         const { data } = await SERVER.post(
            "/api/customer",
            customerFieldsValue
         )
         dispatch({ type: INSERT_CUSTOMER, customer: data.customer })
         message.success(data.msg || "Customer successfully created.")
         setCustomer(null)
				 form.resetFields()
      } catch (error) {
         handleError(error) 
      } finally {
         customerManagementSetstate((prevState) => ({
            ...prevState,
            isAddCustomerModalOpen: false,
         }))
      }
   }

   const updateCustomer = async () => {
      try {
         const customerFieldsValue = form.getFieldsValue()
         const _customer = { ...customer, ...customerFieldsValue }
         await SERVER.put("/api/customer", _customer)
         dispatch({ type: UPDATE_CUSTOMER, _customer })

         customerManagementSetstate((prevState) => ({
            ...prevState,
            isAddCustomerModalOpen: false,
         }))
         setCustomer({})
         message.success("Customer updated successfully.")
      } catch (error) {
          handleError(error)
      }
   }

   const handleOk = () => {
      if (addOrUpdate === "Add") {
         addCustomer()
      } else {
         updateCustomer()
      }
   }

   const handleCancel = () => {
      customerManagementSetstate((prevState) => ({
         ...prevState,
         isAddCustomerModalOpen: false,
      }))
      setCustomer(null)
      form.resetFields()
   }

   return { handleOk, handleCancel }
}

export default AddCustomerController
