import { Col, DatePicker, Form, Input } from "antd"
import React, { Fragment } from "react"
import dayjs from "dayjs"

export default function CheckedAndRecievedBy() {
   const disabledTo = (current) => {
      return current > dayjs().endOf("day")
   }

   return (
      <Fragment>
         <Col span={12}>
            <Form.Item
               label='Date'
               name='date'
               style={{
                  width: "100%",
               }}
               rules={[
                  {
                     required: true,
                     message: "Date is required!",
                  },
               ]}
            >
               <DatePicker
                  disabledDate={disabledTo}
                  format='MM-DD-YYYY HH:mm'
                  showTime={true}
               />
            </Form.Item>
            <Form.Item
               name='checkedBy'
               label='Checked by'
               style={{
                  width: "80%",
               }}
               rules={[
                  {
                     required: true,
                     message: "Checked by is required!",
                  },
               ]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               name='recievedBy'
               label='Recieved by'
               style={{
                  width: "80%",
               }}
               rules={[
                  {
                     required: true,
                     message: "Recieved by is required!",
                  },
               ]}
            >
               <Input />
            </Form.Item>
         </Col>
      </Fragment>
   )
}
