import { message } from "antd"
import { getBase64 } from "../../../lib/utils"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { baseURL } from "../../../lib/serverAxios"

const UploadAndDisplayImageController = ({
   loading,
   setLoading,
   setImageUrl,
}) => {

   const handleChange = ({ info, id }) => {
      if (info.file.status === "uploading") {
         setLoading(true)
         return
      }
      if (info.file.status === "done") {
       //  const imgUrl = info?.file?.response?.url?.replace("public", "") || ""
         getBase64(info.file.originFileObj, (url) => {
            console.log("first")
            setLoading(false)
            setImageUrl((prevState) => ({
               ...prevState,
               [id]: url,
            }))
         })
      }
   }

   const uploadButton = (text) => {
      return (
         <button
            style={{
               border: 0,
               background: "none",
            }}
            type='button'
         >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
               style={{
                  marginTop: 8,
               }}
            >
               Upload ID ({text})
            </div>
         </button>
      )
   }

   const beforeUpload = (file) => {
      const isJpgOrPng = file.type === "image/jpeg"
      if (!isJpgOrPng) {
         message.error("You can only upload JPEG file!")
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
         message.error("Image must smaller than 2MB!")
      }
      return isJpgOrPng && isLt2M
   }

   const url = (image) => {
      const uri = `${baseURL}/api/customer/upload-image/${image}`
      return uri
   }

   return { url, uploadButton, handleChange, beforeUpload }
}

export default UploadAndDisplayImageController
