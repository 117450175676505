import React, { useState, useEffect, useContext } from "react"
import dayjs from "dayjs"
import Table from "../../../common/components/Table"
import { MonthlySalesReportContext } from "../../../context/MonthlySalesReportContext"
import {
   computeDurationForReport,
   convertConvertToPHCurrency,
   isEmpty,
} from "../../../lib/utils"
import {
   INITIALIAZE_COMPONENENT,
   SET_UNIT_TOTAL_GROSS,
} from "../../../context/reducer/MonthlySalesReportReducer"
import {
   getTotalMinutes,
} from "../../../lib/calculation"
import CutOffTableController from "../controllers/CutOffTableController"

function MonthTable() {
   const [tableData, setTableData] = useState([])

   const {
      selectedDate,
      cutoff,
      selectedCar,
      bookReservations,
      dispatch,
      isLoading,
   } = useContext(MonthlySalesReportContext)

   const { filterBookReservationPerDay } = CutOffTableController({
      bookReservations,
   })

   const processBooks = ({
      currentDate,
      booksCurrentDate,
      startDate,
      endDateCalendar,
      previousMileAge,
   }) => {
      let totalAmount = 0,
         totalAmountSameDay = 0,
         totalDiscount = 0,
         totalDF = 0,
         totalRentalAmount = 0,
         mileage = null

      const processedBooks = []
      if (isEmpty(booksCurrentDate)) {
         processedBooks.push({
            key: currentDate?.format("YYYY-MM-DD"),
            date: currentDate?.format("MM/DD/YYYY"),
            totalAmount: convertConvertToPHCurrency(0),
            status: "Vacant",
         })
         mileage = previousMileAge
      } else {
         booksCurrentDate?.forEach((booked, index) => {
            // ******* VARIABLES
            let remainingAmount = 0
            const endDate = isEmpty(booked?.dateReturned)
               ? booked?.end
               : booked?.dateReturned

            const isDateReleasePCutoff =
						dayjs(startDate.format('MM/DD/YYYY')).diff(dayjs(booked?.dateReleased).format('MM/DD/YYYY'), "day") > 0
					
						const isDateReturnedNextCutoff =  dayjs(endDate).diff(endDateCalendar.format('MM/DD/YYYY'), "day") > 0
					
            const isDateReturnedPCutoff = currentDate.isSame(
               endDateCalendar,
               "day"
            ) && isDateReturnedNextCutoff
            if (isDateReturnedPCutoff) {
               const totalHours = getTotalMinutes({
                  start: booked?.dateReleased,
                  end: endDate,
               })
               const consumeHrs = getTotalMinutes({
                  start: booked?.dateReleased,
                  end: dayjs(booked?.dateReleased).endOf("day"),
               })
               const percentage = (consumeHrs / totalHours) * 100
               remainingAmount = Math.floor(
                  (percentage / 100) * booked?.payment?.rentalFee
               )
            }

            if (isDateReleasePCutoff) {
               const consumeHrs = getTotalMinutes({
                  start: startDate.startOf("day"),
                  end: booked?.dateReturned,
               })
               const totalHours = getTotalMinutes({
                  start: booked?.dateReleased,
                  end: booked?.dateReturned,
               })
               //		const totalHoursPrev = getTotalMinutes({ start: booked?.dateReleased, end: dayjs(booked?.dateReleased).endOf("day")})
               const percentage = (consumeHrs / totalHours) * 100
               remainingAmount = Math.floor(
                  (percentage / 100) * booked?.payment?.rentalFee
               )
            }

            // ((((((((((((((( ****** payments ****** )))))))))))))))
            const rentalAmount =
						isDateReleasePCutoff || isDateReturnedPCutoff
						? remainingAmount
						: parseFloat(booked?.payment?.rentalFee || 0)

            const { payment } = booked
            const discount = isDateReturnedPCutoff
               ? 0
               : parseFloat(payment?.discount || 0)
            const destinationFee = isDateReturnedPCutoff
               ? 0
               : parseFloat(payment?.destinationFee || 0)

            totalDiscount = discount + totalDiscount
            totalDF = destinationFee + totalDF
            totalRentalAmount = rentalAmount + totalRentalAmount

            const totalHoursDaysTxt = computeDurationForReport({
               start: booked?.dateReleased,
               end: endDate,
            })

            const dateReturnedOrExpectedDateReturn =
               dayjs(endDate)?.format("MM/DD hh:mm A")

            totalAmount = rentalAmount + destinationFee - discount
            totalAmountSameDay = totalAmountSameDay + totalAmount

						const isOngoing = currentDate.isSame(
							endDateCalendar,
							"day"
					 ) && (isEmpty(booked?.dateReleased))

            processedBooks.push({
               key: booked?.id,
               date: index === 0 ? currentDate?.format("MM/DD/YYYY") : "", // Format the date as MM/DD/YYYY
               status: isEmpty(booked?.customerId) ? "Owner" : "Rented",
               mileAge: {
                  start: booked?.mileage?.start,
                  end: booked?.mileage?.end,
                  consumption: booked?.mileage?.end - booked?.mileage?.start,
                  farMileAge:
                     booked?.mileage?.start -
                        (previousMileAge || booked?.mileage?.start) >
                     5
                        ? true
                        : false,
               },
               rental: {
                  duration: isDateReleasePCutoff
                     ? "Remaining"
                     : isOngoing
                     ? "Ongoing"
                     : totalHoursDaysTxt,
                  release: dayjs(booked?.dateReleased)?.format("MM/DD hh:mm A"),
                  expectedDateReturn: isEmpty(booked?.dateReturned),
                  returned: dateReturnedOrExpectedDateReturn,
               },
               destination: booked?.ootd?.destination || "",
               rentalAmount: convertConvertToPHCurrency(rentalAmount),
               destinationRate: destinationFee,
               discount: discount,
               totalAmount: convertConvertToPHCurrency(totalAmount || 0),
            })

            mileage = booked?.mileage?.end // To compare is mileAge previous end far from mileAge start current
            previousMileAge = mileage // for more than 1 booked within a day
         })
      }

      return [
         processedBooks,
         totalAmountSameDay,
         totalDiscount,
         totalDF,
         totalRentalAmount,
         mileage,
      ]
   }

   const generateDateData = async (startDate, endDate) => {
      const data = []
      let currentDate = startDate
      let grandTotalAmount = 0,
         subTotalDiscount = 0,
         subTotalDF = 0,
         subTotalRentalAmount = 0,
         previousMileAge = null

      // Populate data array with dates
      while (
         currentDate.isBefore(endDate) ||
         currentDate.isSame(endDate, "day")
      ) {
         const booksCurrentDate = await filterBookReservationPerDay(
            currentDate,
            startDate
         )

         // Call the function and push the results into the data array
         const [
            processedBooks,
            totalAmountSameDay,
            totalDiscount,
            totalDF,
            totalRentalAmount,
            mileage,
         ] = processBooks({
            currentDate,
            booksCurrentDate,
            startDate,
            endDateCalendar: endDate,
            previousMileAge,
         })
         grandTotalAmount = grandTotalAmount + totalAmountSameDay
         subTotalDiscount = subTotalDiscount + totalDiscount
         subTotalDF = subTotalDF + totalDF
         subTotalRentalAmount = subTotalRentalAmount + totalRentalAmount

         data.push(...processedBooks)

         currentDate = currentDate.add(1, "day")
         previousMileAge = mileage
      } // **********  end of WHILE LOOP **********
      data.push({
         totalAmount: convertConvertToPHCurrency(grandTotalAmount || 0),
         discount: subTotalDiscount,
         destinationRate: subTotalDF,
         rentalAmount: convertConvertToPHCurrency(subTotalRentalAmount || 0),
      })
      dispatch({ type: SET_UNIT_TOTAL_GROSS, unitTotalGross: grandTotalAmount })

      return data
   }

   const initializedComponent = async () => {
      let startDate
      let endDate

      const currentMonth = selectedDate.month() + 1
      const lastDateOfMonth = selectedDate.endOf("month").date()
      const isEndCutOffEndofTheMonth =
         cutoff >= lastDateOfMonth || cutoff === 30
      if (isEndCutOffEndofTheMonth) {
         let day = cutoff
         if (currentMonth === 2 || cutoff === 30) {
            day = selectedDate.endOf("month").date()
         }
         startDate = selectedDate.set("date", 1)
         endDate = selectedDate.date(day)
      }
      if (!isEndCutOffEndofTheMonth) {
         startDate = selectedDate
            .date(cutoff)
            .subtract(1, "month")
            .add(1, "day")
         endDate = selectedDate.date(cutoff)
      }

      dispatch({ type: INITIALIAZE_COMPONENENT, endDate, startDate })

      const data = await generateDateData(startDate, endDate)
      setTableData(data)
   }

   useEffect(() => {
      initializedComponent()
      //eslint-disable-next-line
   }, [bookReservations, selectedCar])

   // Define columns for the Ant Design Table
   const columns = [
      {
         title: "Date",
         dataIndex: "date",
         key: "date",
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         align: "center",
         render: (status, record, index) => ({
            children: status,
            props: {
               className: "custom-cell",
               style: {
                  backgroundColor:
                     status === "Vacant"
                        ? "#590900"
                        : isEmpty(status)
                        ? ""
                        : "#606C38",
                  color: "white",
               },
            },
         }),
      },
      {
         title: "MileAge",
         children: [
            {
               title: "Start",
               dataIndex: "mileAge",
               key: "start",
               align: "center",
               render: (mileAge, record) => {
                  if (mileAge?.farMileAge)
                     return (
                        <span style={{ color: "red" }}> {mileAge?.start}</span>
                     )
                  return record?.mileAge?.start
               },
            },
            {
               title: "End",
               dataIndex: "end",
               key: "end",
               align: "center",

               render: (mileage, record) => {
                  return record?.mileAge?.end
               },
            },
            {
               title: "Consumption",
               dataIndex: "mileAge",
               key: "consumption",
               align: "center",

               render: (mileAge, record) => {
                  const consumption = mileAge?.consumption
                  return (
                     <div
                        style={{
                           backgroundColor: consumption < 0 ? "#DDA15E" : "",
                        }}
                     >
                        {consumption < 0 ? "Ongoing" : consumption}
                     </div>
                  )
               },
            },
         ],
      },
      {
         title: "Rental",
         children: [
            {
               title: "Duration",
               dataIndex: "rental",
               key: "duration",
               align: "center",
               render: (rental) => {
                  const remaingOrOngoingDuration =
                     rental?.duration === "Remaining" ||
                     rental?.duration === "Ongoing"

                  return (
                     <div
                        style={{
                           backgroundColor: remaingOrOngoingDuration
                              ? "#DDA15E"
                              : "",
                        }}
                     >
                        {rental?.duration}
                     </div>
                  )
               },
            },
            {
               title: "Release",
               dataIndex: "rental",
               key: "release",
               align: "center",

               render: (rental) => {
                  const remaingOrOngoingDuration =
                     rental?.duration === "Remaining" ||
                     rental?.duration === "Ongoing"

                  if (remaingOrOngoingDuration) {
                     return (
                        <div
                           style={{
                              backgroundColor: "#DDA15E",
                           }}
                        >
                           {rental?.release}
                        </div>
                     )
                  } else {
                     return <div>{rental?.release}</div>
                  }
               },
            },
            {
               title: "Returned",
               dataIndex: "rental",
               key: "returned",
               align: "center",
               render: (rental) => {
                  const remaingOrOngoingDuration =
                     rental?.duration === "Remaining" ||
                     rental?.duration === "Ongoing"

                  if (remaingOrOngoingDuration) {
                     return (
                        <div
                           style={{
                              backgroundColor: "#DDA15E",
                           }}
                        >
                           {rental?.returned}
                        </div>
                     )
                  } else {
                     return <div>{rental?.returned}</div>
                  }
               },
            },
         ],
      },
      {
         title: "Destination",
         dataIndex: "destination",
         key: "destination",
      },
      {
         title: "Rental Amount",
         dataIndex: "rentalAmount",
         key: "rentalAmount",
         align: "right",
         render: (rentalAmount, record, index) => {
            const footerTotal = tableData.length - 1 === index
            const remaingOrOngoingDuration =
               record?.rental?.duration === "Remaining" ||
               record?.rental?.duration === "Ongoing"

            if (footerTotal) {
               return <span style={{ fontWeight: "bold" }}>{rentalAmount}</span>
            } else if (remaingOrOngoingDuration) {
               return (
                  <div
                     style={{
                        backgroundColor: "#DDA15E",
                     }}
                  >
                     {rentalAmount}
                  </div>
               )
            } else {
               return <span>{rentalAmount}</span>
            }
         },
      },
      {
         title: "Destination Rate",
         dataIndex: "destinationRate",
         key: "destinationRate",
         align: "right",
         render: (destinationRate, _, index) => {
            const footerTotal = tableData.length - 1 === index
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(destinationRate || 0)}
                  </span>
               )
            } else {
               return (
                  <span>
                     {isEmpty(destinationRate)
                        ? ""
                        : convertConvertToPHCurrency(destinationRate)}
                  </span>
               )
            }
         },
      },
      {
         title: "Discount",
         dataIndex: "discount",
         key: "discount",
         align: "right",
         render: (discount, _, index) => {
            const footerTotal = tableData.length - 1 === index
            if (footerTotal) {
               return (
                  <span style={{ fontWeight: "bold" }}>
                     {convertConvertToPHCurrency(discount || 0)}
                  </span>
               )
            } else {
               return (
                  <span>
                     {isEmpty(discount)
                        ? ""
                        : convertConvertToPHCurrency(discount || 0)}
                  </span>
               )
            }
         },
      },
      {
         title: "Total Amount",
         dataIndex: "totalAmount",
         key: "totalAmount",
         align: "right",
         render: (totalAmount) => {
            return <span style={{ fontWeight: "bold" }}>{totalAmount}</span>
         },
      },
   ]

   return (
      <div className='to-print'>
         <Table
            isLoading={isLoading}
            className='table'
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered={true}
            style={{
               fontSize: "10px !important",
            }}
         />
      </div>
   )
}

export default MonthTable
