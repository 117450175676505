import SERVER, { handleError } from "../../../lib/serverAxios"

export const getBooksAndReservations = async (start, end) => {
   try {
      const {
         data: { bookReservations },
      } = await SERVER.get(`/api/books-reservation?start=${start}&end=${end}`)
      return [bookReservations, false]
   } catch (error) {
      let msg =
         error?.response?.data?.msg ??
         error?.message ??
         error ??
         "Internal Server Error"
      return [[], msg]
   }
}

export const getBooksAndReservationsReleaseForToday = async () => {
	try {
		 const {
				data: { bookReservations },
		 } = await SERVER.get(`/api/books-reservation/today-release`)
		 return [bookReservations, false]
	} catch (error) {
		 handleError(error)
		 return [[], true]
	}
}
export const getOverDueBooksAndReservations = async () => {
	try {
		 const {
				data: { bookReservations },
		 } = await SERVER.get(`/api/books-reservation/overdue`)
		 return [bookReservations, false]
	} catch (error) {
		 handleError(error)
		 return [[], true]
	}
}

