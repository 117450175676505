import React from 'react'
import { PlusCircleFilled, CloudSyncOutlined, LoadingOutlined } from '@ant-design/icons'
import { FloatButton } from 'antd'
import FloatingButtonCustom from '../../../common/components/FloatingButtonCustom'

const DashboardFloatBtn = ({ isLoading, handleRefreshData, setIsSelectCarModalOpen }) => {
   return (
      <FloatingButtonCustom>
				  <FloatButton
            style={styles.fabStyle}
            icon={<PlusCircleFilled style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={() => setIsSelectCarModalOpen(true)}
         />
         <FloatButton
            style={styles.fabStyleRefresh}
            icon={isLoading ? <LoadingOutlined style={styles.plusCircleIcon} />  :  <CloudSyncOutlined style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleRefreshData}
         />
      </FloatingButtonCustom>
   )
}

export default DashboardFloatBtn

const styles = {
   fabStyleRefresh: {
      height: 50,
      width: 50,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      paddingRight: '70px !important',
   },
	 fabStyle: {
		height: 50,
		width: 50,
		backgroundColor: 'rgba(89, 9, 0, 0)',
		paddingRight: '70px !important',
		marginBottom: '7vh',
 },
   plusCircleIcon: {
      overflow: 'visible !important',
      fontSize: '3em',
   },
}
