import React from "react"
import "./styles.css"

import { Layout } from "antd"
const { Header } = Layout

function HeaderContent() {
    return (
        <Header
            style={{
                background: '#590900 0% 0% no-repeat padding-box',
                position: "fixed",
                width: "100%",
                zIndex: 9,
                padding: "0px",
                height: `5%`,
                fontSize: "8px"
            }}
            className='sd-sm'
        >
        </Header>
    )
}

export default HeaderContent
