import { message } from "antd"
import SERVER, { handleError } from "./serverAxios"

export const getOOTDS = async () => {
   try {
      const { data: ootd } = await SERVER.get(`/api/ootd`)
      return ootd
   } catch (error) {
      const { msg } = handleError(error)
      message.error(msg)
   }
}

export const getCars = async (signal) => {
   const { data: cars } = await SERVER.get(`/api/cars`, { signal })
   return cars
}

export const getTypes = async () => {
   const { data: types } = await SERVER.get(`/api/types`)
   return types
}

export const getOwners = async () => {
   const { data: types } = await SERVER.get(`/api/owners`)
   return types
}

export const getBranches = async () => {
   try {
      const { data: branches } = await SERVER.get(`/api/branches`)
      return branches
   } catch (error) {
      handleError(error)
   }
}

export const getCustomers = async (current = null, size = null) => {
	const _currentPage = current ?? 1
	const _perPage = size ?? 50

	const { data } = await SERVER.get(
		`/api/customer/filter?page=${_currentPage}&perPage=${_perPage}`
 )
 return data
}

export const getUnitExpense = async ({selectedDate, car}) => {
	try {
		const uri = `/api/cars/unit-expense?selectedDateString=${selectedDate}&cutoff=${car?.cutoff}&carId=${car?.id}`
		 const { data: {unitExpense} } = await SERVER.get(uri)
		 return unitExpense
	} catch (error) {
		 handleError(error)
	}
}
