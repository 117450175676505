import React, { useContext, useEffect } from "react"

import { Drawer, Row, Form } from "antd"
import { CalendarContext } from "../../../context/CalendarContext"
import {
   TOGGLE_CD,
   TOGGLE_DRAWER,
} from "../../../context/reducer/CalendarReducer"
import { isEmpty } from "../../../lib/utils"

import PaymentsRow from "./PaymentsRow"

import dayjs from "dayjs"
import CheckListRow from "./CheckListRow"
import ChargeDamageRow from "./ChargeDamageRow"

import BookReservationDrawerController from "../controller/BookReservationDrawerController"
import isToday from "dayjs/plugin/isToday"
import BRDrawerExtra from "./BRDrawerExtra"
import { BOOKSTATE } from "../../../data/enum"
import InformationSection from "./InformationSection"
import DateSection from "./DateSection"
import CarHeader from "./CarHeader"
import ChargeDamageInfoRow from "./ChargeDamageInfoRow"
import ProcessedBy from "./ProcessedBy"
import { GlobalContext } from "../../../context/GlobalContext"

dayjs.extend(isToday)

export default function BookReservationDrawer() {
   const [form] = Form.useForm()

   const { isBookReservationDrawerOpen, dispatch, bookReservation, isLoading } =
      useContext(CalendarContext)

   const { user } = useContext(GlobalContext)
   const userFullName = user?.firstName + "" + user?.lastName

   const { car, start, dateReleased, state, id, customerID } = bookReservation

   const closeModal = () => {
      dispatch({ type: TOGGLE_DRAWER, isBookReservationDrawerOpen: false })
      dispatch({ type: TOGGLE_CD, isCDchecked: false })
   }

   const isStartDatePassed = dayjs().diff(dayjs(start), "day") > -1
   const isCustomerBooked = !isEmpty(customerID)

   const isForRecieve = !isEmpty(dateReleased)
   const isAlreadyReleased = state === BOOKSTATE.released
   const isBookCancelled = state === BOOKSTATE.cancelled
   const isFinished =
      state === BOOKSTATE.done ||
      state === BOOKSTATE.switched ||
      state === BOOKSTATE.voided ||
      state === BOOKSTATE.cancelled

   const {
      switchVehicle,
      releaseVehicle,
      returnVehicle,
      cancelledBookReservation,
      updateBook,
   } = BookReservationDrawerController({
      dispatch,
      form,
      id,
      bookReservation,
   })

   useEffect(() => {
      form.setFieldsValue({
         typeOfDamage: 1,
         rentalPT1: 1,
         orcr: true,
         tools: true,
         jack: true,
         spareTire: true,
         dirtyStained: false,
         stinkyOdor: false,
         dateRelease: dayjs(),
         dateReturn: isForRecieve ? dayjs() : null,
         recievedBy: userFullName,
         reservationFee: parseFloat(
            bookReservation?.payment?.reservationFee || 0
         ),
         rentalFee: parseFloat(bookReservation?.payment?.rentalFee || 0),
         destinationFee: parseFloat(
            bookReservation?.payment?.destinationFee || 0
         ),
         withDriverFee: parseFloat(
            bookReservation?.payment?.withDriverFee || 0
         ),
      })

      //eslint-disable-next-line
   }, [bookReservation])

   return (
      <Drawer
         closeIcon={isLoading ? false : true}
         maskClosable={isLoading ? false : true}
         title={null}
         onClose={closeModal}
         open={isBookReservationDrawerOpen}
         width={"65vw"}
         style={{ borderLeft: `3px solid ${car.color}` }}
         extra={
            <BRDrawerExtra
               switchVehicle={switchVehicle}
               form={form}
               cancelledBookReservation={cancelledBookReservation}
               releaseVehicle={releaseVehicle}
               returnVehicle={returnVehicle}
               updateBook={updateBook}
            />
         }
      >
         <Form
            id='form'
            name='basic'
            layout='horizontal'
            form={form}
            onFinish={() => null}
            onFinishFailed={null}
            autoComplete='off'
         >
            <Row gutter={[8, 12]}>
               <CarHeader car={car} />
               <InformationSection car={car} />
               <DateSection />
               {!!isCustomerBooked && <PaymentsRow form={form} />}
               {(isStartDatePassed || isAlreadyReleased) && !isBookCancelled ? (
                  <CheckListRow />
               ) : null}
               {(isStartDatePassed || isAlreadyReleased) && !isBookCancelled ? (
                  <ProcessedBy />
               ) : null}

               {isForRecieve && !isFinished && !!isCustomerBooked ? (
                  <ChargeDamageRow />
               ) : null}
               {isFinished && !!isCustomerBooked && <ChargeDamageInfoRow />}
            </Row>
         </Form>
      </Drawer>
   )
}
