import { FilePdfFilled } from "@ant-design/icons"
import { Button, Drawer, Space, Table, message } from "antd"
import React, { useContext, useEffect, useState } from "react"
import SERVER, { enviroment, handleError } from "../../../lib/serverAxios"
import dayjs from "dayjs"
import BookReservationTag from "../../../common/components/BookReservationTag"
import { isEmpty, rowkey } from "../../../lib/utils"
import { CustomerContext } from "../../../context/CustomerContext"
import CustomerBookedController from "../controllers/CustomerBookedController"
import { modeOfPaymentObject } from "../../../data/data"

import { LOCALHOST } from "../../../data/magicString"
import { GlobalContext } from "../../../context/GlobalContext"

const CustomerBooked = ({
   isViewCustomerBookedOpen,
   setCustomerManagementState,
   width = "96vw",
}) => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(false)

   const [messageApi] = message.useMessage()

   const { customer, customerID, customerBooks } = useContext(CustomerContext)
   const { selectedBranch} = useContext(GlobalContext)

	 
   useEffect(() => {
      if (isLoading) {
         messageApi.open({
            type: "loading",
            content: "Action in progress..",
            duration: 100,
         })
      } else {
         messageApi.destroy()
      }

      return () => {
         messageApi.destroy()
      }
      //eslint-disable-next-line
   }, [isLoading])

   const handleInvoice = async (record) => {
      try {
         setIsLoading(true)
				 message.loading("Generating a PDF please wait for a while.")
         const {
            data: { path },
         } = await SERVER.get(`/api/invoice/pdf?bookId=${record?.id}&selectedBranch=${selectedBranch.id}`)
         if (enviroment === LOCALHOST) {
            window.open(
               `http://localhost:1991/pdf/${path}`,
               "rel=noopener noreferrer"
            )
         } else {
					 setTimeout(function() {
						 const pdfWindow = window.open(`/pdf/${path}`, "rel=noopener noreferrer")
						pdfWindow.location.reload();
					}, 1500);
         }
         setIsLoading(false)
      } catch (error) {
         setIsLoading(false)

         handleError(error)
      }
   }

   const { initialize, handleOk, handleCancel, footerExpandedRow } =
      CustomerBookedController({
         setData,
         setCustomerManagementState,
         customerID,
      })

   const columns = [
      {
         title: "Car",
         dataIndex: "id",
         key: "id",
         render: (id, record) => {
            return (
               <div
                  style={{
                     backgroundColor: record?.car?.color,
                     color: "white",
                     padding: "2px",
                  }}
               >
                  &nbsp;{record?.car?.name?.toUpperCase()}
               </div>
            )
         },
      },
      {
         title: "Released",
         dataIndex: "dateReleased",
         key: "dateReleased",
         render: (dateReleased, record) => {
            if (isEmpty(dateReleased))
               return (
                  <div>
                     <span className='nti'>Expected: </span>
                     {dayjs(record?.start).format("MMMM DD, YYYY (h A)")}
                  </div>
               )
            return (
               <div>{dayjs(dateReleased).format("MMMM DD, YYYY (h A)")}</div>
            )
         },
      },
      {
         title: "Returned",
         dataIndex: "dateReturned",
         key: "dateReturned",
         render: (dateReturned, record) => {
            if (isEmpty(dateReturned))
               return (
                  <div>
                     <span className='nti'>Expected: </span>
                     {dayjs(record?.end).format("MMMM DD, YYYY (h A)")}
                  </div>
               )
            return (
               <div>{dayjs(dateReturned).format("MMMM DD, YYYY (h A)")}</div>
            )
         },
      },
      {
         title: "ID left",
         dataIndex: "idLeft",
         key: "idLeft",
      },
      {
         title: "State",
         dataIndex: "state",
         key: "state",
         render: (state) => {
            return <BookReservationTag state={state} />
         },
      },
      {
         title: "Action",
         key: "action",
         render: (_, record) => (
            <Space size='middle'>
               <Button
                  onClick={
                     () => handleInvoice(record)
                  }
                  type='primary'
                  shape='circle'
                  icon={<FilePdfFilled />}
									disabled={isLoading}
               />
            </Space>
         ),
      },
   ]

   const invoicesColumn = [
      {
         title: "Amount",
         dataIndex: "paidAmount",
         key: "paidAmount",
      },
      {
         title: "Mode of payment",
         dataIndex: "rentalPT",
         key: "rentalPT",
         render: (rentalPT) => {
            return <span>{modeOfPaymentObject[rentalPT]} </span>
         },
      },
      {
         title: "Date",
         dataIndex: "createdAt",
         key: "createdAt",
         render: (createdAt) => {
            return (
               <span>{dayjs(createdAt).format("MMMM DD, YYYY (h A)")} </span>
            )
         },
      },
   ]

   useEffect(() => {
      initialize()

      return () => {}

      //eslint-disable-next-line
   }, [])

   return (
      <div>
         <Drawer
            title={"Customer booked"}
            onClose={handleCancel}
            open={isViewCustomerBookedOpen}
            width={width}
         >
            <Table
               rowKey={rowkey}
               pagination={false}
               columns={columns}
               style={{ marginTop: "3vw" }}
               dataSource={customerBooks}
               rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
               }
               expandable={{
                  expandedRowRender: (record) => (
                     <Table
                        pagination={false}
                        columns={invoicesColumn}
                        style={{
                           marginTop: "0.2vw",
                           marginBottom: "3vw",
                           marginLeft: "2vw",
                        }}
                        dataSource={[...record.invoices]}
                        rowClassName={(record, index) =>
                           index % 2 === 0
                              ? "table-row-light"
                              : "table-row-dark"
                        }
                        footer={() => footerExpandedRow(record)}
                     />
                  ),
               }}
            />
         </Drawer>
      </div>
   )
}

export default CustomerBooked
