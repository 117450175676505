import {
   PlusCircleFilled,
   CloudSyncOutlined,
   SyncOutlined,
} from '@ant-design/icons'
import { Affix, Button, FloatButton } from 'antd'
import React from 'react'
import FloatingButtonCustom from '../../../common/components/FloatingButtonCustom'

const FloatingButtonCustomerManagement = ({
   handleAddCustomer,
   handleRefreshData,
}) => {
   return (
      <FloatingButtonCustom>
         <FloatButton
            style={styles.fabStyle}
            icon={<PlusCircleFilled style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleAddCustomer}
         />
         <FloatButton
            style={styles.fabStyleRefresh}
            icon={<CloudSyncOutlined style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleRefreshData}
         />
      </FloatingButtonCustom>
   )
}

export default FloatingButtonCustomerManagement

const styles = {
   fabStyleRefresh: {
      height: 50,
      width: 50,
      backgroundColor: 'rgba(89, 9, 0, 0)',
      paddingRight: '70px !important',
   },
   fabStyle: {
      height: 50,
      width: 50,
      backgroundColor: 'rgba(89, 9, 0, 0)',
      paddingRight: '70px !important',
      marginBottom: '7vh',
   },
   plusCircleIcon: {
      overflow: 'visible !important',
      fontSize: '3em',
			backgroundColor: 'rgba(0, 0, 0, 0) !important',
   },
}
