import React, { useContext, useState, useEffect } from "react"
import Logo from "../../../assets/hermosocarrental.jpeg"
import "./styles.css"

import { MailOutlined, LogoutOutlined, DownOutlined } from "@ant-design/icons"
import { Layout, Space, message, Button, Dropdown } from "antd"
import UserDropDownMenu from "./UserDropDownMenu"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../../../context/GlobalContext"
import { CHANGE_BRANCH } from "../../../context/reducer/GlobalReducer"
import { toUpperCaseFirstLetterWord } from "../../../lib/utils"

const { Header } = Layout

function HeaderContent({ Menu }) {
   const { user, selectedBranch, dispatch } = useContext(GlobalContext)
   const [items, setItems] = useState([])

   useEffect(() => {
      const generateBranches = () => {
         return user.branches.map((branch) => ({
            key: branch?.id,
            label: <Button onClick={() => dispatch({type: CHANGE_BRANCH, branch})} className='btn-link-black'>{branch?.name}</Button>,
         }))
      }
      setItems(generateBranches())

      return () => {
         setItems([])
      }
			//eslint-disable-next-line
   }, [user])
   

   return (
      <Header
         style={{
            color: "white",
            background: "#590900 0% 0% no-repeat padding-box",
            position: "fixed",
            width: "100%",
            zIndex: 9,
            padding: "0px",
            height: `5%`,
            fontSize: "8px",
         }}
         className='sd-sm'
      >
         <div
            className='logo-div item'
            style={{ float: "left", marginTop: "0.8vh", fontSize: "3em" }}
         >
            <Dropdown
               menu={{
                  items,
               }}
            >
               <Space>
                  <Button className='btn-link'>
                     {toUpperCaseFirstLetterWord(selectedBranch?.name)} &nbsp;
                     <DownOutlined />
                  </Button>
               </Space>
            </Dropdown>
         </div>
         <Menu />
         <div
            style={{
               float: "right",
               marginRight: "1.5vw",
               marginTop: "0.1vh",
            }}
         >
            <UserDropDownMenu />
         </div>
      </Header>
   )
}

export default HeaderContent
