import dayjs from "dayjs"
import { getStartAndEndDate } from "../../../lib/api"
import { message } from "antd"
import { isEmpty } from "../../../lib/utils"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { INSERT_NEW_UNIT_EXPENSE } from "../../../context/reducer/CarReducer"

const UnitExpensesController = ({ car, selectedDate, dispatch }) => {

	const [startDate, endDate] = getStartAndEndDate({
		selectedDate,
		cutoff: car?.cutoff,
 })

   const disabledTo = (current) => {
    
      const disabledStartDate = dayjs(startDate)

      return (
         current > endDate.endOf("day") ||
         current < disabledStartDate ||
         current > dayjs().endOf("day")
      )
   }

   const isthreeMonthsAgo = () => {
      const currentDate = dayjs()

      // Calculate the date 3 months ago from the current date
      const threeMonthsAgo = currentDate.subtract(2, "month")

      // Date to check
      const dateToCheck = dayjs(selectedDate) // Example date

      // Check if the date is within the past 3 months
      return dateToCheck.isAfter(threeMonthsAgo)
   }

   const validateValues = (values) => {
      let hasError = false
      const { carWash, pickupOrDelivery, maintenance, referral, description } =
         values

      const isEmptyExpensesFields =
         isEmpty(pickupOrDelivery) &&
         isEmpty(maintenance) &&
         isEmpty(referral) 
         
      if (isEmptyExpensesFields && isEmpty(carWash)) {
         message.info("Field is empty")
         hasError = true
      }
      if (!isEmptyExpensesFields) {
         if (isEmpty(description)) {
            message.info("Description is empty")
            hasError = true
         }
      }

      return hasError
   }

   const addExpenses = async (values) => {
      try {
         const uri = `/api/cars/unit-expense`
         const {data: {unitExpense}} = await SERVER.post(uri, { ...values, carId: car?.id })
				 dispatch({type: INSERT_NEW_UNIT_EXPENSE, unitExpense: {...values, ...unitExpense}})
      } catch (error) {
         handleError(error)
      }
   }

   const onFinish = async (values) => {
      const hasError = await validateValues(values)
      if (hasError) return
			addExpenses(values)

      message.info("Onfish")
   }

   return { onFinish, disabledTo, isthreeMonthsAgo, startDate, endDate }
}
export default UnitExpensesController
