import { Checkbox, Col, Divider } from "antd"
import React, { Fragment, useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import { TYPE_OF_DAMAGE } from "../../../data/enum"
import { convertConvertToPHCurrency } from "../../../lib/utils"

export default function ChargeDamageInfoRow() {
   const { bookReservation } = useContext(CalendarContext)

   const { car, damage } = bookReservation
   const hasDamage = damage?.hasDamage
   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            DAMAGE
         </Col>
         <Col span={12}>
            
            {hasDamage ? (
               <Fragment>
                  <div>
                     <span className='nti'> Type of damage: </span>
                     <b>
                        <i>{TYPE_OF_DAMAGE[damage?.typeOfDamage]}</i>
                     </b>
                  </div>
									<div>
                     <span className='nti'> Specify: </span>
                     <b>
                        <i>{damage?.specify}</i>
                     </b>
                  </div>
									<div>
                     <span className='nti'> Cost: </span>
                     <b>
                        <i>{convertConvertToPHCurrency(parseInt(damage?.damageCost ?? 0))}</i>
                     </b>
                  </div>
               </Fragment>
            ) : "No damage"}
         </Col>
      </Fragment>
   )
}
