import React, { useContext } from "react"

import { Space, Card, Button } from "antd"
import { EditFilled } from "@ant-design/icons"
import Table from "../../../common/components/Table"
import OotdFloatBtn from "../components/OotdFloatBtn"
import { GlobalContext } from "../../../context/GlobalContext"
import OOTDPageController from "../controller/OOTDPageController"
import { OOTDContext } from "../../../context/OOTDContext"
import AddUpdateOOTD from "../components/AddUpdateOOTD"
import { convertToDay } from "../../../lib/utils"

const OutOfTownDestinationPage = () => {
   const { dispatch: globalDisptach, outOfTownDestinations, isLoading: isLoadingGlobal } =
      useContext(GlobalContext)

   const { dispatch, isLoading } = useContext(OOTDContext)

   const { openModal, openUpdateModal, handleRefreshData } = OOTDPageController(
      { dispatch, globalDisptach }
   )

   const columns = [
      {
         title: "Destination",
         dataIndex: "destination",
         key: "destination",
      },
      {
         title: "Fee",
         dataIndex: "fee",
         key: "fee",
      },
      {
         title: "Min. Days",
         dataIndex: "minDays",
         key: "minDays",
         render: (minDays) => {
            if (minDays > 24) {
               return <span>{convertToDay(minDays)} Days </span>
            } else {
               return <span> {minDays} hours </span>
            }
         },
      },
      {
         title: "W/Driver",
         key: "withDriverFee",
         dataIndex: "withDriverFee",
      },
      {
         title: "Action",
         key: "action",
         render: (_, record, index) => (
            <Space size='middle'>
               <Button
                  onClick={() => openUpdateModal(record, index)}
                  shape='circle'
                  icon={<EditFilled style={{ color: "#DDA15E" }} />}
               />
            </Space>
         ),
      },
   ]

   return (
      <Card className='sd-sm'>
         <div>
            <div style={{ float: "left" }}></div>
            <div style={{ float: "right" }}></div>
         </div>

         <Table
            columns={columns}
            dataSource={outOfTownDestinations}
            isLoading={isLoadingGlobal || isLoading}
         />
         <AddUpdateOOTD />
         <OotdFloatBtn
            handleRefreshData={handleRefreshData}
            handleAdd={openModal}
         />
      </Card>
   )
}

export default OutOfTownDestinationPage
