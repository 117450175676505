import { message } from "antd"
import {
   OPEN_UPDATE,
   SET_LOADING,
   TOGGLE_ADD_UPDATE_OOTD_MODAL,
} from "../../../context/reducer/OOTDReducer"
import { ADD, UPDATE } from "../../../data/magicString"
import { getOOTDS } from "../../../lib/services"
import { handleError } from "../../../lib/serverAxios"
import { SET_OUT_OF_TOWN_DESTINATIONS } from "../../../context/reducer/GlobalReducer"

const OOTDPageController = ({ dispatch, globalDisptach }) => {
   const handleRefreshData = async () => {
      try {
         dispatch({
            type: SET_LOADING,
            isLoading: true,
         })
         const data = await getOOTDS()
         globalDisptach({
            type: SET_OUT_OF_TOWN_DESTINATIONS,
            outOfTownDestinations: data.ootds,
         })
         dispatch({
            type: SET_LOADING,
            isLoading: false,
         })
      } catch (error) {
				handleError(error)
         dispatch({
            type: SET_LOADING,
            isLoading: false,
         })
      }
   }
   const openModal = () => {
      dispatch({
         type: TOGGLE_ADD_UPDATE_OOTD_MODAL,
         addOrUpdate: ADD,
         isAddUpdateModalOpen: true,
      })
   }
   const openUpdateModal = (record, index) => {
      dispatch({
         type: TOGGLE_ADD_UPDATE_OOTD_MODAL,
         addOrUpdate: UPDATE,
         isAddUpdateModalOpen: true,
      })
      dispatch({ type: OPEN_UPDATE, ootd: record, ootdIndex: index })
   }

   return { handleRefreshData, openModal, openUpdateModal }
}
export default OOTDPageController
