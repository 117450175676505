import { Col, Divider } from "antd"
import React, { Fragment, useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import CheckedAndRecievedBy from "./CheckedAndRecievedBy"
import CheckedAndRecievedByInfo from "./CheckedAndRecievedByInfo"
import { isEmpty } from "../../../lib/utils"

export default function ProcessedBy() {
   const { bookReservation } = useContext(CalendarContext)

   const { car, dateReleased, dateReturned } = bookReservation

   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            PROCESSED
         </Col>
         {isEmpty(dateReleased) ? (
            <CheckedAndRecievedBy />
         ) : (
            <CheckedAndRecievedByInfo isRelease={false} />
         )}
         {isEmpty(dateReturned) && !isEmpty(dateReleased) ? (
            <CheckedAndRecievedBy />
         ) : (
            <CheckedAndRecievedByInfo isRelease={true} />
         )}
      </Fragment>
   )
}
