import { message } from "antd"
import {
	OPEN_CarMonthly_Unit_Expenses_Drawer,
   OPEN_UPDATE,
   TOGGLE_ADD_UPDATE_CAR_MODAL,
} from "../../../context/reducer/CarReducer"
import { SET_CARS, UPDATE_CAR_STATUS } from "../../../context/reducer/GlobalReducer"
import { UPDATE } from "../../../data/magicString"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { getCars } from "../../../lib/services"
import { ISEmsg } from "../../../data/enum"

const CarPageController = ({ dispatch, setState, globalDispatch }) => {
	const openCarMonthlyUnitExpensesDrawer = (record, index) => {
		dispatch({
			 type: OPEN_CarMonthly_Unit_Expenses_Drawer,
			 car: record, 
			 carIndex: index
		})
 }
   const openUpdateModal = (record, index) => {
      dispatch({
         type: TOGGLE_ADD_UPDATE_CAR_MODAL,
         addOrUpdate: UPDATE,
         isAddCarModalOpen: true,
      })
      dispatch({ type: OPEN_UPDATE, car: record, carIndex: index })
   }

   const handleRefreshData = async () => {
      try {
         setState((prevState) => ({
            ...prevState,
            isLoading: true,
         }))
         const data = await getCars()
         globalDispatch({
            type: SET_CARS,
            cars: data?.cars,
         })
         setState((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      } catch (error) {
         const errorMsg = handleError(error)
         message.error(errorMsg)
         setState((prevState) => ({
            ...prevState,
            isLoading: false,
         }))
      }
   }

   const handleChangeStatus = async ({ key, record, index }) => {
      try {
         const { data } = await SERVER.put("/api/cars/status", {
            ...record,
						status: key
         })
         globalDispatch({ type: UPDATE_CAR_STATUS, car: data?.car, carIndex: index, record })
         message.success("Car successfully updated.")
      } catch (error) {
         handleError(error)
      }
   }

   return { handleChangeStatus, openUpdateModal, handleRefreshData, openCarMonthlyUnitExpensesDrawer }
}

export default CarPageController
