import React, { Fragment, useContext, useRef, useState } from "react"

import { CalendarContext } from "../../../context/CalendarContext"
import { GlobalContext } from "../../../context/GlobalContext"

import {
   Button,
   Col,
   Divider,
   Form,
   Input,
   InputNumber,
   Row,
   Select,
   Space,
} from "antd"
import {
   InfoCircleOutlined,
   MinusCircleOutlined,
   PlusOutlined,
} from "@ant-design/icons"

import {
   convertConvertToPHCurrency,
   isEmpty,
   totalHoursDays,
} from "../../../lib/utils"
import { paymentTypeData } from "../../../data/data"
import { BOOKSTATE, FLAG_BOOK } from "../../../data/enum"
import { computeTotalAmountPaid } from "../../../lib/calculation"

import PaymentsRowController from "../controller/PaymentsRowController"
import { isRelease } from "../../commons/controllers/bookReservationsStatesController"
import { CHANGE_ADJUSTMENT } from "../../../context/reducer/CalendarReducer"

export default function PaymentsRow({ form, isSwitched = false }) {
   const reservationFee = Form.useWatch("reservationFee", form)
   const rentalFee = Form.useWatch("rentalFee", form)
   const destinationFee = Form.useWatch("destinationFee", form)
   const withDriverFee = Form.useWatch("withDriverFee", form)
   const damageCost = Form.useWatch("damageCost", form)
   const paidAlready = Form.useWatch("paidAlready", form)
   const previousBalance = Form.useWatch("previousBalance", form)

   const paidAmountRef = useRef(null)

   const { bookReservation, hasDamage, adjustment, dispatch, isCDchecked } =
      useContext(CalendarContext)
   const { outOfTownDestinations } = useContext(GlobalContext)

   const { car, start, end, payment, state, invoices, flag, ootdId } =
      bookReservation

   const ootd_ID = Form.useWatch("ootd", form)
   const _OOTD = isCDchecked ? ootd_ID : ootdId

   const oldDestination =
      outOfTownDestinations.find((ootd) => ootd.id === ootdId) || {}

   const destination =
      outOfTownDestinations.find((ootd) => ootd.id === _OOTD) || {}

   const isForRelease = isRelease({ state, start, end })
   const isForReturn = state === BOOKSTATE.released // THe book is already been release so now you can return it

   const isFinished =
      state === BOOKSTATE.done ||
      state === BOOKSTATE.switched ||
      state === BOOKSTATE.voided ||
      state === BOOKSTATE.cancelled

   const totalDiscount =
      parseInt(payment?.discount || 0) + parseInt(adjustment || 0)

   const paymentsMade = Form.useWatch("paymentsMade", form)

   const payments = {
      reservationFee,
      rentalFee,
      destinationFee,
      withDriverFee,
      previousBalance: isSwitched
         ? previousBalance || 0
         : payment?.previousBalance,
   }

   const { subTotal, computBalance, computBalanceSwitchedBooked } =
      PaymentsRowController({
         flag,
         payment,
         damageCost,
         invoices,
         totalDiscount,
         paidAmountRef,
         paymentsMade,
         isCDchecked,
         destination,
         oldDestination,
         ootdId,
         adjustment,
         payments,
         paidAlready,
         isFinished,
      })

   const infoFlag = () => {
      if (flag === FLAG_BOOK.switchedBooked) {
         return <InfoCircleOutlined />
      } else {
         return
      }
   }

   return (
      <Fragment>
         <Divider
            style={{
               backgroundColor: car?.color,
            }}
         />
         <Col
            className='nti'
            span={24}
            style={{ fontWeight: "bold", textDecoration: "underline" }}
         >
            PAYMENTS
         </Col>

         <Col span={12}>
            <Space direction='vertical'>
               {isFinished ? (
                  <div>
                     <span className='nti'> Reservation Fee: </span>
                     <b>
                        <i>
                           {convertConvertToPHCurrency(
                              parseFloat(payment.reservationFee ?? 0)
                           )}
                        </i>
                     </b>
                  </div>
               ) : (
                  !isSwitched && (
                     <div>
                        <Form.Item
                           disabled={true}
                           label='Reservation Fee'
                           name='reservationFee'
                           style={{
                              width: "100%",
                              marginBottom: "0px",
                           }}
                           rules={[
                              {
                                 required: true,
                                 message: "Reservation fee is required",
                              },
                           ]}
                        >
                           <InputNumber />
                        </Form.Item>
                     </div>
                  )
               )}
               {isFinished ? (
                  <div>
                     <span className='nti'> Rental Fee: </span>
                     <b>
                        <i>
                           {convertConvertToPHCurrency(
                              parseFloat(payment.rentalFee ?? 0)
                           )}
                        </i>
                     </b>
                  </div>
               ) : (
                  <div>
                     <Form.Item
                        label='Rental Fee'
                        name='rentalFee'
                        style={{
                           width: "100%",
                           marginBottom: "0px",
                        }}
                        rules={[
                           {
                              required: true,
                              message: "Rental fee is required",
                           },
                        ]}
                     >
                        <InputNumber />
                     </Form.Item>
                  </div>
               )}
               {isFinished ? (
                  <div>
                     <span className='nti'> With Driver Fee: </span>
                     <b>
                        <i>
                           {convertConvertToPHCurrency(
                              parseFloat(payment.withDriverFee ?? 0)
                           )}
                        </i>
                     </b>
                  </div>
               ) : (
                  <Form.Item
                     label='With Driver Fee'
                     name='withDriverFee'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "With driver fee is required",
                        },
                     ]}
                  >
                     <InputNumber />
                  </Form.Item>
               )}
               {isFinished ? (
                  <div>
                     <span className='nti'> Destination Fee: </span>
                     <b>
                        <i>
                           {convertConvertToPHCurrency(
                              parseFloat(payment.destinationFee ?? 0)
                           )}
                        </i>
                     </b>
                  </div>
               ) : (
                  <Form.Item
                     label='Destination Fee'
                     name='destinationFee'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "Destination fee is required",
                        },
                     ]}
                  >
                     <InputNumber />
                  </Form.Item>
               )}

               {hasDamage || !isEmpty(payment?.damageCost) ? (
                  <div>
                     <span className='nti'> Damage Cost: </span>
                     <b>
                        <i>
                           {convertConvertToPHCurrency(
                              parseInt((damageCost || payment?.damageCost) ?? 0)
                           )}
                        </i>
                     </b>
                  </div>
               ) : (
                  ""
               )}
            </Space>
            <Divider style={{ width: "10vw", minWidth: "unset" }} />
            {parseInt(payment?.previousBalance) !== 0 ? (
               <div style={{ fontSize: "1.1em" }}>
                  <span className='nti'>Previous Balance:</span>
                  <b>
                     <i>
                        {convertConvertToPHCurrency(
                           parseInt(payment?.previousBalance)
                        )}
                     </i>
                  </b>
               </div>
            ) : null}
            <div style={{ fontSize: "1.1em" }}>
               <span className='nti'> Subtotal: </span>
               <b>
                  <i>{convertConvertToPHCurrency(parseInt(subTotal ?? 0))}</i>
               </b>
            </div>

            <div style={{ fontSize: "1.1em" }}>
               {isSwitched ? (
                  <Form.Item
                     label='Paid'
                     name='paidAlready'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "Paid is required",
                        },
                     ]}
                  >
                     <InputNumber />
                  </Form.Item>
               ) : (
                  <b>
                     <span className='nti'> Paid: </span>
                     <i>
                        {convertConvertToPHCurrency(
                           parseInt(computeTotalAmountPaid(invoices) ?? 0) +
                              parseFloat(payment?.paidAlready)
                        )}
                     </i>
                  </b>
               )}
            </div>

            <div style={{ fontSize: "1.1em" }}>
               <span className='nti'> Adjustment {infoFlag()}: </span>
               <b>
                  <i>
                     {convertConvertToPHCurrency(
                        parseInt(totalDiscount ?? 0) ?? 0
                     )}
                  </i>
               </b>
            </div>
            <Divider />
            {isSwitched ? (
               <div>
                  <Form.Item
                     label='Previous Balance'
                     name='previousBalance'
                     style={{
                        width: "100%",
                        marginBottom: "0px",
                     }}
                     rules={[
                        {
                           required: true,
                           message: "Previous balance is required",
                        },
                     ]}
                  >
                     <InputNumber />
                  </Form.Item>
                  <div style={{ fontSize: "1.1em" }}>
                     <span
                        className='nti'
                        style={{
                           color:
                              computBalanceSwitchedBooked() > 0 &&
                              flag !== FLAG_BOOK.beenSwitched
                                 ? "red"
                                 : "black",
                        }}
                     >
                        Balance:&nbsp;
                     </span>
                     <b>
                        <i
                           style={{
                              color:
                                 computBalanceSwitchedBooked() > 0 &&
                                 flag !== FLAG_BOOK.beenSwitched
                                    ? "red"
                                    : "black",
                           }}
                        >
                           {flag === FLAG_BOOK.beenSwitched
                              ? convertConvertToPHCurrency(0)
                              : convertConvertToPHCurrency(
                                   parseInt(computBalanceSwitchedBooked() ?? 0)
                                )}
                           {}
                        </i>
                     </b>
                  </div>
               </div>
            ) : (
               <div style={{ fontSize: "1.1em" }}>
                  <span
                     className='nti'
                     style={{
                        color:
                           computBalance() > 0 &&
                           flag !== FLAG_BOOK.beenSwitched
                              ? "red"
                              : "black",
                     }}
                  >
                     Balance:&nbsp;
                  </span>
                  <b>
                     <i
                        style={{
                           color:
                              computBalance() > 0 &&
                              flag !== FLAG_BOOK.beenSwitched
                                 ? "red"
                                 : "black",
                        }}
                     >
                        {flag === FLAG_BOOK.beenSwitched
                           ? convertConvertToPHCurrency(0)
                           : convertConvertToPHCurrency(
                                parseInt(computBalance() ?? 0)
                             )}
                        {}
                     </i>
                  </b>
               </div>
            )}
         </Col>
         {(isForRelease || isForReturn) && !isFinished ? (
            <Col span={12}>
               <Form.Item
                  label='Adjustment'
                  name='discount'
                  style={{
                     width: "100%",
                  }}
               >
                  <b>
                     <InputNumber
                        onChange={(adjustment) =>
                           dispatch({
                              type: CHANGE_ADJUSTMENT,
                              adjustment: adjustment,
                           })
                        }
                        style={{ width: "50%" }}
                     />
                  </b>
               </Form.Item>
               <Form.List name='paymentsMade'>
                  {(fields, { add, remove }) => {
                     fields =
                        fields.length === 0 &&
                        (isForRelease || computBalance() > 0)
                           ? [{ name: 0, key: 0, fieldKey: 0 }]
                           : fields
                     return (
                        <div>
                           {fields.map(({ key, name, ...restField }) => (
                              <Row key={key} gutter={[8, 8]}>
                                 <Col key='1'>
                                    <Form.Item
                                       {...restField}
                                       name={[name, "paidAmount"]}
                                       rules={[
                                          {
                                             required: true,
                                             message: "Field is empty!",
                                          },
                                       ]}
                                    >
                                       <InputNumber />
                                    </Form.Item>
                                 </Col>
                                 <Col key='2' span={5}>
                                    <Form.Item
                                       style={{ width: "100%" }}
                                       {...restField}
                                       name={[name, "rentalPT"]}
                                    >
                                       <Select
                                          style={{
                                             width: "100%",
                                             marginBottom: "0px",
                                          }}
                                          defaultValue={1}
                                          options={paymentTypeData}
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col key='3'>
                                    <MinusCircleOutlined
                                       onClick={() => remove(name)}
                                    />
                                 </Col>
                              </Row>
                           ))}
                           <Form.Item>
                              <Button
                                 type='dashed'
                                 onClick={() => add()}
                                 block
                                 icon={<PlusOutlined />}
                                 style={{ width: "200px" }}
                              >
                                 Add payment
                              </Button>
                           </Form.Item>
                        </div>
                     )
                  }}
               </Form.List>
            </Col>
         ) : (
            ""
         )}
      </Fragment>
   )
}
