import { Button, Popconfirm, Space } from "antd"
import React, { useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import { BOOKSTATE } from "../../../data/enum"
import { isRelease } from "../../commons/controllers/bookReservationsStatesController"
import { isEmpty } from "../../../lib/utils"
import { TOGGLE_EDITABLE } from "../../../context/reducer/CalendarReducer"

export default function BRDrawerExtra({
   switchVehicle,
   releaseVehicle,
   returnVehicle,
   cancelledBookReservation,
   updateBook,
}) {
   const { bookReservation, isLoading, editable, dispatch } =
      useContext(CalendarContext)

   const { car, start, state, end, customerID } = bookReservation

   const isCustomerBooked = !isEmpty(customerID)
   const isForReturn = state === BOOKSTATE.released
   const isForRelease = isRelease({ state, start, end })

   const canCancel =
      state !== BOOKSTATE.released &&
      state !== BOOKSTATE.done &&
      state !== BOOKSTATE.switched &&
      state !== BOOKSTATE.cancelled

   const toggleEdit = () => {
      dispatch({ type: TOGGLE_EDITABLE, editable: true })
   }
   const cancelEdit = () => {
      dispatch({ type: TOGGLE_EDITABLE, editable: false })
   }

   return (
      <Space>
         {!editable && (isForRelease || isForReturn) && (
            <Button type='link' onClick={toggleEdit}>
               Edit
            </Button>
         )}
         {editable && (
            <>
               <Button type='link' onClick={cancelEdit}>
                  Cancel
               </Button>
               <Popconfirm
                  title='Updating Book/Reservation is not reversible'
                  description='Are you sure you want to update this book/reservation?'
                  onConfirm={updateBook}
                  onCancel={null}
                  okText='Yes'
                  cancelText='No'
                  key='cancel'
               >
                  <Button type='link'>Save</Button>
               </Popconfirm>
            </>
         )}
         {canCancel && !editable && (
            <Popconfirm
               title='Cancelling reservation is not reversible'
               description='Are you sure you want to cancel this reservation?'
               onConfirm={cancelledBookReservation}
               onCancel={null}
               okText='Yes'
               cancelText='No'
               key='cancel'
            >
               <Button loading={isLoading} danger>
                  Cancel Reservation
               </Button>
            </Popconfirm>
         )}

         {isForRelease && !editable && (
            <Popconfirm
               description='Are you sure you?'
               onConfirm={releaseVehicle}
               onCancel={null}
               okText='Yes'
               cancelText='No'
               key='release'
            >
               <Button
                  className='sd-sm'
                  style={{
                     backgroundColor: `${car?.color}`,
                     color: "white",
                     borderColor: "#f6c439",
                     border: "1px solid !important",
                  }}
                  loading={isLoading}
               >
                  RELEASE
               </Button>
            </Popconfirm>
         )}
         {isForReturn && isCustomerBooked && !editable && (
            <Popconfirm
               description='Are you sure you?'
               onCancel={null}
               onConfirm={switchVehicle}
               okText='Yes'
               cancelText='No'
               key='switch'
            >
               <Button
                  className='sd-sm'
                  style={{
                     backgroundColor: `${car?.color}`,
                     color: "white",
                     borderColor: "#f6c439",
                     border: "1px solid !important",
                  }}
                  loading={isLoading}
               >
                  SWITCH
               </Button>
            </Popconfirm>
         )}
         {state === BOOKSTATE.released && !editable && (
            <Popconfirm
               description='Are you sure you?'
               onConfirm={returnVehicle}
               onCancel={null}
               okText='Yes'
               cancelText='No'
               key='return'
            >
               <Button
                  className='sd-sm'
                  style={{
                     backgroundColor: `${car?.color}`,
                     color: "white",
                     borderColor: "#f6c439",
                     border: "1px solid !important",
                  }}
               >
                  RETURN
               </Button>
            </Popconfirm>
         )}
      </Space>
   )
}
