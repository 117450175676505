import React from 'react';

import { Layout } from 'antd';
//ROUTER
import { Route } from 'react-router-dom';

//Components
import HeaderContent from '../components/HeaderContent';
//Third party packages
const { Content } = Layout;

function PublicRoute(props) {
  const Component = props.component;

  const layout = (props) => (
    <>
      <Layout>
        <HeaderContent />
        <Layout
          style={{
            background: 'transparent',
            display: 'flex',
          }}
        >
          <Content
            className='public-content'
            style={{
              float: 'left',
              background: '#ffff6',
            }}
          >
            <Component {...props} />
          </Content>
        </Layout>
      </Layout>
    </>
  );

  return layout(props);
}

export default PublicRoute;
