import { Tag } from "antd"
import React from "react"

export default function BookReservationTag({ state = "" }) {
   let color = "green"
   if (state === "voided") {
      color = "volcano"
   }
   if (state === "done") {
      color = "gold"
   }
   if (state === "ongoing") {
      color = "green"
   }
   if (state === "reserved") {
      color = "geekblue"
   }
   if (state === "released") {
      color = "lime"
   }
   if (state === "voided") {
      color = "red"
   }
   return (
      <div>
         <Tag color={color}>{state?.toUpperCase()} </Tag>
      </div>
   )
}
