import { Image } from 'antd'
import React from 'react'
import Invoice from '../../../assets/Invoice.png'

const CustomerInvoice = () => {
	return (
		<div>
			<Image 
			 width={800}
			 src={Invoice}
			 />
		</div>
	)
}

export default CustomerInvoice