import { toUpperCaseFirstLetterWord } from "../../lib/utils"

export const SET_USER_DATA = "SET_USER_DATA"
export const SET_LOADING = "SET_LOADING"
export const LOGOUT = "LOGOUT"
export const LOGGED_IN = "LOGGED_IN"
export const SET_OUT_OF_TOWN_DESTINATIONS = "SET_OUT_OF_TOWN_DESTINATIONS"
export const SET_CARS = "SET_CARS"
export const SET_TYPES = "SET_TYPES"
export const SET_OWNERS = "SET_OWNERS"
export const INSERT_CAR = "INSERT_CAR"
export const UPDATE_CAR = "UPDATE_CAR"
export const UPDATE_OOTD = "UPDATE_OOTD"
export const CHANGE_BRANCH = "CHANGE_BRANCH"
export const UPDATE_CAR_STATUS = "UPDATE_CAR_STATUS"
export const INSERT_OOD = "INSERT_OOD"
export const SET_BRANCHES = "SET_BRANCHES"


export const GlobalReducer = (state, action) => {
   let car, stringifiedCars, ootds, ootd, outOfTownDestinations, stringifiedOOTD,stringifiedOotds
   let cars = []
   switch (action.type) {
      case SET_OWNERS:
         const owners = action?.owners?.map((e) => {
            return {
               ...e,
               key: e?.id,
               label: e?.fullName,
               text: e?.fullName,
               value: e?.id,
            }
         })
         return {
            ...state,
            owners,
         }
      case SET_LOADING:
         return {
            ...state,
            isLoading: !state.isLoading,
         }
				 case SET_BRANCHES:
					 const branches = action?.branches?.map((branch) => ({
						 ...branch,
						 text: branch.name,
						 value: branch._id,
						 label: branch._id,
						 key: branch._id
						}))
					return {
						...state,
            isLoading: false,
            branches: branches,
         }
      case "SET_TYPES":
         const typesWithText = action.types.map((type) => ({
            ...type,
            text: type.value,
         }))
         return {
            ...state,
            isLoading: false,
            types: typesWithText,
         }
      case "CHANGE_BRANCH":
         return {
            ...state,
            selectedBranch: action.branch,
         }

      case "SET_USER_DATA":
         return {
            ...state,
            isLoading: false,
            user: action.user,
            isLogin: true,
         }

      case "LOGOUT":
         return {
            isLogin: false,
            isLoading: false,
            user: null,
         }
      case "LOGGED_IN":
         return {
            ...state,
            isOnline: true,
            selectedBranch: action?.user?.branches[0],
         }
      case UPDATE_CAR_STATUS:
         car = action.car
         car.label = car?.name
         car.value = car?.id
         car.owner = action?.record?.owner
         cars = [...state.cars]
         cars.splice(action.carIndex, 1, car)
         stringifiedCars = JSON.stringify(cars)
         localStorage.setItem("customers", stringifiedCars)
         return {
            ...state,
            cars: cars,
         }

      case UPDATE_OOTD:
         ootd = action.ootd
         ootd.label = ootd?.name
         ootd.value = ootd?.id
         ootd.key = ootd?.id

         outOfTownDestinations = [...state.outOfTownDestinations]
         outOfTownDestinations.splice(action.ootdIndex, 1, ootd)
         stringifiedOOTD = JSON.stringify(outOfTownDestinations)
         localStorage.setItem("OOTD", stringifiedOOTD)
         return {
            ...state,
            outOfTownDestinations,
         }
      case "UPDATE_CAR":
         car = action.car
         car.label = car?.name
         car.value = car?.id
         car.key = car?.id

         cars = [...state.cars]
         cars.splice(action.carIndex, 1, car)
         stringifiedCars = JSON.stringify(cars)
         localStorage.setItem("customers", stringifiedCars)
         return {
            ...state,
            cars: cars,
         }
      case SET_OUT_OF_TOWN_DESTINATIONS:
				ootds = action.outOfTownDestinations.map((e) => {
					return {
						...e,
						key: e.id,
						label: e.destination,
						value: e.id,
					}
				})
				stringifiedOotds = JSON.stringify(ootds)
				localStorage.setItem("ootd", stringifiedOotds) 
         return {
            ...state,
            isLoading: false,
            outOfTownDestinations: ootds,
         }

      case SET_CARS:
         cars = action?.cars?.map((e) => {
            return {
               ...e,
               key: e.id,
               label: e?.name + " - " + toUpperCaseFirstLetterWord(e?.type),
               value: e.id,
            }
         })
				 const stringifoedCars = JSON.stringify(cars)
				 localStorage.setItem("cars", stringifoedCars)
         return {
            ...state,
            isLoading: false,
            cars: cars,
         }

      case "INSERT_CAR":
         car = action?.car
         cars = [
            { ...car, key: car.id, label: car.name, value: car.id },
            ...state.cars,
         ]
         return {
            ...state,
            cars: cars,
         }
      case INSERT_OOD:
         ootds = [
            {
               ...action?.ootd,
               key: action.ootd.id,
               label: action.ootd.name,
               value: action.ootd.id,
            },
            ...state.outOfTownDestinations,
         ]
         return {
            ...state,
            outOfTownDestinations: ootds,
         }

      default:
         return state
   }
}
