import React from "react"
import { Col, Form, InputNumber, Row, Select } from "antd"
import { paymentTypeData } from "../../../data/data"

export default function PaidSection() {
   return (
      <Row gutter={[8, 8]}>
         <Col>
            <Form.Item
               name='reservation'
               rules={[
                  {
                     required: true,
                     message: "Reservation payment is empty!",
                  },
               ]}
            >
               <InputNumber
                  style={{
                     width: "100%",
                  }}
               />
            </Form.Item>
         </Col>
         <Col span={5}>
            <Form.Item
               style={{ width: "100%" }}
               name={"rentalPT"}
            >
               <Select
                  style={{
                     width: "100%",
                     marginBottom: "0px",
                  }}
                  defaultValue={1}
                  options={paymentTypeData}
               />
            </Form.Item>
         </Col>
      </Row>
   )
}
