import { Button, message } from "antd"
import {
   SET_CAR,
   TOGGLE_ADD_UPDATE_CAR_MODAL,
} from "../../../context/reducer/CarReducer"
import SERVER, { handleError } from "../../../lib/serverAxios"
import { getRandomDarkColor, isEmpty } from "../../../lib/utils"
import { DiceOutlinedIcon } from "../../../common/icon/icon"
import { INSERT_CAR, UPDATE_CAR } from "../../../context/reducer/GlobalReducer"
import { ISEmsg } from "../../../data/enum"

const AddUpdateCarController = ({
	car,
   state,
   form,
   dispatch,
	 globalDispatch,
   addOrUpdate,
   setState,
	 carIndex
}) => {

	const getCars = () => {
		const _car = {
			...car,
			//owner: car.ownerId,
			color: car.color?.replace("#", ""),
			fiveHours: car.carRate?.fiveHours,
			eightHours: car.carRate?.eightHours,
			twelveHours: car.carRate?.twelveHours,
			twentyFourHours: car.carRate?.twentyFourHours,
			exceedingHours: car.carRate?.exceedingHours,
			reservationFee: car.carRate?.reservationFee,
			withDriverFee: car.carRate?.withDriverFee,
	 }
	 return _car
	}

   const handleCancel = () => {
      dispatch({
         type: TOGGLE_ADD_UPDATE_CAR_MODAL,
         isAddCustomerModalOpen: false,
				 addOrUpdate
      })
      dispatch({ type: SET_CAR, car: {} })
      form.resetFields()
   }

	 const handleUpdateCar =  async () => {
		try {
			const carFieldsValue = await form.getFieldsValue()
			const { data } = await SERVER.put("/api/cars", {
				 id: car.id,
				 ...carFieldsValue,
				 color: "#" + carFieldsValue?.color,
			})
			form.resetFields()
			globalDispatch({ type: UPDATE_CAR , car: data?.car, carIndex })
			dispatch({type: TOGGLE_ADD_UPDATE_CAR_MODAL, addOrUpdate, isAddCarModalOpen:false })
			message.success("Car successfully updated.")
	 } catch (error) {
			const errorMsg = handleError(error)?.msg || ISEmsg
			message.error(errorMsg)
	 }
	 }

   const handleAddCar = async () => {
      try {
         const carFieldsValue = await form.getFieldsValue()
         const { data } = await SERVER.post("/api/cars", {
            ...carFieldsValue,
            color: "#" + carFieldsValue?.color,
         })
         globalDispatch({ type: INSERT_CAR , car: data?.car })
				 dispatch({type: TOGGLE_ADD_UPDATE_CAR_MODAL,isAddCarModalOpen:false })
				 message.success("Car successfully created.")
				 form.resetFields()
      } catch (error) {
         const errorMsg = handleError(error)?.msg || ISEmsg
         message.error(errorMsg)
      }
   }

   const handleOk = () => {
      if (addOrUpdate === "Add") {
         handleAddCar()
      } else {
				handleUpdateCar()
      }
   }
	 
   const onFieldsChange = async (changedFields) => {
      const nameChange = !isEmpty(changedFields)
         ? changedFields[0].name?.includes("name")
         : false

      if (nameChange) {
         setState((prevState) => ({
            ...prevState,
            carName: changedFields[0]?.value,
         }))
      }
   }

   const title = () => {
      return `${addOrUpdate?.toUpperCase()} CAR`
   }

   const generateColor = () => {
      const darkColor = getRandomDarkColor(false)
      setState((prevState) => ({
         ...prevState,
         color: "#" + darkColor,
      }))
      form.setFieldValue("color", darkColor)
   }

   const colorLabel = () => {
      return (
         <div style={{ display: "flex" }}>
            Color :
            <div
               style={{
                  marginLeft: "2px",
                  backgroundColor: state?.color,
                  padding: "2px 4px",
                  color: "white",
                  justifyContent: "center",
                  textAlign: "center",
                  maxHeight: "28px",
                  width: "80%",
               }}
            >
               {state?.carName?.toUpperCase()}
            </div>
            <div style={{ marginLeft: "2%", padding: "0px" }}>
               <Button icon={<DiceOutlinedIcon />} onClick={generateColor} />
            </div>
         </div>
      )
   }

   const onChangeColor = (e) => {
      const value = e.target.value
      setState((prevState) => ({
         ...prevState,
         color: "#" + value,
      }))
   }

   return {
      onChangeColor,
      colorLabel,
      title,
      handleCancel,
      handleOk,
      onFieldsChange,
			getCars
   }
}

export default AddUpdateCarController
