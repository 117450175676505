import dayjs from "dayjs"
import { SET_PREVIOUS_BOOK } from "../../../context/reducer/MonthlySalesReportReducer"
import SERVER, { handleError } from "../../../lib/serverAxios"
import fileDownload from "js-file-download"
import { getStartAndEndDate } from "../../../lib/api"

const MonthlySalesReportController = ({
   cutoff,
   previousBook,
   selectedDate,
   selectedCar,
   dispatch,
}) => {
   const getLastOdometer = async (value) => {
      const date = value || selectedDate
      try {
         const { data } = await SERVER.get(
            `/api/reports/last-odometer?selectedMonth=${date}&carId=${selectedCar?.id}`
         )

         dispatch({
            type: SET_PREVIOUS_BOOK,
            previousBook: data?.bookReservations,
         })
      } catch (error) {
         handleError(error)
      }
   }

   const lastMonthTxt = () => {
      return (
         <span>
            {dayjs(previousBook?.dateReturned).format("MMMM DD, YYYY")}
         </span>
      )
   }

   const showPDF = async () => {
      try {
         const { data } = await SERVER.get(
            `/api/reports/?_selectedDate=${selectedDate}&cutoff=${cutoff}`,
            { responseType: "blob" }
         )
         //  const { data } = await SERVER.get("/api/reports/download-pdf-screen", {
         //     responseType: "blob",
         //  })

         fileDownload(data, "fileName.pdf")
      } catch (error) {
         handleError(error)
      }
   }

   const disabledTo = (current) => {
      return current > dayjs().add(1, "day").endOf("day")
   }

   const onSearch = (value) => {
      console.log("search:", value)
   }

   const getMonthAndYearTxt = ({ startDate, endDate }) => {
      const startMonth = startDate?.month()
      const startYear = startDate?.year()
      const endMonth = endDate?.month()
      const endYear = endDate?.year()

      const isSameMonthAndYear =
         startMonth === endMonth && startYear === endYear

      const theSameMonthYearTxt = `${startDate?.format(
         "MMMM"
      )} ${startDate?.format("D")} -
		${endDate?.format("D")}, ${endDate?.format("YYYY")}`

      const notTheSameMonthYearTxt = `${startDate?.format(
         "MMMM"
      )} ${startDate?.format("D")} - ${endDate?.format("MMMM")}
		${endDate?.format("D")}, ${endDate?.format("YYYY")}`

      return [isSameMonthAndYear, theSameMonthYearTxt, notTheSameMonthYearTxt]
   }

   const dateRangeTxt = () => {
      const [startDate, endDate] = getStartAndEndDate({ selectedDate, cutoff })
      const [isSameMonthAndYear, theSameMonthYearTxt, notTheSameMonthYearTxt] =
         getMonthAndYearTxt({ startDate, endDate })

      return (
         <span>
            {isSameMonthAndYear ? theSameMonthYearTxt : notTheSameMonthYearTxt}
         </span>
      )
   }

   return { dateRangeTxt, onSearch, disabledTo, getLastOdometer, lastMonthTxt }
}
export default MonthlySalesReportController
