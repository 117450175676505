import { Col, Space } from "antd"
import React, { Fragment, useContext } from "react"
import { CalendarContext } from "../../../context/CalendarContext"
import { RELEASED, RETURNED } from "../../../data/enum"
import dayjs from "dayjs"
import { isEmpty } from "../../../lib/utils"

export default function CheckedAndRecievedByInfo({ isRelease }) {
   const { bookReservation } = useContext(CalendarContext)

   const { checklists } = bookReservation

   const checkListReturned =
      checklists.find((checklist) => checklist.action === RETURNED) || {}
   const checkListReleased =
      checklists.find((checklist) => checklist.action === RELEASED) || {}

   const checklist = isRelease ? checkListReturned : checkListReleased

   const date = isEmpty(checklist?.date)
      ? ""
      : dayjs(checklist?.date).format("MMMM DD, YYYY (h A)")

   return (
      <Fragment>
         <Col span={12}>
            <Space direction='vertical'>
               <div>
                  <span className='nti'> Date: </span>

                  <b>{date} </b>
               </div>
               <div>
                  <span className='nti'>Checked by: </span>
                  <b>
                     <i> {checklist?.checkedBy}</i>
                  </b>
               </div>
               <div>
                  <span className='nti'>Recieved by: </span>
                  <b>
                     <i> {checklist?.recievedBy}</i>
                  </b>
               </div>
            </Space>
         </Col>
      </Fragment>
   )
}
