import React, { useReducer } from "react"
import { CustomerReducer } from "./reducer/CustomerReducer"


const customers = localStorage.getItem("customers")

const initialCustomerState = {
  isLoading: false,
	customers: customers ? JSON.parse(localStorage.getItem("customers")) : [],
	isUploadIDOpen: false,
	customerID: "",
	customer: {},
	customerBooks : []
}

export const CustomerContext = React.createContext()

export const CustomerProvider = props => {
  const [customerState, dispatch] = useReducer(CustomerReducer, initialCustomerState)


  return (
    <CustomerContext.Provider
      value={{
        ...customerState,
        dispatch
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  )
}
