import React from 'react'
import PageMonthlySalesReport from '../components/PageMonthlySalesReport'
import { MonthlySalesReportProvider } from '../../../context/MonthlySalesReportContext'

export default function MonthlySalesReport() {
	return (
		<MonthlySalesReportProvider>
			<PageMonthlySalesReport />
		</MonthlySalesReportProvider>
	)
}
