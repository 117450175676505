import dayjs from "dayjs"

const CutOffTableController = ({bookReservations}) => {

	const filterBookReservationPerDay = (currentDate, startDate) => {
		const reservationsWithCurrentDate = bookReservations?.filter(
			 (reservation) => {
					return (
						 dayjs(reservation?.dateReleased).isSame(currentDate, "day") ||
						 (dayjs(startDate).diff(
								dayjs(reservation?.dateReleased),
								"day"
						 ) === 1 &&
								currentDate.isSame(startDate, "day"))
					)
			 }
		)
		return reservationsWithCurrentDate
 }

	return {filterBookReservationPerDay}
}

export default CutOffTableController