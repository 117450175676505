import { Card, Typography } from "antd"
import React, { useEffect, useState } from "react"
import Table from "../../../common/components/Table"
import dayjs from "dayjs"
import { isEmpty, toUpperCaseFirstLetterWord } from "../../../lib/utils"
import { branchesData } from "../../../data/data"
import RentedStatusController from "../controller/RentedStatusController"

const { Title } = Typography
export default function RentedStatus({ updateCount }) {
   const [cars, setCars] = useState([])

   const { getCarsWithRecentBooked } = RentedStatusController({ setCars })

   useEffect(() => {
      getCarsWithRecentBooked()
      //eslint-disable-next-line
   }, [updateCount])

   const columns = [
      {
         title: "Car",
         dataIndex: "car",
         key: "car",
         render: (color, car) => {
            return (
               <div
                  style={{
                     backgroundColor: car?.color,
                     color: "white",
                     padding: "2px",
                  }}
               >
                  &nbsp;{car?.name?.toUpperCase()}
               </div>
            )
         },
      },
      {
         title: "Type",
         dataIndex: "type",
         key: "type",
         onFilter: (value, record) => record.type === value,
         render: (type) => {
            return <div>{type?.toUpperCase()}</div>
         },
      },
      {
         title: "Branch",
         key: "branchId",
         dataIndex: "branchId",
         onFilter: (value, record) => record.branchId === value,
         render: (branchId) => {
            const branch = branchesData.find(
               (branch) => branch.value === branchId
            )
            return <div>{toUpperCaseFirstLetterWord(branch?.label)}</div>
         },
      },
      {
         title: "Last rented",
         dataIndex: "recentReservation",
         key: "lastDate",
         render: (recentReservation) => {
					const lastRentedtxt = isEmpty(recentReservation?.dateReturned) ? "": dayjs(recentReservation?.dateReturned).format(
						"MMMM DD, YYYY (hh A)"
				 )
            return lastRentedtxt
         },
      },
      {
         title: "No.",
         dataIndex: "daysNotBeingRented",
         key: "daysNotBeingRented",
				 render: (daysNotBeingRented) => {
					return <span style={{fontWeight: "bold"}}>
						{daysNotBeingRented} days
					</span>
				 }
      },
   ]

   return (
      <Card className='sd-sm' style={{ width: "100%", marginTop: "1vw" }}>
         <div>
            <div>
               <div style={{ float: "left" }}>
                  <Title level={5}>Car Priority</Title>
               </div>
               <div style={{ float: "right" }}></div>
            </div>
            <Table columns={columns} dataSource={cars} />
         </div>
      </Card>
   )
}
