import React from 'react'

import {
	PlusCircleFilled,
	CloudSyncOutlined,
} from '@ant-design/icons'
import { FloatButton } from 'antd'
import FloatingButtonCustom from '../../../common/components/FloatingButtonCustom'


const OotdFloatBtn = ({handleAdd, handleRefreshData}) => {
   return (
      <FloatingButtonCustom>
         <FloatButton
            style={styles.fabStyle}
            icon={<PlusCircleFilled style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleAdd}
         />
         <FloatButton
            style={styles.fabStyleRefresh}
            icon={<CloudSyncOutlined style={styles.plusCircleIcon} />}
            shape='circle'
            onClick={handleRefreshData}
         />
      </FloatingButtonCustom>
   )
}

export default OotdFloatBtn



const styles = {
	fabStyleRefresh: {
		 height: 50,
		 width: 50,
		 backgroundColor: 'rgba(0,255,255,0)',
		 paddingRight: '70px !important',
	},
	fabStyle: {
		 height: 50,
		 width: 50,
		 backgroundColor: 'rgba(0,255,255,0)',
		 paddingRight: '70px !important',
		 marginBottom: '3.5vw',
	},
	plusCircleIcon: {
		 overflow: 'visible !important',
		 fontSize: '3em',
	},
}

