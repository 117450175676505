import React, { useEffect, useReducer } from "react"

import {
   GlobalReducer,
   SET_CARS,
   SET_OUT_OF_TOWN_DESTINATIONS,
   SET_OWNERS,
   SET_TYPES,
} from "./reducer/GlobalReducer"
import { getCars, getOOTDS, getOwners, getTypes } from "../lib/services"
import { handleError } from "../lib/serverAxios"

const user = localStorage.getItem("user")
   ? JSON.parse(localStorage.getItem("user"))
   : null

const initialGlobalState = {
   isLogin: localStorage.getItem("isLogin") || false,
   isLoading: false,
   user: user,
   outOfTownDestinations:localStorage.getItem("ootd") ? JSON.parse(localStorage.getItem("ootd")) : [],
   cars: localStorage.getItem("cars") ? JSON.parse(localStorage.getItem("cars")) : [],
   types: [],
   owners: [],
   selectedBranch: (user?.branches && user?.branches[0]) || {},
   branches: [],
}
export const GlobalContext = React.createContext()

export const GlobalProvider = (props) => {
   const [globalState, dispatch] = useReducer(GlobalReducer, initialGlobalState)

   useEffect(() => {
      if (globalState.isLogin) {
         (async () => {
            try {
               let promise = await Promise.all([
                  getOOTDS(),
                  getCars(),
                  getTypes(),
                  getOwners(),
               ])
               const ootds = promise[0]?.ootds ?? []
               const cars = promise[1]?.cars ?? []
               const types = promise[2]?.types ?? []
               const owners = promise[3]?.owners ?? []

               dispatch({
                  type: SET_OUT_OF_TOWN_DESTINATIONS,
                  outOfTownDestinations: ootds,
               })
               dispatch({
                  type: SET_CARS,
                  cars,
               })
               dispatch({
                  type: SET_TYPES,
                  types,
               })
               dispatch({
                  type: SET_OWNERS,
                  owners,
               })
            } catch (error) {
               handleError(error)
						//	 window.location.reload() // so user will be logout -> it will cause an infinite loop if server run down
            }
         })()
      }

      return () => {}
   }, [globalState.isLogin])

   return (
      <GlobalContext.Provider
         value={{
            ...globalState,
            dispatch,
         }}
      >
         {props.children}
      </GlobalContext.Provider>
   )
}
